import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { 
  LineChart, 
  Line, 
  XAxis, 
  YAxis, 
  CartesianGrid, 
  Tooltip, 
  Legend,
  ResponsiveContainer,
  ReferenceArea
} from 'recharts';
import { Empty, Spin } from 'antd';
import { BaseText } from 'src/components/typography';
import { getTrackDass } from 'src/services/client-service';
import { EDASS_HOMEWORK } from 'src/variables/enum-variables';
import { TDass, THomeworkSummaryScore } from 'src/interfaces/clients-interface';
import { showErrorToast } from 'src/components/toast/Toast';
import ResponseError from 'src/interfaces/error-response-interface';
import ModalSeverity from './ModalSeverity';
import ChartLegend from './ChartLegend';
import './Dass.scss';
import { calculateDassStatistics } from './utils/statistics';
import { getDass21SeverityRanges, getDass42SeverityRanges, getSeverityForScore, SEVERITY_COLORS, DassSeverityCategory } from './utils/severity';
import DassStatistics from './DassStatistics';

interface DassSeverityData {
  depression: number[];
  anxiety: number[];
  stress: number[];
}

interface DassEntry {
  date: string;
  depression: number;
  anxiety: number;
  stress: number;
}

interface VisibleLines {
  depression: boolean;
  anxiety: boolean;
  stress: boolean;
}

const DASS_COLORS = {
  depression: '#2E5BFF',
  anxiety: '#8C54FF',
  stress: '#00C1D4'
};

const Dass = () => {
  const params = useParams();
  const clientId = params?.clientId || '';
  const [selectedDass, setSelectedDass] = useState<TDass | null>(null);
  const [isShowSeverity, setIsShowSeverity] = useState(false);
  const [loading, setLoading] = useState(true);
  const [dassData, setDassData] = useState<{
    dass21: TDass | null;
    dass42: TDass | null;
  }>({
    dass21: null,
    dass42: null
  });
  const [visibleLines, setVisibleLines] = useState<VisibleLines>({
    depression: true,
    anxiety: true,
    stress: true
  });

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      setLoading(true);
      const [dass21Response, dass42Response] = await Promise.all([
        getTrackDass(clientId, EDASS_HOMEWORK.DASS21),
        getTrackDass(clientId, EDASS_HOMEWORK.DASS42)
      ]);

      console.log('DASS-21 Raw Response:', dass21Response);
      console.log('DASS-42 Raw Response:', dass42Response);

      setDassData({
        dass21: dass21Response,
        dass42: dass42Response
      });
    } catch (e) {
      showErrorToast((e as ResponseError).message);
    } finally {
      setLoading(false);
    }
  };

  const formatChartData = (data: Array<{ 
    createdAt: string; 
    summary: THomeworkSummaryScore;
  }>) => {
    console.log('Formatting data:', data);
    const formattedData = data.map(entry => {
      const formatted = {
        date: new Date(entry.createdAt).toLocaleDateString(),
        depression: entry.summary?.Depression?.score || 0,
        anxiety: entry.summary?.Anxiety?.score || 0,
        stress: entry.summary?.Stress?.score || 0
      };
      console.log('Formatted entry:', formatted);
      return formatted;
    });
    console.log('Final formatted data:', formattedData);
    return formattedData;
  };

  const handleLegendClick = (dataKey: keyof VisibleLines) => {
    setVisibleLines(prev => ({
      ...prev,
      [dataKey]: !prev[dataKey]
    }));
  };

  const renderSeverityRanges = (type: 'DASS-21' | 'DASS-42', metric: 'Depression' | 'Anxiety' | 'Stress') => {
    const ranges = type === 'DASS-21' ? getDass21SeverityRanges(metric) : getDass42SeverityRanges(metric);
    return ranges.map((range, index) => (
      <ReferenceArea
        key={`${metric}-${range.category}`}
        y1={range.from}
        y2={range.to}
        fill={range.color}
        fillOpacity={0.1}
        strokeOpacity={0}
      />
    ));
  };

  const renderSeverityLegend = () => {
    return (
      <div className="severity-legend">
        <BaseText type="caption" className="legend-title">Severity Ranges:</BaseText>
        <div className="severity-legend-items">
          {(Object.keys(SEVERITY_COLORS) as DassSeverityCategory[]).map((category) => (
            <div key={category} className="severity-legend-item">
              <span 
                className="color-box" 
                style={{ backgroundColor: SEVERITY_COLORS[category] }} 
              />
              <BaseText type="caption">{category}</BaseText>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const renderDassChart = (data: TDass | null, title: string) => {
    if (!data) {
      return (
        <div className="dass-chart-container">
          <BaseText style={{ marginTop: '24px' }} type="title" className="chart-title">{title}</BaseText>
          <Empty description="No data available" />
        </div>
      );
    }
    if (!data.data.length) {
      return (
        <div className="dass-chart-container">
          <BaseText type="title" className="chart-title">{title}</BaseText>
          <div className="empty-state">
            <Empty description="No assessments completed yet" />
          </div>
        </div>
      );
    }

    const chartData = formatChartData(data.data);
    const statistics = calculateDassStatistics(data.data);
    const version = title.includes('DASS-21') ? 'DASS-21' : 'DASS-42';

    // Determine max scale based on DASS version
    const maxPossibleScore = version === 'DASS-21' ? 21 : 42;
    
    const allScores = chartData.flatMap(entry => [
      Number(entry.depression),
      Number(entry.anxiety),
      Number(entry.stress)
    ]);
    
    const maxScore = Math.max(
      ...allScores,
      Math.ceil(maxPossibleScore * 0.75)
    );
    
    const yDomain = [0, Math.ceil(maxScore * 1.1)];
    const curveType: 'monotone' | undefined = chartData.length === 1 ? undefined : 'monotone';

    return (
      <div className="dass-chart-container">
        <BaseText type="title" className="chart-title">{title}</BaseText>
        
        <div className="dass-statistics">
          <DassStatistics 
            title="Depression"
            metrics={statistics.depression}
            color={DASS_COLORS.depression}
            version={version}
          />
          <DassStatistics 
            title="Anxiety"
            metrics={statistics.anxiety}
            color={DASS_COLORS.anxiety}
            version={version}
          />
          <DassStatistics 
            title="Stress"
            metrics={statistics.stress}
            color={DASS_COLORS.stress}
            version={version}
          />
        </div>

        <div className="chart-wrapper">
          <ResponsiveContainer width="100%" height={400}>
            <LineChart data={chartData} margin={{ top: 20, right: 30, left: 0, bottom: 20 }}>
              <CartesianGrid strokeDasharray="3 3" />
              {visibleLines.depression && renderSeverityRanges(version, 'Depression')}
              {visibleLines.anxiety && renderSeverityRanges(version, 'Anxiety')}
              {visibleLines.stress && renderSeverityRanges(version, 'Stress')}
              <XAxis 
                dataKey="date" 
                tick={{ fontSize: 12 }}
                interval={0}
                minTickGap={50}
              />
              <YAxis 
                tick={{ fontSize: 12 }}
                domain={yDomain}
                label={{ 
                  value: 'Score', 
                  angle: -90, 
                  position: 'insideLeft',
                  style: { textAnchor: 'middle' }
                }}
              />
              <Tooltip 
                contentStyle={{ 
                  backgroundColor: 'white',
                  border: '1px solid #ccc',
                  borderRadius: '4px',
                  padding: '8px'
                }}
                formatter={(value: number, name: string) => {
                  const key = String(name).toLowerCase();
                  if (!visibleLines[key as keyof VisibleLines]) {
                    return ['-', name];
                  }
                  const severity = getSeverityForScore(
                    value, 
                    name as 'Depression' | 'Anxiety' | 'Stress',
                    version
                  );
                  return [
                    <span key={`tooltip-${name}-${value}`} style={{ color: DASS_COLORS[key as keyof typeof DASS_COLORS] }}>
                      {value} - <span style={{ color: severity.color }}>{severity.category}</span>
                    </span>,
                    name
                  ];
                }}
              />
              {visibleLines.depression && (
                <Line 
                  type={curveType}
                  dataKey="depression" 
                  stroke={DASS_COLORS.depression} 
                  name="Depression"
                  strokeWidth={2}
                  dot={{ r: 4 }}
                  label={chartData.length === 1 ? {
                    position: 'top',
                    fill: DASS_COLORS.depression
                  } : false}
                />
              )}
              {visibleLines.anxiety && (
                <Line 
                  type={curveType}
                  dataKey="anxiety" 
                  stroke={DASS_COLORS.anxiety}
                  name="Anxiety"
                  strokeWidth={2}
                  dot={{ r: 4 }}
                  label={chartData.length === 1 ? {
                    position: 'top',
                    fill: DASS_COLORS.anxiety
                  } : false}
                />
              )}
              {visibleLines.stress && (
                <Line 
                  type={curveType}
                  dataKey="stress" 
                  stroke={DASS_COLORS.stress}
                  name="Stress"
                  strokeWidth={2}
                  dot={{ r: 4 }}
                  label={chartData.length === 1 ? {
                    position: 'top',
                    fill: DASS_COLORS.stress
                  } : false}
                />
              )}
            </LineChart>
          </ResponsiveContainer>
        </div>
        <ChartLegend 
          visibleLines={visibleLines}
          onToggle={handleLegendClick}
        />
        {renderSeverityLegend()}
        <div className="dass-info">
          <button 
            className="severity-button"
            onClick={() => {
              setSelectedDass(data);
              setIsShowSeverity(true);
            }}
          >
            View Severity Ranges
          </button>
          {chartData.length === 1 && (
            <BaseText type="caption" className="single-point-note">
              Only one assessment completed. Complete more assessments to track changes over time.
            </BaseText>
          )}
        </div>
      </div>
    );
  };

  if (loading) {
    return (
      <div className="dass-loading">
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div className="dass-container">
      {renderDassChart(dassData.dass21, 'DASS-21')}
      {renderDassChart(dassData.dass42, 'DASS-42')}
      
      <ModalSeverity 
        open={isShowSeverity} 
        data={selectedDass?.severity} 
        onCancel={() => setIsShowSeverity(false)} 
      />
    </div>
  );
};

export default Dass;
