import React from 'react';
import { Button, ButtonProps, styled } from '@mui/material';
import { alpha } from '@mui/material/styles';

const StyledButton = styled(Button)(({ theme }) => ({
  '&.MuiButton-containedPrimary': {
    background: `linear-gradient(to right, ${theme.palette.primary.main}, ${alpha(theme.palette.primary.main, 0.8)})`,
    '&:hover': {
      background: `linear-gradient(to right, ${theme.palette.primary.dark}, ${alpha(theme.palette.primary.dark, 0.8)})`,
    },
  },
  '&.MuiButton-containedSecondary': {
    background: `linear-gradient(to right, ${theme.palette.secondary.main}, ${alpha(theme.palette.secondary.main, 0.8)})`,
    '&:hover': {
      background: `linear-gradient(to right, ${theme.palette.secondary.dark}, ${alpha(theme.palette.secondary.dark, 0.8)})`,
    },
  },
  '&.MuiButton-outlined': {
    borderWidth: '2px',
    '&:hover': {
      borderWidth: '2px',
    },
  },
  '&.MuiButton-sizeLarge': {
    padding: '12px 24px',
    fontSize: '1rem',
  },
  '&.MuiButton-sizeSmall': {
    padding: '6px 12px',
    fontSize: '0.8125rem',
  },
}));

export interface MButtonProps extends Omit<ButtonProps, 'css'> {
  loading?: boolean;
}

export const MButton: React.FC<MButtonProps> = ({
  children,
  loading,
  disabled,
  ...props
}) => {
  return (
    <StyledButton
      disabled={disabled || loading}
      {...props}
    >
      {loading ? (
        <span className="flex items-center gap-2">
          <span className="animate-spin h-4 w-4 border-2 border-t-transparent border-current rounded-full" />
          {children}
        </span>
      ) : (
        children
      )}
    </StyledButton>
  );
}; 