import { Spin, Row, Col, Card, Avatar as AntAvatar, Typography, Divider, List } from 'antd';
import dayjs from 'dayjs';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import { MessageOutlined, ProfileOutlined, TeamOutlined, NotificationOutlined, CommentOutlined, VideoCameraOutlined } from '@ant-design/icons';
import { ReactElement } from 'react';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { EPaymentStatus, EUserType } from 'src/variables/enum-variables';

import { showErrorToast } from 'src/components/toast/Toast';
import { TCommonGetDashboardParams, TGetDashboardResponse } from 'src/interfaces/common-interface';
import ResponseError from 'src/interfaces/error-response-interface';
import { RoutePaths } from 'src/routes/routes-constants';
import { getOwnerDashboard } from 'src/services/owner-service';
import { getTrialTime } from 'src/services/payment-service';
import { getPractitionerDashboard } from 'src/services/practitioner-service';
import { TRootState } from 'src/stores';
import { getCurrentTimezone } from 'src/utils/common-utils';
import { TUserProfile } from 'src/stores/user/user-constants';
import ExpireTrialModal from './components/ExpireTrialModal';
import { getTheMessageOfTheDay } from 'src/services/motd-service';
import { TMessageOfTheDay } from 'src/interfaces/motd-interface';
import { getDashboardVideos, DashboardVideo, DashboardVideoType } from 'src/services/dashboard-videos-service';
import {
  AbstractWellness1,
  AbstractWellness2,
  MeditationPattern,
  WavePattern,
  HealingPattern,
} from 'src/assets/images';
import DashboardGirl from 'src/assets/images/dashboard-girl.png';
import { EProfileTabKey } from '../profile/profile-page-constants';

const { Title, Text } = Typography;

interface DashboardCardProps {
  title: string;
  icon: ReactElement;
  content: string | number | ReactElement;
  color: string;
  characterImage?: string;
  onClick?: () => void;
}

const DashboardCard: React.FC<DashboardCardProps> = ({ title, icon, content, color, characterImage, onClick }) => {
  const cardStyle = {
    height: '100%',
    background: `linear-gradient(135deg, #fff 0%, ${color}10 100%)`,
    borderRadius: 16,
    border: '1px solid rgba(0, 0, 0, 0.1)',
    cursor: onClick ? 'pointer' : 'default',
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.05)',
    transition: 'all 0.3s ease',
    position: 'relative' as const,
  };

  return (
    <Card
      className={`dashboard-card ${onClick ? 'clickable-card' : ''}`}
      onClick={onClick}
      style={cardStyle}
      bodyStyle={{
        height: '100%',
        padding: '24px',
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <style>
        {`
          .clickable-card {
            position: relative;
          }
          .clickable-card:hover {
            transform: translateY(-4px);
            box-shadow: 0 6px 16px rgba(0, 0, 0, 0.1);
          }
          .clickable-card:active {
            transform: translateY(-2px);
          }
        `}
      </style>
      <div className="card-header" style={{ marginBottom: 24, flexShrink: 0 }}>
        <div style={{
          display: 'flex',
          alignItems: 'center',
          gap: 16
        }}>
          <div style={{
            backgroundColor: `${color}15`,
            padding: 16,
            borderRadius: 8,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
            {React.cloneElement(icon, { style: { color: color, fontSize: 24 } })}
          </div>
          <Title level={4} style={{ margin: 0, color: '#2c3e50' }}>{title}</Title>
          {onClick && (
            <div style={{
              marginLeft: 'auto',
              backgroundColor: `${color}15`,
              padding: '4px 8px',
              borderRadius: 4,
              fontSize: '0.8rem',
              color: color,
              fontWeight: 500
            }}>
              Click to view →
            </div>
          )}
        </div>
      </div>
      <div className="card-content" style={{
        flex: 1,
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column'
      }}>
        {content}
      </div>
      {characterImage && (
        <div className="character-image" style={{
          position: 'absolute',
          right: -20,
          bottom: -20,
          height: '130%',
          opacity: 0.8,
          pointerEvents: 'none',
          zIndex: 1
        }}>
          <img src={characterImage} alt="" style={{ height: '100%' }} />
        </div>
      )}
    </Card>
  );
};

const WelcomeBanner: React.FC<{ profile: TUserProfile | undefined }> = ({ profile }) => (
  <Card
    className="welcome-banner"
    style={{
      height: '100%',
      background: 'linear-gradient(135deg, #48ABE2 0%, #3498db 100%)',
      borderRadius: 16,
      border: 'none',
      boxShadow: '0 4px 12px rgba(72, 171, 226, 0.15)'
    }}
    bodyStyle={{
      height: '100%',
      padding: '24px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }}
  >
    <div style={{
      display: 'flex',
      alignItems: 'center',
      gap: 24,
      width: '100%',
      height: '100%'
    }}>
      <AntAvatar
        size={84}
        style={{
          backgroundColor: 'rgba(255, 255, 255, 0.2)',
          border: '2px solid rgba(255, 255, 255, 0.5)',
          fontSize: '2.5rem',
          color: 'white',
          flexShrink: 0
        }}
      >
        {profile?.firstName?.[0]}
      </AntAvatar>
      <div>
        <Title level={2} style={{ color: 'white', margin: 0 }}>
          Welcome back, {profile?.firstName || 'User'}!
        </Title>
        <Text style={{ color: 'rgba(255, 255, 255, 0.9)', fontSize: '1.1rem' }}>
          {new Date().toLocaleDateString('en-US', {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          })}
        </Text>
      </div>
    </div>
  </Card>
);

const QuoteContent: React.FC<{ content: string; author?: string }> = ({ content, author }) => (
  <div style={{ padding: '16px 0' }}>
    <Text style={{
      fontSize: '1.2rem',
      fontStyle: 'italic',
      color: 'rgba(0, 0, 0, 0.65)',
      display: 'block',
      marginBottom: author ? 16 : 0
    }}>
      &quot;{content}&quot;
    </Text>
    {author && (
      <Text style={{
        fontSize: '1rem',
        color: 'rgba(0, 0, 0, 0.45)',
        display: 'block',
        textAlign: 'right'
      }}>
        — {author}
      </Text>
    )}
  </div>
);

// Add this component for markdown content
const MarkdownContent: React.FC<{ content: string }> = ({ content }) => (
  <ReactMarkdown
    remarkPlugins={[remarkGfm]}
    components={{
      // Customize heading styles
      h1: ({ children }) => <Title level={1} style={{ fontSize: '1.5rem' }}>{children}</Title>,
      h2: ({ children }) => <Title level={2} style={{ fontSize: '1.3rem' }}>{children}</Title>,
      h3: ({ children }) => <Title level={3} style={{ fontSize: '1.2rem' }}>{children}</Title>,
      h4: ({ children }) => <Title level={4} style={{ fontSize: '1.1rem' }}>{children}</Title>,
      // Customize paragraph styles
      p: ({ children }) => (
        <Text style={{
          fontSize: '1.1rem',
          lineHeight: 1.6,
          color: 'rgba(0, 0, 0, 0.65)',
          display: 'block',
          marginBottom: '0.8em'
        }}>{children}</Text>
      ),
      // Customize link styles
      a: ({ children, href }) => (
        <a
          href={href}
          style={{
            color: '#1890ff',
            textDecoration: 'none'
          }}
          target="_blank"
          rel="noopener noreferrer"
        >{children}</a>
      ),
      // Customize list styles
      ul: ({ children }) => (
        <ul style={{
          paddingLeft: '1.5em',
          marginBottom: '0.8em'
        }}>{children}</ul>
      ),
      ol: ({ children }) => (
        <ol style={{
          paddingLeft: '1.5em',
          marginBottom: '0.8em'
        }}>{children}</ol>
      ),
      li: ({ children }) => (
        <li style={{
          fontSize: '1.1rem',
          lineHeight: 1.6,
          color: 'rgba(0, 0, 0, 0.65)',
          marginBottom: '0.3em'
        }}>{children}</li>
      ),
      // Customize code block styles
      code: ({ children, inline }) => (
        inline ?
          <code style={{
            backgroundColor: 'rgba(0, 0, 0, 0.05)',
            padding: '0.2em 0.4em',
            borderRadius: 3,
            fontSize: '0.9em'
          }}>{children}</code> :
          <pre style={{
            backgroundColor: 'rgba(0, 0, 0, 0.05)',
            padding: '1em',
            borderRadius: 6,
            overflow: 'auto'
          }}>
            <code>{children}</code>
          </pre>
      )
    }}
  >
    {content}
  </ReactMarkdown>
);

// Add this interface near the other interfaces
interface TrainingMaterial {
  id: string;
  title: string;
  type: DashboardVideoType;
  duration?: string;
  thumbnail: string;
  description?: string;
  url: string;
}

const Home: React.FC = () => {
  const navigate = useNavigate();
  const userId = useSelector((state: TRootState) => state.user.id);
  const profile = useSelector((state: TRootState) => state.user.profile);
  const quote = useSelector((state: TRootState) => state.user.quote);
  const isOwnerRole = profile?.role && profile?.role === EUserType.OWNER;
  const [motd, setMotd] = useState<TMessageOfTheDay | null>(null);
  const [trainingMaterials, setTrainingMaterials] = useState<DashboardVideo[]>([]);
  const [isVideosLoading, setIsVideosLoading] = useState<boolean>(true);

  const requestDashboardParams: TCommonGetDashboardParams = {
    timezome: getCurrentTimezone(),
  };

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [openExpireTrialModal, setOpenExpireTrialModal] = useState<boolean>(false);
  const [count, setCount] = useState<number>(0);
  const [dashboardDetail, setDashboardDetail] = useState<TGetDashboardResponse>();

  const fetchTrialTime = async () => {
    try {
      const trial = await getTrialTime();
      if (trial.trialEnd) {
        const remainingTrialTime = dayjs(trial.trialEnd).diff(dayjs(), 'd') + 1;

        // Only show the trial expiration modal if there are days remaining (not expired yet)
        // This prevents redirect loops with the PricingPackage page
        if (remainingTrialTime > 0 && remainingTrialTime <= 3) {
          setCount(remainingTrialTime);
          setOpenExpireTrialModal(true);
        }
      }
    } catch (error) {
      const message = (error as ResponseError).message;
      showErrorToast(message);
    }
  };

  const handleSubmitExpireTrialModal = () => {
    navigate(RoutePaths.PROFILE, { state: { activeTab: EProfileTabKey.SUBSCRIPTION } });
  };

  const fetchMotd = useCallback(async () => {
    try {
      // Only fetch MOTD if user has an active subscription/trial
      if (profile?.clinic?.paymentStatus && 
          (profile.clinic.paymentStatus === EPaymentStatus.ACTIVE || 
           profile.clinic.paymentStatus === EPaymentStatus.TRIALING)) {
        const response = await getTheMessageOfTheDay();
        setMotd(response);
      }
    } catch (error) {
      // Silently handle the error - don't show toast as this is not critical
      console.error('Failed to fetch Message of the Day:', error);
    }
  }, [profile?.clinic?.paymentStatus]);

  const fetchDashboardData = useCallback(async () => {
    try {
      if (isOwnerRole === undefined) return;

      const response = isOwnerRole
        ? await getOwnerDashboard(requestDashboardParams)
        : await getPractitionerDashboard(requestDashboardParams);

      if (response) {
        setDashboardDetail(response);
      }
    } catch (error) {
      // Only show error toast if this isn't a payment status issue
      if (profile?.clinic?.paymentStatus && profile.clinic.paymentStatus !== EPaymentStatus.CANCELED) {
        showErrorToast(`Get ${isOwnerRole ? 'owner' : 'practitioner'} dashboard failed!`);
      } else {
        console.error('Dashboard fetch failed - payment status issue:', error);
      }
    } finally {
      setIsLoading(false);
    }
  }, [isOwnerRole, profile?.clinic?.paymentStatus]);

  const fetchDashboardVideos = useCallback(async () => {
    try {
      // Don't fetch videos if payment status is invalid
      if (!profile?.clinic?.paymentStatus || 
          profile.clinic.paymentStatus === EPaymentStatus.CANCELED) {
        return;
      }
      
      setIsVideosLoading(true);
      const videos = await getDashboardVideos();
      setTrainingMaterials(videos);
    } catch (error) {
      console.error('Failed to fetch dashboard videos:', error);
      // Don't show error toast to user as this is not critical
    } finally {
      setIsVideosLoading(false);
    }
  }, [profile?.clinic?.paymentStatus]);

  useEffect(() => {
    if (profile?.role === EUserType.OWNER || profile?.role === EUserType.SOLO_PRACTITIONER) {
      fetchTrialTime();
    }
    
    // Only fetch additional dashboard data if we have a valid subscription status
    // This prevents infinite loops between Home and PricingPackage
    if (
      isOwnerRole !== undefined && 
      profile?.clinic?.paymentStatus && 
      profile.clinic.paymentStatus !== EPaymentStatus.CANCELED
    ) {
      fetchDashboardData();
      fetchMotd();
      fetchDashboardVideos();
    } else if (isOwnerRole !== undefined) {
      // Still fetch basic dashboard data without the other components
      fetchDashboardData();
    }
  }, [isOwnerRole, profile?.role, profile?.clinic?.paymentStatus, fetchDashboardVideos]);

  if (isLoading) {
    return (
      <div style={{
        height: 'calc(100vh - 64px)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}>
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div className="Home" style={{
      padding: '24px',
      backgroundColor: '#fafafa',
      minHeight: 'calc(100vh - 64px)',
      width: '100%',
      boxSizing: 'border-box',
      overflow: 'auto'
    }}>
      <div style={{ maxWidth: '1600px', margin: '0 auto' }}>
        <Row gutter={[16, 16]}>
          {/* First Row: Welcome Banner and Message of the Day */}
          <Col xs={24}>
            <Row gutter={[16, 16]}>
              <Col xs={24} md={12}>
                <WelcomeBanner profile={profile} />
              </Col>
              <Col xs={24} md={12}>
                <DashboardCard
                  title="Message of the Day"
                  icon={<NotificationOutlined />}
                  content={
                    motd ? (
                      <div style={{
                        height: '140px',
                        overflow: 'auto',
                        padding: '8px 0'
                      }}>
                        <MarkdownContent content={motd.patchNotes} />
                      </div>
                    ) : (
                      <div style={{
                        height: '140px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}>
                        <Spin />
                      </div>
                    )
                  }
                  color="#48ABE2"
                />
              </Col>
            </Row>
          </Col>

          {/* Second Row: Quote of the Day */}
          <Col xs={24}>
            <DashboardCard
              title="Quote of the Day"
              icon={<CommentOutlined />}
              content={
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', minHeight: '100px' }}>
                  <QuoteContent
                    content={quote?.message || "Success is not final, failure is not fatal: it is the courage to continue that counts."}
                    author={quote?.author || "Winston Churchill"}
                  />
                </div>
              }
              color="#48ABE2"
            />
          </Col>

          {/* Third Row: Stats Cards */}
          <Col xs={24}>
            <Row gutter={[16, 16]}>
              <Col xs={24} md={12} >
                <DashboardCard
                  title="Active Clients"
                  icon={<TeamOutlined />}
                  content={
                    <div style={{
                      height: '200px',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}>
                      <Title level={1} style={{
                        color: '#48ABE2',
                        margin: 0,
                        fontSize: '2.5rem',
                        lineHeight: 1
                      }}>
                        {dashboardDetail?.statistic?.numberActiveClient || 0}
                      </Title>
                      <Text style={{
                        fontSize: '1rem',
                        color: 'rgba(0, 0, 0, 0.65)',
                        marginTop: '8px'
                      }}>
                        active clients
                      </Text>
                    </div>
                  }
                  color="#48ABE2"
                  onClick={() => navigate('/clients')}
                />
              </Col>

              <Col xs={24} md={12} >
                <DashboardCard
                  title="Recent Messages"
                  icon={<MessageOutlined />}
                  content={
                    <div style={{
                      height: '200px',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}>
                      <Title level={1} style={{
                        color: '#48ABE2',
                        margin: 0,
                        fontSize: '2.5rem',
                        lineHeight: 1
                      }}>
                        {dashboardDetail?.newMessages?.length || 0}
                      </Title>
                      <Text style={{
                        fontSize: '1rem',
                        color: 'rgba(0, 0, 0, 0.65)',
                        marginTop: '8px'
                      }}>
                        unread messages
                      </Text>
                    </div>
                  }
                  color="#48ABE2"
                  onClick={() => navigate('/messages')}
                />
              </Col>
            </Row>
          </Col>

          {/* Fourth Row: Training Materials */}
          <Col xs={24}>
            <DashboardCard
              title="Getting Started"
              icon={<VideoCameraOutlined />}
              content={
                isVideosLoading ? (
                  <div style={{
                    height: '200px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}>
                    <Spin />
                  </div>
                ) : (
                  <List
                    grid={{
                      gutter: 16,
                      xs: 1,
                      sm: 2,
                      md: 3,
                      lg: 3,
                      xl: 3,
                      xxl: 3,
                    }}
                    dataSource={trainingMaterials}
                    renderItem={(item) => (
                      <List.Item>
                        <Card
                          hoverable
                          style={{ 
                            borderRadius: 8,
                            overflow: 'hidden',
                            height: '100%'
                          }}
                          onClick={() => window.open(item.url, '_blank')}
                          cover={
                            <div style={{
                              height: 140,
                              overflow: 'hidden',
                              position: 'relative',
                              backgroundColor: '#f5f5f5'
                            }}>
                              <img
                                alt={item.title}
                                src={item.thumbnail}
                                style={{
                                  width: '100%',
                                  height: '100%',
                                  objectFit: 'cover'
                                }}
                              />
                              {item.type === DashboardVideoType.VIDEO && item.duration && (
                                <div style={{
                                  position: 'absolute',
                                  bottom: 8,
                                  right: 8,
                                  backgroundColor: 'rgba(0, 0, 0, 0.75)',
                                  color: 'white',
                                  padding: '4px 8px',
                                  borderRadius: 4,
                                  fontSize: '0.8rem'
                                }}>
                                  {item.duration}
                                </div>
                              )}
                            </div>
                          }
                        >
                          <Card.Meta
                            title={
                              <div style={{ 
                                display: 'flex',
                                alignItems: 'center',
                                gap: 8
                              }}>
                                {item.type === DashboardVideoType.VIDEO ? 
                                  <VideoCameraOutlined style={{ color: '#48ABE2' }} /> : 
                                  <ProfileOutlined style={{ color: '#48ABE2' }} />
                                }
                                <span>{item.title}</span>
                              </div>
                            }
                            description={
                              <div>
                                <Text type="secondary" style={{ fontSize: '0.9rem' }}>
                                  {item.description}
                                </Text>
                                {item.type === DashboardVideoType.ARTICLE && item.duration && (
                                  <div style={{ 
                                    marginTop: 8,
                                    fontSize: '0.8rem',
                                    color: '#8c8c8c'
                                  }}>
                                    {item.duration}
                                  </div>
                                )}
                              </div>
                            }
                          />
                        </Card>
                      </List.Item>
                    )}
                    locale={{ emptyText: 'No training materials available' }}
                  />
                )
              }
              color="#48ABE2"
            />
          </Col>
        </Row>
      </div>

      <ExpireTrialModal
        open={openExpireTrialModal}
        onCancel={() => setOpenExpireTrialModal(false)}
        handleSubmit={handleSubmitExpireTrialModal}
        count={count}
      />
    </div>
  );
};

export default Home;
