/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef, useCallback, useMemo, forwardRef } from 'react';
import { Button, Space, Typography, Modal, Tooltip, Spin, Tag, Drawer, Select, message, Card, Switch, Progress, List, AutoComplete, Form, Radio, Alert, DatePicker, Table, Input, Tabs, Steps } from 'antd';
import { ArrowLeftOutlined, UserOutlined, AudioOutlined, AudioMutedOutlined, DeleteOutlined, InfoCircleOutlined, CheckCircleOutlined, FlagOutlined, EditOutlined, SaveOutlined, SettingOutlined, PieChartOutlined, ReloadOutlined, SmileOutlined, MehOutlined, FrownOutlined, ArrowRightOutlined, LoadingOutlined, FileTextOutlined, WarningOutlined, PlusOutlined, GlobalOutlined, TeamOutlined, LockOutlined, CloseOutlined, DownOutlined, UpOutlined, FilePdfOutlined, UploadOutlined, HistoryOutlined, QuestionCircleOutlined, SoundOutlined, UserSwitchOutlined, ClockCircleOutlined, CopyOutlined, CaretRightOutlined, PauseOutlined, StopOutlined, SendOutlined, CheckOutlined, CloudUploadOutlined } from '@ant-design/icons';
import type { TabsProps } from 'antd';
import { exportToPDF } from 'src/utils/document-export';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHatWizard } from '@fortawesome/free-solid-svg-icons';
import { ApiClient } from 'src/services/api-client';
import { useAppDispatch } from 'src/stores';
import { updateTranscriptSegmentAction, updateTranscriptAction, processAudioWithWhisperAction, uploadTranscriptToClientAction, updateTemplateAction } from 'src/stores/clients/clients-actions';
import { WhisperTranscriptionResponse } from 'src/stores/clients/clients-actions';
import { format } from 'date-fns';
import { EUserProfile } from 'src/variables/storage';

const { Title, Text } = Typography;
const { TabPane } = Tabs;

// Define interfaces locally
interface GeneratedDoc {
  id: string;
  content: string;
  name: string;
  isGenerated?: boolean;
  duration?: number;
  sessionId?: string;
}

interface APITranscriptSegmentWithSentiment {
  speaker: string;
  text: string;
  startTime: number;
  endTime: number;
  confidence: number;
  sentiment: {
    score: number;
    label: 'positive' | 'negative' | 'neutral';
  };
}

interface Session {
  id: string;
  sessionId: string;
  recordingDate: string;
  duration: number;
  totalSegments: number;
  averageConfidence: number;
  createdAt: string;
  clientName?: string;
  clientId: string;
}

interface QuickNote {
  timestamp: number;
  note: string;
  type: 'important' | 'followup' | 'observation';
}

interface TemplateVariable {
  name: string;
  value: string;
  type: 'custom';
  description: string;
}

interface ActiveTemplate {
  id: string;
  name: string;
  content: string;
  variables: TemplateVariable[];
  usage_count: number;
  createdBy: string;
}

interface SpeakerLabel {
  id: string;
  label: string;
  description?: string;
  color?: string;
}

interface TranscriptSegment {
  id: string;
  valid?: boolean;
  isSystem?: boolean;
  speaker: string;
  confidence: number;
  sentiment: {
    label: 'positive' | 'negative' | 'neutral';
    score: number;
  };
  startTime: number;
  endTime: number;
  time: string;
  text: string;
  isEditing?: boolean;
  originalText?: string;
  speakerLabel?: SpeakerLabel;
}

// Add new interfaces for audio devices
interface AudioDevice {
  deviceId: string;
  label: string;
  kind: MediaDeviceKind;
}

// Add interface for system audio constraints
interface SystemAudioConstraints extends MediaTrackConstraints {
  // Add browser-specific constraints
  suppressLocalAudioPlayback?: boolean;
  mediaSource?: 'screen' | 'window' | 'browser' | 'microphone';
}

interface AudioVisualizerProps {
  analyser: AnalyserNode | null;
  color?: string;
  height?: number;
  fftSize?: number;
}

// Move AudioVisualizer component outside of LiveTranscribeTab
const AudioVisualizer: React.FC<AudioVisualizerProps> = React.memo(({ 
  analyser, 
  color = '#52c41a', 
  height = 100,
  fftSize = 2048 
}) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const animationFrameRef = useRef<number>();

  useEffect(() => {
    if (!analyser || !canvasRef.current) return;

    analyser.fftSize = fftSize;
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    const draw = () => {
      const bufferLength = analyser.frequencyBinCount;
      const dataArray = new Uint8Array(bufferLength);
      analyser.getByteTimeDomainData(dataArray);

      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.lineWidth = 2;
      ctx.strokeStyle = color;
      ctx.beginPath();

      const sliceWidth = canvas.width / bufferLength;
      let x = 0;

      for (let i = 0; i < bufferLength; i++) {
        const v = dataArray[i] / 128.0;
        const y = (v * canvas.height) / 2;

        if (i === 0) {
          ctx.moveTo(x, y);
        } else {
          ctx.lineTo(x, y);
        }

        x += sliceWidth;
      }

      ctx.lineTo(canvas.width, canvas.height / 2);
      ctx.stroke();
      animationFrameRef.current = requestAnimationFrame(draw);
    };

    draw();

    return () => {
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
    };
  }, [analyser, color, fftSize]);

  return (
    <canvas
      ref={canvasRef}
      width={300}
      height={height}
      style={{
        width: '100%',
        maxWidth: '600px',
        height: `${height}px`,
        background: 'rgba(0, 0, 0, 0.05)',
        borderRadius: '8px',
      }}
    />
  );
});

AudioVisualizer.displayName = 'AudioVisualizer';

const parseTranscriptContent = (content: string): TranscriptSegment[] => {
  const lines = content.split('\n').filter(line => line.trim());
  const segments: TranscriptSegment[] = [];
  
  const timeToSeconds = (timeStr: string): number => {
    const [minutes, seconds] = timeStr.trim().split(':').map(Number);
    return minutes * 60 + seconds;
  };
  
  // First pass: parse all valid segments to get their start times
  const validSegments = lines.map((line) => {
    // Updated regex to capture segment ID if present
    const segmentRegex = /\[ID:([^\]]+)\]?\s*\[([^\]]+)\]\s*\[CONF\s*(\d+)%\]\s*\[([A-Z]+)\s*(\d+)%\]\s*\[([^-\]]+)-\s*([^\]]+)\]:\s*(.*)/;
    const match = line.match(segmentRegex);

    if (match) {
      const startTime = timeToSeconds(match[6]);
      return {
        id: match[1] || `segment-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`, // Use original ID if present, otherwise generate unique one
        valid: true,
        speaker: match[2],
        confidence: parseInt(match[3]),
        sentiment: {
          label: match[4].toLowerCase() as 'positive' | 'negative' | 'neutral',
          score: parseInt(match[5])/100
        },
        startTime,
        text: match[8],
        rawEndTime: timeToSeconds(match[7]),
        isEditing: false
      };
    } else if (line.startsWith('[SYSTEM]:')) {
      return {
        id: `system-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`,
        valid: false,
        speaker: 'System',
        text: line.replace('[SYSTEM]:', '').trim(),
        isSystem: true,
        confidence: 0,
        sentiment: { label: 'neutral' as const, score: 0 },
        startTime: 0,
        rawEndTime: 0,
        isEditing: false
      };
    } else {
      return {
        id: `unknown-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`,
        valid: false,
        speaker: 'Unknown',
        text: line,
        isSystem: false,
        confidence: 0,
        sentiment: { label: 'neutral' as const, score: 0 },
        startTime: 0,
        rawEndTime: 0,
        isEditing: false
      };
    }
  });

  // Second pass: calculate end times based on next segment's start time
  validSegments.forEach((segment, index) => {
    if (!segment.valid) {
      segments.push({
        ...segment,
        endTime: segment.startTime,
        time: ''
      });
      return;
    }

    let endTime: number;
    
    if (index < validSegments.length - 1) {
      // For all segments except the last one, end time is the start time of the next valid segment
      const nextValidSegment = validSegments.slice(index + 1).find(s => s.valid);
      if (nextValidSegment) {
        endTime = nextValidSegment.startTime;
      } else {
        // If no next valid segment, use the raw end time or add default duration
        endTime = Math.max(segment.rawEndTime, segment.startTime + 5);
      }
    } else {
      // For the last segment, use the raw end time or add default duration
      endTime = Math.max(segment.rawEndTime, segment.startTime + 5);
    }

    // Format the time display
    const formatTimeMMSS = (seconds: number): string => {
      const mins = Math.floor(seconds / 60);
      const secs = Math.floor(seconds % 60);
      return `${mins}:${secs.toString().padStart(2, '0')}`;
    };

    segments.push({
      ...segment,
      endTime,
      time: `${formatTimeMMSS(segment.startTime)} - ${formatTimeMMSS(endTime)}`
    });
  });
  
  return segments;
};

// Add helper functions for segment operations
const createNewSegment = (position: number, segments: TranscriptSegment[]): TranscriptSegment => {
  const newId = `segment-${Date.now()}`;
  const prevSegment = segments[position - 1];
  const nextSegment = segments[position];

  let startTime = 0;
  let endTime = 0;

  if (prevSegment && nextSegment) {
    // Place the new segment between the previous and next segments
    startTime = prevSegment.endTime;
    endTime = nextSegment.startTime;
  } else if (prevSegment) {
    // Place after the last segment
    startTime = prevSegment.endTime;
    endTime = startTime + 5; // Default 5-second duration
  } else if (nextSegment) {
    // Place before the first segment
    startTime = 0;
    endTime = nextSegment.startTime;
  }

  const formatTimeMMSS = (seconds: number): string => {
    const mins = Math.floor(seconds / 60);
    const secs = Math.floor(seconds % 60);
    return `${mins}:${secs.toString().padStart(2, '0')}`;
  };

  return {
    id: newId,
    valid: true,
    speaker: 'Speaker',
    confidence: 100,
    sentiment: {
      label: 'neutral',
      score: 0.5
    },
    startTime,
    endTime,
    time: `${formatTimeMMSS(startTime)} - ${formatTimeMMSS(endTime)}`,
    text: '',
    isEditing: true
  };
};

const updateSegment = (
  segment: TranscriptSegment,
  updates: Partial<TranscriptSegment>,
  segments: TranscriptSegment[]
): TranscriptSegment[] => {
  const index = segments.findIndex(s => s.id === segment.id);
  if (index === -1) return segments;

  const updatedSegment = { ...segment, ...updates };
  const newSegments = [...segments];
  newSegments[index] = updatedSegment;

  // Recalculate times if necessary
  if ('startTime' in updates || 'endTime' in updates) {
    // Update previous segment's end time if needed
    if (index > 0) {
      newSegments[index - 1] = {
        ...newSegments[index - 1],
        endTime: updatedSegment.startTime
      };
    }
    // Update next segment's start time if needed
    if (index < segments.length - 1) {
      newSegments[index + 1] = {
        ...newSegments[index + 1],
        startTime: updatedSegment.endTime
      };
    }
  }

  return newSegments;
};

const deleteSegment = (
  segmentId: string,
  segments: TranscriptSegment[]
): TranscriptSegment[] => {
  const index = segments.findIndex(s => s.id === segmentId);
  if (index === -1) return segments;

  const newSegments = [...segments];
  newSegments.splice(index, 1);

  // Adjust times of adjacent segments if needed
  if (index > 0 && index < segments.length - 1) {
    const prevSegment = newSegments[index - 1];
    const nextSegment = newSegments[index];
    prevSegment.endTime = nextSegment.startTime;
  }

  return newSegments;
};

// Add speaker color generation function before the LiveTranscribeTab component
const generateSpeakerColor = (speaker: string): string => {
  // Predefined set of pleasant, accessible colors
  const colors = [
    '#1890ff', // Blue
    '#722ed1', // Purple
    '#13c2c2', // Cyan
    '#52c41a', // Green
    '#faad14', // Gold
    '#eb2f96', // Pink
    '#fa541c', // Volcano
    '#2f54eb', // Geekblue
  ];
  
  // Generate a consistent index for each speaker
  let hash = 0;
  for (let i = 0; i < speaker.length; i++) {
    hash = speaker.charCodeAt(i) + ((hash << 5) - hash);
  }
  
  // Use the hash to select a color
  const index = Math.abs(hash) % colors.length;
  return colors[index];
};

interface LiveTranscribeTabProps {
  clientId: string;
  clientName: string;
  isRecording: boolean;
  isPaused: boolean;
  onRecordingStateChange: (recording: boolean, initializing: boolean) => void;
  onPauseStateChange: (paused: boolean) => void;
  onProcessRecording: (audio: Blob) => void;
  onTranscriptStateChange: (hasTranscript: boolean) => void;
  onTranscriptUpdate: (segments: APITranscriptSegmentWithSentiment[]) => void;
  isGeneratingDocument?: boolean;
  generatedDocument?: string;
  generatedDocs: Array<{id: string; content: string; name: string; isGenerated?: boolean}>;
  activeTab: string;
  setActiveTab: (tab: string) => void;
  onCloseDocument: (docId: string) => void;
  customVariables?: TemplateVariable[];
  isTemplateVariablesCollapsed?: boolean;
  onSessionLoad?: (session: Session) => void;
  quickNotes?: QuickNote[];
  selectedTemplate?: ActiveTemplate | null;
  onTemplateClose?: () => void;
  renderTemplateVariables?: () => React.ReactNode;
  isAddingVariable?: boolean;
  setIsAddingVariable?: (isAdding: boolean) => void;
  newVariableName?: string;
  setNewVariableName?: (name: string) => void;
  newVariableValue?: string;
  setNewVariableValue?: (value: string) => void;
  handleAddCustomVariable?: () => void;
  onSegmentsChange?: (count: number) => void;
  segmentRefs?: {
    speakerLabelRef: React.RefObject<HTMLDivElement>;
    confidenceRef: React.RefObject<HTMLDivElement>;
    sentimentIconRef: React.RefObject<HTMLDivElement>;
    timestampRef: React.RefObject<HTMLDivElement>;
    editButtonRef: React.RefObject<HTMLDivElement>;
    deleteButtonRef?: React.RefObject<HTMLDivElement>;
    transcriptionSegmentRef?: React.RefObject<HTMLDivElement>;
    overallSentimentRef?: React.RefObject<HTMLDivElement>;
    selectedTemplateRef?: React.RefObject<HTMLDivElement>;
    generatedDocumentRef?: React.RefObject<HTMLDivElement>;
    piiSettingsRef?: React.RefObject<HTMLDivElement>;
  };
  piiSettings?: {
    enabled: boolean;
    redactTypes: {
      personalInfo: boolean;
      financialInfo: boolean;
      locationInfo: boolean;
      temporalInfo: boolean;
      identityInfo: boolean;
      otherInfo: boolean;
    };
  };
  showTemplateModal: boolean;
  setShowTemplateModal: (show: boolean) => void;
  hasExistingTranscript: boolean;
  renderTemplateModal: () => React.ReactNode;
  onGeneratedDocsChange?: (docs: GeneratedDoc[]) => void;
  isClientLinked: boolean;
  selectedClientId: string | null;
}

const LiveTranscribeTab = forwardRef<HTMLDivElement, LiveTranscribeTabProps>(({
  clientId,
  clientName,
  isRecording,
  isPaused: isPausedProp,
  onRecordingStateChange,
  onPauseStateChange,
  onProcessRecording,
  onTranscriptStateChange,
  onTranscriptUpdate,
  isGeneratingDocument,
  generatedDocument,
  generatedDocs,
  activeTab,
  setActiveTab,
  onCloseDocument,
  customVariables,
  isTemplateVariablesCollapsed,
  onSessionLoad,
  quickNotes = [],
  selectedTemplate,
  onTemplateClose,
  renderTemplateVariables,
  isAddingVariable,
  setIsAddingVariable,
  newVariableName,
  setNewVariableName,
  newVariableValue,
  setNewVariableValue,
  handleAddCustomVariable,
  onSegmentsChange,
  segmentRefs,
  piiSettings,
  showTemplateModal,
  setShowTemplateModal,
  hasExistingTranscript,
  renderTemplateModal,
  onGeneratedDocsChange,
  isClientLinked,
  selectedClientId
}, ref) => {
  const [error, setError] = useState<string | null>(null);
  const [showTemplateDrawer, setShowTemplateDrawer] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [showSentimentSummary, setShowSentimentSummary] = useState(true);
  const [templateContent, setTemplateContent] = useState(selectedTemplate?.content || '');
  const [originalTemplateContent, setOriginalTemplateContent] = useState(selectedTemplate?.content || '');
  const [showGeneratedDoc, setShowGeneratedDoc] = useState(false);
  const [isTourOpen, setIsTourOpen] = useState(false);
  const [isTourEnabled, setIsTourEnabled] = useState(true);
  const [isInitializing, setIsInitializing] = useState(false);
  const [hasStoppedRecording, setHasStoppedRecording] = useState(false);
  const [hasMicrophonePermission, setHasMicrophonePermission] = useState<boolean | null>(null);
  const [recordedAudio, setRecordedAudio] = useState<Blob | null>(null);
  const [isSavingTemplate, setIsSavingTemplate] = useState(false);
  const [isProcessingRecording, setIsProcessingRecording] = useState<boolean>(false);
  const [processingStep, setProcessingStep] = useState<'idle' | 'processing' | 'generating'>('idle');
  const [recordingDuration, setRecordingDuration] = useState<number>(0);
  const [recordingStartTime, setRecordingStartTime] = useState<number | null>(null);
  const [isPaused, setIsPaused] = useState(isPausedProp);
  const [audioDevices, setAudioDevices] = useState<AudioDevice[]>([]);
  const [selectedDeviceId, setSelectedDeviceId] = useState<string>('default');
  const [useInternalAudio, setUseInternalAudio] = useState(false);
  const [analyserNode, setAnalyserNode] = useState<AnalyserNode | null>(null);
  const [audioContext, setAudioContext] = useState<AudioContext | null>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const animationFrameRef = useRef<number>();

  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const audioChunksRef = useRef<Blob[]>([]);
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  const [segments, setSegments] = useState<TranscriptSegment[]>([]);
  const currentDoc = useMemo(() => {
    if (activeTab.startsWith('doc-')) {
      const docId = activeTab.replace('doc-', '');
      return generatedDocs.find(doc => doc.id === docId);
    }
    return null;
  }, [activeTab, generatedDocs]);

  const [speakerLabels, setSpeakerLabels] = useState<SpeakerLabel[]>([
    { id: 'default-therapist', label: 'Therapist', color: '#1890ff', description: 'The therapist or counselor' },
    { id: 'default-client', label: 'Client', color: '#52c41a', description: 'The client or patient' }
  ]);
  const [isAddingSpeaker, setIsAddingSpeaker] = useState(false);
  const [newSpeakerLabel, setNewSpeakerLabel] = useState('');
  const [newSpeakerDescription, setNewSpeakerDescription] = useState('');
  const [newSpeakerColor, setNewSpeakerColor] = useState('#1890ff');
  const [showSpeakerSettings, setShowSpeakerSettings] = useState(false);
  const [modifiedSegments, setModifiedSegments] = useState<string[]>([]);
  const [isUpdating, setIsUpdating] = useState(false);

  const dispatch = useAppDispatch();

  const [uploadError, setUploadError] = useState<string | null>(null);
  const [failedUploadAudio, setFailedUploadAudio] = useState<Blob | null>(null);
  const [isTesting, setIsTesting] = useState(false);
  const [testAnalyser, setTestAnalyser] = useState<AnalyserNode | null>(null);
  const testStreamRef = useRef<MediaStream | null>(null);
  const [uploadingAudio, setUploadingAudio] = useState(false);
  const audioInputRef = useRef<HTMLInputElement>(null);

  // Add state for process duration tracking
  const [processingElapsedSeconds, setProcessingElapsedSeconds] = useState(0);
  const processingTimerRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize(); // Initial check
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Update template content when selected template changes
  useEffect(() => {
    if (selectedTemplate) {
      setTemplateContent(selectedTemplate.content);
      setOriginalTemplateContent(selectedTemplate.content);
      setActiveTab('template');
    } else {
      setTemplateContent('');
      setOriginalTemplateContent('');
      if (activeTab === 'template') {
        setActiveTab('transcript');
      }
    }
  }, [selectedTemplate]);

  const handleTemplateContentChange = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTemplateContent(e.target.value);
  }, []);

  const isTemplateOwner = useMemo(() => {
    if (!selectedTemplate) return false;
    const userId = localStorage.getItem(EUserProfile.PROFILE_ID);
    return selectedTemplate.createdBy === userId;
  }, [selectedTemplate]);

  const handleCloseDocument = useCallback((docId: string, e?: React.MouseEvent) => {
    e?.stopPropagation();
    
    // Find the document
    const doc = generatedDocs.find(d => d.id === docId);
    
    // Prevent closing if document is being generated
    if (doc && isGeneratingDocument && doc.content === '') {
      message.warning('Please wait for the document to finish generating before closing');
      return;
    }

    // If we're closing the current tab, switch to another tab
    if (activeTab === `doc-${docId}`) {
      // Find the next available tab to switch to
      const remainingDocs = generatedDocs.filter(d => d.id !== docId);
      if (remainingDocs.length > 0) {
        // Switch to the last remaining document
        setActiveTab(`doc-${remainingDocs[remainingDocs.length - 1].id}`);
      } else if (selectedTemplate) {
        // If there's an active template, switch to that
        setActiveTab('template');
      } else {
        // Otherwise switch to transcript tab
        setActiveTab('transcript');
      }

      // Clear any segments if we were in a transcript
      if (!doc?.isGenerated) {
        setSegments([]);
        setModifiedSegments([]);
      }
    }
    
    onCloseDocument(docId);
  }, [onCloseDocument, generatedDocs, isGeneratingDocument, activeTab, selectedTemplate]);

  const handleExportDocument = (doc: GeneratedDoc) => {
    exportToPDF({
      title: doc.name,
      content: doc.content,
      generatedAt: new Date(),
      metadata: {
        wordCount: doc.content.split(/\s+/).length,
        estimatedReadTime: Math.ceil(doc.content.split(/\s+/).length / 200)
      }
    });
  };

  const handleSaveTemplateChanges = async () => {
    if (!selectedTemplate) return;

    try {
      setIsSavingTemplate(true);
      
      // Use the same update template action as TemplatesPage
      await dispatch(updateTemplateAction({
        id: selectedTemplate.id,
        template: {
          ...selectedTemplate,
          content: templateContent
        }
      })).unwrap();

      // Update the local content after successful API update
      setOriginalTemplateContent(templateContent);
      message.success('Template saved successfully');
    } catch (error) {
      message.error('Failed to save template changes');
      console.error('Error saving template:', error);
    } finally {
      setIsSavingTemplate(false);
    }
  };

  const renderTemplateContent = () => (
    <div style={{ 
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      background: '#fff',
      borderRadius: '12px',
      border: '1px solid rgba(0, 0, 0, 0.06)',
      boxShadow: '0 2px 8px rgba(0, 0, 0, 0.04)',
      overflow: 'hidden',
      height: '100%',
      gap: '16px',
      padding: '16px'
    }}>
      <div style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}>
        <Text type="secondary">
          Edit your template content below. Use <Text code>{'{{variable}}'}</Text> for placeholders.
          <br />
          <Text type="secondary" style={{ fontSize: '12px' }}>
            {isTemplateOwner 
              ? 'Changes will be saved to the template library.'
              : 'Changes are temporary and will reset when you close the template.'}
          </Text>
        </Text>
        {templateContent !== originalTemplateContent && (
          <Button
            type="primary"
            icon={isTemplateOwner ? <SaveOutlined /> : <CheckOutlined />}
            onClick={() => {
              if (isTemplateOwner) {
                Modal.confirm({
                  title: 'Save Template Changes',
                  content: 'Are you sure you want to save these changes to the template? This will update the template for all users.',
                  okText: 'Save Changes',
                  cancelText: 'Cancel',
                  onOk: handleSaveTemplateChanges,
                  okButtonProps: { loading: isSavingTemplate }
                });
              } else {
                // Just apply changes locally
                if (selectedTemplate) {
                  selectedTemplate.content = templateContent;
                  setOriginalTemplateContent(templateContent);
                  message.success('Template content updated for this session');
                }
              }
            }}
          >
            {isTemplateOwner ? 'Save Changes' : 'Apply Changes'}
          </Button>
        )}
      </div>
      <div ref={segmentRefs?.selectedTemplateRef} style={{ 
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden'
      }}>
        <Input.TextArea
          value={templateContent}
          onChange={handleTemplateContentChange}
          style={{ 
            flex: 1,
            height: '100%',
            resize: 'none',
            fontSize: '16px',
            lineHeight: '1.6',
            padding: '16px',
            border: '1px solid #d9d9d9',
            borderRadius: '6px'
          }}
          placeholder="Enter your template content here..."
          autoSize={{ minRows: 10, maxRows: 20 }}
        />
      </div>
    </div>
  );

  // Check microphone permissions
  const checkMicrophonePermissions = useCallback(async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      // Stop the stream immediately after getting permission
      stream.getTracks().forEach(track => track.stop());
      setHasMicrophonePermission(true);
      return true;
    } catch (error) {
      console.error('Microphone permission error:', error);
      setHasMicrophonePermission(false);
      setError('Microphone access is required for recording. Please grant permission and try again.');
      return false;
    }
  }, []);

  // Check permissions on mount
  useEffect(() => {
    checkMicrophonePermissions();
  }, [checkMicrophonePermissions]);

  const formatTime = (seconds: number): string => {
    const mins = Math.floor(seconds / 60);
    const secs = Math.floor(seconds % 60);
    return `${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  };

  const startTimer = () => {
    if (timerRef.current) {
      clearInterval(timerRef.current);
    }
    setRecordingStartTime(Date.now());
    timerRef.current = setInterval(() => {
      setRecordingDuration(prev => prev + 1);
    }, 1000);
  };

  const pauseTimer = () => {
    if (timerRef.current) {
      clearInterval(timerRef.current);
    }
  };

  const resumeTimer = () => {
    startTimer();
  };

  const stopTimer = () => {
    if (timerRef.current) {
      clearInterval(timerRef.current);
    }
    setRecordingDuration(0);
    setRecordingStartTime(null);
  };

  // Add useEffect to sync with prop
  useEffect(() => {
    setIsPaused(isPausedProp);
  }, [isPausedProp]);

  // Add new function to detect browser capabilities
  const detectBrowserCapabilities = () => {
    const ua = navigator.userAgent.toLowerCase();
    return {
      isChrome: ua.includes('chrome') && !ua.includes('edge'),
      isSafari: ua.includes('safari') && !ua.includes('chrome'),
      isFirefox: ua.includes('firefox'),
      hasDisplayMedia: 'getDisplayMedia' in navigator.mediaDevices,
      hasUserMedia: 'getUserMedia' in navigator.mediaDevices
    };
  };

  // Update getSystemAudioStream function
  const getSystemAudioStream = async (): Promise<MediaStream | null> => {
    const browser = detectBrowserCapabilities();
    
    try {
      const streams: MediaStream[] = [];
      
      // For Chrome/Edge, use getDisplayMedia with specific audio constraints
      if (browser.isChrome || browser.hasDisplayMedia) {
        const gdmOptions = {
          audio: {
            // Remove suppressLocalAudioPlayback as it's not widely supported
            echoCancellation: false, // Disable echo cancellation for system audio
            noiseSuppression: false, // Disable noise suppression for system audio
            autoGainControl: false   // Disable gain control for system audio
          } as MediaTrackConstraints,
          video: {
            width: 1,  // Minimal video track
            height: 1,
            frameRate: 1
          }
        };

        try {
          const systemStream = await navigator.mediaDevices.getDisplayMedia(gdmOptions);
          
          // If we got a video track, stop it as we only need audio
          const videoTrack = systemStream.getVideoTracks()[0];
          if (videoTrack) {
            videoTrack.stop();
            systemStream.removeTrack(videoTrack);
          }

          // Verify we have an audio track
          const audioTrack = systemStream.getAudioTracks()[0];
          if (!audioTrack) {
            throw new Error('No audio track available - please select an audio source');
          }

          // Set audio track settings
          try {
            await audioTrack.applyConstraints({
              echoCancellation: false,
              noiseSuppression: false,
              autoGainControl: false
            });
          } catch (e) {
            console.warn('Could not apply audio constraints:', e);
          }

          streams.push(systemStream);
        } catch (error) {
          if (error instanceof Error && error.name === 'NotAllowedError') {
            throw new Error('Please select "Share system audio" or "Share tab audio" when prompted');
          }
          throw error;
        }
      }

      // For Firefox
      if (browser.isFirefox) {
        try {
          const tabStream = await navigator.mediaDevices.getUserMedia({
            audio: {
              mediaSource: 'screen' // Firefox-specific
            } as MediaTrackConstraints
          });
          streams.push(tabStream);
        } catch (error) {
          throw new Error('Firefox only supports tab audio capture. Please use Chrome for full system audio.');
        }
      }

      // For Safari and others, show compatibility message
      if (browser.isSafari) {
        throw new Error('System audio capture is not supported in Safari. Please use Chrome for this feature.');
      }

      // If no streams were added, throw an error
      if (streams.length === 0) {
        throw new Error('System audio capture is not supported in your browser. Please use Chrome.');
      }

      // If we have at least one stream, return the first one
      return streams[0];
    } catch (error) {
      console.error('Error getting system audio:', error);
      const errorMessage = error instanceof Error ? error.message : 'Failed to capture system audio';
      message.error(errorMessage);
      return null;
    }
  };

  // Update handleStartRecording
  const handleStartRecording = async () => {
    if (!hasMicrophonePermission && !useInternalAudio) {
      const hasPermission = await checkMicrophonePermissions();
      if (!hasPermission) return;
    }

    try {
      setIsInitializing(true);
      setRecordedAudio(null);
      audioChunksRef.current = [];
      setRecordingDuration(0);
      setIsPaused(false);

      const streams: MediaStream[] = [];

      if (useInternalAudio) {
        // Get system audio stream
        const systemStream = await getSystemAudioStream();
        if (!systemStream) {
          setIsInitializing(false);
          return;
        }
        streams.push(systemStream);

        // Also get microphone stream when system audio is enabled
        try {
          const micStream = await navigator.mediaDevices.getUserMedia({
            audio: {
              deviceId: selectedDeviceId ? { exact: selectedDeviceId } : undefined,
              channelCount: 1,
              sampleRate: 48000,
              echoCancellation: true,
              noiseSuppression: true,
              autoGainControl: true
            }
          });
          streams.push(micStream);
        } catch (error) {
          console.warn('Could not get microphone access while recording system audio:', error);
          message.warning('Could not access microphone. Only system audio will be recorded.');
        }
      } else {
        // Regular microphone-only recording
        const audioConstraints: MediaTrackConstraints = {
          deviceId: selectedDeviceId ? { exact: selectedDeviceId } : undefined,
          channelCount: 1,
          sampleRate: 48000,
          echoCancellation: true,
          noiseSuppression: true,
          autoGainControl: true
        };

        const micStream = await navigator.mediaDevices.getUserMedia({
          audio: audioConstraints
        });
        streams.push(micStream);
      }

      // Create a new audio context
      const ctx = new (window.AudioContext || window.webkitAudioContext)();
      
      // Create a destination stream to combine all audio sources
      const dest = ctx.createMediaStreamDestination();

      // Connect all streams to the destination
      streams.forEach(stream => {
        stream.getAudioTracks().forEach(track => {
          if (track.readyState === 'live') {
            const source = ctx.createMediaStreamSource(new MediaStream([track]));
            source.connect(dest);
          }
        });
      });

      // Create analyser node for visualization
      const analyser = ctx.createAnalyser();
      const combinedSource = ctx.createMediaStreamSource(dest.stream);
      combinedSource.connect(analyser);
      setAnalyserNode(analyser);
      setAudioContext(ctx);

      const mimeType = MediaRecorder.isTypeSupported('audio/webm;codecs=opus') 
        ? 'audio/webm;codecs=opus'
        : MediaRecorder.isTypeSupported('audio/webm') 
          ? 'audio/webm'
          : 'audio/mp4';

      console.log('Using mime type:', mimeType);

      const mediaRecorder = new MediaRecorder(dest.stream, {
        mimeType,
        audioBitsPerSecond: 128000
      });
      
      setupMediaRecorder(mediaRecorder);
      startTimer();

      mediaRecorder.start(1000);
      onRecordingStateChange(true, false);
      setIsInitializing(false);
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'Unknown error';
      setError('Failed to start recording: ' + errorMessage);
      setIsInitializing(false);
    }
  };

  // Update handleStopRecording to trigger processing
  const handleStopRecording = () => {
    if (mediaRecorderRef.current) {
      try {
        // Stop all tracks in the MediaRecorder's stream and clean them up properly
        const tracks = mediaRecorderRef.current.stream.getTracks();
        tracks.forEach(track => {
          // Check if this is a system audio track (from tab/screen share)
          if (track.label && (
            track.label.includes('tab-capture') || 
            track.label.includes('screen-capture') ||
            track.label.toLowerCase().includes('system audio')
          )) {
            console.log('Stopping system audio track:', track.label);
          }
          track.stop();
          mediaRecorderRef.current?.stream.removeTrack(track);
        });

        // Stop the MediaRecorder itself
        mediaRecorderRef.current.stop();
        mediaRecorderRef.current = null;  // Clear the reference
        
        // Clean up audio context and analyzer node
        if (audioContext) {
          // Disconnect all nodes before closing
          if (analyserNode) {
            analyserNode.disconnect();
          }
          audioContext.close();
          setAudioContext(null);
        }
        setAnalyserNode(null);

        // Reset recording state
        onRecordingStateChange(false, false);
        setHasStoppedRecording(true);
        setIsPaused(false);
        stopTimer();

        // Reset system audio state
        if (useInternalAudio) {
          setUseInternalAudio(false);
        }

        // Check total size of recorded chunks
        const totalSize = audioChunksRef.current.reduce((acc, chunk) => acc + chunk.size, 0);
        if (totalSize > 100 * 1024 * 1024) { // 100MB limit
          setError('Recording size exceeds 100MB limit. Please try recording a shorter session or reduce audio quality settings.');
          setRecordedAudio(null);
          audioChunksRef.current = [];
          return;
        }

        // Start processing if size is within limits
        setIsProcessingRecording(true);
        setProcessingStep('processing');
      } catch (error) {
        const errorMessage = error instanceof Error ? error.message : 'Unknown error';
        setError('Failed to stop recording: ' + errorMessage);
      }
    }
  };

  // Add function to check chunk size during recording
  const checkRecordingSize = useCallback(() => {
    const totalSize = audioChunksRef.current.reduce((acc, chunk) => acc + chunk.size, 0);
    if (totalSize > 95 * 1024 * 1024) { // Warning at 95MB
      message.warning('Recording is approaching size limit. Consider stopping soon to ensure successful processing.');
    }
  }, []);

  const handlePauseRecording = (shouldPause: boolean) => {
    if (!mediaRecorderRef.current) {
      message.error('No active session found');
      return;
    }

    try {
      if (shouldPause && mediaRecorderRef.current.state === 'recording') {
        mediaRecorderRef.current.pause();
        message.info('Session paused');
        setIsPaused(true);
        onPauseStateChange?.(true);
        pauseTimer();
      } else if (!shouldPause && mediaRecorderRef.current.state === 'paused') {
        mediaRecorderRef.current.resume();
        message.success('Session resumed');
        setIsPaused(false);
        onPauseStateChange?.(false);
        resumeTimer();
      } else {
        message.warning(`Cannot ${shouldPause ? 'pause' : 'resume'} - session is ${mediaRecorderRef.current.state}`);
        return;
      }
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'Unknown error';
      setError(`Failed to ${shouldPause ? 'pause' : 'resume'} recording: ` + errorMessage);
      message.error(`Failed to ${shouldPause ? 'pause' : 'resume'} session`);
    }
  };

  // Add event listeners to MediaRecorder
  const setupMediaRecorder = (mediaRecorder: MediaRecorder) => {
    mediaRecorderRef.current = mediaRecorder;

    mediaRecorder.onpause = () => {
      console.log('MediaRecorder paused');
      onPauseStateChange?.(true);
    };

    mediaRecorder.onresume = () => {
      console.log('MediaRecorder resumed');
      onPauseStateChange?.(false);
    };

    mediaRecorder.ondataavailable = (event) => {
      if (event.data.size > 0) {
        console.log('Received audio chunk:', event.data.size, 'bytes');
        audioChunksRef.current.push(event.data);
        checkRecordingSize();
      }
    };

    mediaRecorder.onstop = () => {
      try {
        console.log('Recording stopped, processing chunks...');
        console.log('Total chunks:', audioChunksRef.current.length);
        
        if (audioChunksRef.current.length > 0) {
          const mimeType = mediaRecorder.mimeType || 'audio/webm;codecs=opus';
          const audioBlob = new Blob(audioChunksRef.current, { type: mimeType });
          console.log('Created audio blob:', audioBlob.size, 'bytes');
          setRecordedAudio(audioBlob);
          onTranscriptStateChange(true);
        } else {
          throw new Error('No audio data recorded');
        }
      } catch (error: unknown) {
        console.error('Error in onstop handler:', error);
        const errorMessage = error instanceof Error ? error.message : 'Unknown error';
        setError('Failed to process recorded audio: ' + errorMessage);
      }
      console.log('MediaRecorder stopped');
      onRecordingStateChange(false, false);
      setHasStoppedRecording(true);
    };

    mediaRecorder.onerror = (event: Event) => {
      console.error('MediaRecorder error:', event);
      setError('An error occurred during recording. Please try again.');
    };
  };

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      if (mediaRecorderRef.current) {
        const tracks = mediaRecorderRef.current.stream.getTracks();
        tracks.forEach(track => track.stop());
      }
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
      if (audioContext) {
        audioContext.close();
      }
    };
  }, []);

  const handleTabChange = (key: string) => {
    if (modifiedSegments.length > 0) {
      Modal.confirm({
        title: 'Unsaved Changes',
        content: 'You have unsaved changes to this transcript. Would you like to save them before switching tabs?',
        okText: 'Save Changes',
        cancelText: 'Discard Changes',
        onOk: async () => {
          await handleUpdateSegments();
          setActiveTab(key);
        },
        onCancel: () => {
          setModifiedSegments([]);
          setActiveTab(key);
        }
      });
      return;
    }

    if (key === 'choose-template') {
      if (clientName) {
        setShowTemplateModal(true);
      }
      return;
    }
    setActiveTab(key);
  };

  // Effect to reset UI when document is created
  useEffect(() => {
    if (generatedDocs.length > 0 && isProcessingRecording) {
      // Reset processing states
      setIsProcessingRecording(false);
      setProcessingStep('idle');
      setHasStoppedRecording(false);
      setRecordedAudio(null);
      audioChunksRef.current = [];
      
      // Switch to the newly generated document tab
      const latestDoc = generatedDocs[generatedDocs.length - 1];
      setActiveTab(`doc-${latestDoc.id}`);
    }
  }, [generatedDocs.length, setActiveTab]);

  // Update handleResumeSession to reset pause state
  const handleResumeSession = () => {
    if (recordedAudio) {
      Modal.confirm({
        title: 'Resume Previous Session',
        content: 'Starting a new session will discard the previous recording. Are you sure you want to continue?',
        okText: 'Start New Session',
        cancelText: 'Keep Previous',
        onOk() {
          // Reset all states
          setHasStoppedRecording(false);
          setRecordedAudio(null);
          audioChunksRef.current = [];
          setError(null);
          setIsProcessingRecording(false);
          setProcessingStep('idle');
          setIsPaused(false);
          // Reset audio configuration
          setSelectedDeviceId('default');
          setUseInternalAudio(false);
          setAnalyserNode(null);
          if (audioContext) {
            audioContext.close();
            setAudioContext(null);
          }
        }
      });
    } else {
      // Reset all states without confirmation
      setHasStoppedRecording(false);
      setError(null);
      setIsPaused(false);
      // Reset audio configuration
      setSelectedDeviceId('default');
      setUseInternalAudio(false);
      setAnalyserNode(null);
      if (audioContext) {
        audioContext.close();
        setAudioContext(null);
      }
    }
  };

  // Add function to get available audio devices
  const getAudioDevices = useCallback(async () => {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const audioDevices = devices
        .filter(device => device.kind === 'audioinput')
        .map(device => ({
          deviceId: device.deviceId,
          label: device.label || `Microphone ${device.deviceId.slice(0, 5)}...`,
          kind: device.kind
        }));
      setAudioDevices(audioDevices);
    } catch (error) {
      console.error('Error getting audio devices:', error);
      message.error('Failed to get audio devices');
    }
  }, []);

  // Update setupAudioStream function
  const setupAudioStream = async (stream: MediaStream) => {
    try {
      const newAudioContext = new AudioContext();
      const source = newAudioContext.createMediaStreamSource(stream);
      const analyser = newAudioContext.createAnalyser();
      
      // Set default FFT size
      analyser.fftSize = 2048;
      source.connect(analyser);
      
      setAudioContext(newAudioContext);
      setAnalyserNode(analyser);
      
      return analyser;
    } catch (error) {
      console.error('Error setting up audio stream:', error);
      return null;
    }
  };

  // Add effect to get audio devices on mount
  useEffect(() => {
    getAudioDevices();
    navigator.mediaDevices.addEventListener('devicechange', getAudioDevices);
    
    return () => {
      navigator.mediaDevices.removeEventListener('devicechange', getAudioDevices);
      if (audioContext) {
        audioContext.close();
      }
    };
  }, [getAudioDevices]);

  // Add function to handle microphone testing
  const handleTestMicrophone = async () => {
    try {
      if (isTesting) {
        // Stop testing
        if (testStreamRef.current) {
          testStreamRef.current.getTracks().forEach(track => track.stop());
          testStreamRef.current = null;
        }
        setTestAnalyser(null);
        setIsTesting(false);
        return;
      }

      // Start testing
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: {
          deviceId: selectedDeviceId ? { exact: selectedDeviceId } : undefined,
          channelCount: 1,
          sampleRate: 48000,
          echoCancellation: true,
          noiseSuppression: true,
          autoGainControl: true
        }
      });

      testStreamRef.current = stream;
      const ctx = new AudioContext();
      const source = ctx.createMediaStreamSource(stream);
      const analyser = ctx.createAnalyser();
      analyser.fftSize = 2048;
      source.connect(analyser);
      
      setTestAnalyser(analyser);
      setIsTesting(true);
    } catch (error) {
      console.error('Error testing microphone:', error);
      message.error('Failed to test microphone');
    }
  };

  // Add cleanup effect for test stream
  useEffect(() => {
    return () => {
      if (testStreamRef.current) {
        testStreamRef.current.getTracks().forEach(track => track.stop());
      }
    };
  }, []);

  // Update renderAudioSettings to include the test feature
  const renderAudioSettings = () => (
    <Card size="small" title="Audio Settings">
      <Space direction="vertical" style={{ width: '100%' }}>
        <div>
          <Text strong>Microphone</Text>
          <Select
            style={{ width: '100%', marginTop: 8 }}
            value={selectedDeviceId}
            onChange={(value) => {
              setSelectedDeviceId(value);
              // Stop current test if running
              if (isTesting) {
                handleTestMicrophone();
              }
            }}
            placeholder="Select microphone"
          >
            {audioDevices.map(device => (
              <Select.Option key={device.deviceId} value={device.deviceId}>
                {device.label}
              </Select.Option>
            ))}
          </Select>
        </div>

        <div style={{ 
          border: '1px solid #f0f0f0',
          borderRadius: '8px',
          padding: '12px',
          background: '#fafafa'
        }}>
          <div style={{ 
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '12px'
          }}>
            <Text strong>Test Microphone</Text>
            <Button
              type={isTesting ? 'primary' : 'default'}
              onClick={handleTestMicrophone}
              icon={isTesting ? <AudioMutedOutlined /> : <AudioOutlined />}
              danger={isTesting}
            >
              {isTesting ? 'Stop Test' : 'Start Test'}
            </Button>
          </div>
          
          {isTesting && (
            <>
              <AudioVisualizer
                analyser={testAnalyser}
                height={60}
                color="#1890ff"
              />
              <Text type="secondary" style={{ 
                display: 'block',
                marginTop: '8px',
                fontSize: '12px',
                textAlign: 'center'
              }}>
                The line above should move when you speak. Try saying a few words to verify your microphone is working properly.
              </Text>
            </>
          )}
        </div>

        <div style={{ marginTop: 8 }}>
          <Space align="start">
            <Switch
              checked={useInternalAudio}
              onChange={(checked) => {
                setUseInternalAudio(checked);
                if (!checked) {
                  setSelectedDeviceId('default');
                }
              }}
              disabled={isRecording}
            />
            <div>
              <Text>
                Capture System Audio
                <Tooltip title={
                  <div>
                    <p>Enable this to record system audio (e.g., computer sounds, browser audio)</p>
                    <p><strong>Important:</strong> When prompted, you must select either:</p>
                    <ul style={{ paddingLeft: '20px', margin: '5px 0' }}>
                      <li>&quot;Share system audio&quot; for all system sounds</li>
                      <li>&quot;Share tab audio&quot; for browser tab sounds</li>
                    </ul>
                    <p>Browser Support:</p>
                    <ul style={{ paddingLeft: '20px', margin: '5px 0' }}>
                      <li>Chrome/Edge: Full system audio support</li>
                      <li>Firefox: Tab audio only</li>
                      <li>Safari: Not supported</li>
                    </ul>
                  </div>
                }>
                  <InfoCircleOutlined style={{ marginLeft: '8px', color: '#1890ff' }} />
                </Tooltip>
              </Text>
              {useInternalAudio && (
                <Text type="secondary" style={{ display: 'block', fontSize: '12px', marginTop: '4px' }}>
                  When prompted, make sure to check &quot;Share system audio&quot; or &quot;Share tab audio&quot;
                </Text>
              )}
            </div>
          </Space>
        </div>
      </Space>
    </Card>
  );

  // Add a function to render the transcript toolbar with speaker settings
  const renderTranscriptToolbar = () => (
    <div style={{ 
      marginBottom: '16px',
      display: 'flex',
      gap: '16px',
      alignItems: 'flex-start',
      flexWrap: 'wrap',
      justifyContent: 'space-between'
    }}>
      <div style={{ display: 'flex', gap: '16px', alignItems: 'center' }}>
        <Button
          type="default"
          icon={<UserSwitchOutlined />}
          onClick={() => setShowSpeakerSettings(!showSpeakerSettings)}
        >
          Manage Speaker Labels
        </Button>
        
        {modifiedSegments.length > 0 && (
          <Button
            type="primary"
            icon={<SaveOutlined />}
            onClick={() => {
              Modal.confirm({
                title: isClientLinked ? 'Update Transcript' : 'Update Current Session',
                content: isClientLinked 
                  ? 'Are you sure you want to update this transcript? This action cannot be undone.'
                  : 'This will update the transcript in your current session. The changes will not be saved permanently as no client is linked.',
                okText: 'Update',
                cancelText: 'Cancel',
                onOk: handleUpdateSegments
              });
            }}
            loading={isUpdating}
          >
            {isClientLinked 
              ? `Update Transcript (${modifiedSegments.length} changes)`
              : `Update Current Session (${modifiedSegments.length} changes)`}
          </Button>
        )}
      </div>
      
      <Modal
        title={
          <Space>
            <UserSwitchOutlined />
            <span>Manage Speaker Labels</span>
          </Space>
        }
        open={showSpeakerSettings}
        onCancel={() => setShowSpeakerSettings(false)}
        footer={null}
        width={500}
      >
        {renderSpeakerSettings()}
      </Modal>
    </div>
  );

  // Add segment operation handlers
  const handleCreateSegment = useCallback((position: number) => {
    const newSegment = createNewSegment(position, segments);
    const newSegments = [...segments];
    newSegments.splice(position, 0, newSegment);
    setSegments(newSegments);
    // Mark the new segment as modified
    setModifiedSegments(prev => [...prev, newSegment.id]);
  }, [segments]);

  const handleUpdateSegment = useCallback((segmentId: string, updates: Partial<TranscriptSegment>) => {
    const segment = segments.find(s => s.id === segmentId);
    if (!segment) return;

    const updatedFields: Partial<TranscriptSegment> = {
      ...updates
    };

    // Track modifications for:
    // 1. Text changes when saving edits
    // 2. Speaker changes
    // 3. Any other content changes
    if (
      ('isEditing' in updates && !updates.isEditing && segment.text !== segment.originalText) ||
      ('speaker' in updates && updates.speaker !== segment.speaker) ||
      ('text' in updates && updates.text !== segment.text)
    ) {
      setModifiedSegments(prev => 
        prev.includes(segmentId) ? prev : [...prev, segmentId]
      );
    }

    const updatedSegments = updateSegment(segment, updatedFields, segments);
    setSegments(updatedSegments);
  }, [segments]);

  const handleDeleteSegment = useCallback((segmentId: string) => {
    // Store the deleted segment ID instead of just 'deleted'
    setModifiedSegments(prev => [...prev.filter(id => id !== 'deleted'), `deleted-${segmentId}`]);
    const updatedSegments = deleteSegment(segmentId, segments);
    setSegments(updatedSegments);
  }, [segments]);

  const handleMergeSegments = useCallback((segmentIds: string[]) => {
    // Find the segments to merge
    const segmentsToMerge = segments.filter(s => segmentIds.includes(s.id));
    if (segmentsToMerge.length < 2) return;

    // Create merged segment
    const mergedText = segmentsToMerge.map(s => s.text).join(' ');
    const firstSegment = segmentsToMerge[0];
    
    // Remove old segments
    const remainingSegments = segments.filter(s => !segmentIds.includes(s.id));
    
    // Add merged segment
    const newSegment = {
      ...firstSegment,
      id: `merged-${Date.now()}`,
      text: mergedText,
    };
    
    const newSegments = [...remainingSegments];
    const insertIndex = segments.findIndex(s => s.id === firstSegment.id);
    newSegments.splice(insertIndex, 0, newSegment);
    
    setSegments(newSegments);
    // Mark the merged segment as modified
    setModifiedSegments(prev => [...prev, newSegment.id]);
  }, [segments]);

  const handleToggleEdit = useCallback((segmentId: string) => {
    const segment = segments.find(s => s.id === segmentId);
    if (!segment) return;

    handleUpdateSegment(segmentId, {
      isEditing: !segment.isEditing,
      originalText: segment.text
    });
  }, [segments, handleUpdateSegment]);

  // Update segments when doc content changes
  useEffect(() => {
    if (currentDoc?.content && !currentDoc.isGenerated) {
      const parsedSegments = parseTranscriptContent(currentDoc.content);
      setSegments(parsedSegments);
    }
  }, [currentDoc?.content, currentDoc?.isGenerated]);

  // Add function to render speaker settings
  const renderSpeakerSettings = () => (
    <Card size="small" title={
      <Space>
        <Text strong>Speaker Labels</Text>
        <Tooltip title="Configure custom speaker labels for your transcripts">
          <InfoCircleOutlined style={{ color: '#1890ff' }} />
        </Tooltip>
      </Space>
    }>
      <Space direction="vertical" style={{ width: '100%' }}>
        <List
          size="small"
          dataSource={speakerLabels}
          renderItem={(speaker) => (
            <List.Item
              actions={[
                <Tooltip title="Remove speaker label" key="delete">
                  <Button
                    type="text"
                    danger
                    icon={<DeleteOutlined />}
                    onClick={() => {
                      Modal.confirm({
                        title: 'Delete Speaker Label',
                        content: 'Are you sure you want to delete this speaker label?',
                        okText: 'Delete',
                        okType: 'danger',
                        cancelText: 'Cancel',
                        onOk() {
                          setSpeakerLabels(prev => prev.filter(s => s.id !== speaker.id));
                        }
                      });
                    }}
                  />
                </Tooltip>
              ]}
            >
              <List.Item.Meta
                avatar={
                  <div
                    style={{
                      width: 20,
                      height: 20,
                      borderRadius: '50%',
                      background: speaker.color,
                      marginRight: 8
                    }}
                  />
                }
                title={speaker.label}
                description={speaker.description}
              />
            </List.Item>
          )}
        />
        
        {isAddingSpeaker ? (
          <div style={{ 
            padding: '12px',
            background: '#f5f5f5',
            borderRadius: '4px'
          }}>
            <Space direction="vertical" style={{ width: '100%' }}>
              <Input
                placeholder="Speaker Label (e.g., Therapist, Client, Observer)"
                value={newSpeakerLabel}
                onChange={(e) => setNewSpeakerLabel(e.target.value)}
                maxLength={50}
              />
              <Input
                placeholder="Description (optional)"
                value={newSpeakerDescription}
                onChange={(e) => setNewSpeakerDescription(e.target.value)}
                maxLength={100}
              />
              <div>
                <Text type="secondary">Color:</Text>
                <Input
                  type="color"
                  value={newSpeakerColor}
                  onChange={(e) => setNewSpeakerColor(e.target.value)}
                  style={{ width: 100, marginLeft: 8 }}
                />
              </div>
              <Space>
                <Button
                  type="primary"
                  onClick={() => {
                    if (newSpeakerLabel.trim()) {
                      setSpeakerLabels(prev => [...prev, {
                        id: `speaker-${Date.now()}`,
                        label: newSpeakerLabel.trim(),
                        description: newSpeakerDescription.trim() || undefined,
                        color: newSpeakerColor
                      }]);
                      setNewSpeakerLabel('');
                      setNewSpeakerDescription('');
                      setNewSpeakerColor('#1890ff');
                      setIsAddingSpeaker(false);
                    }
                  }}
                >
                  Add Speaker
                </Button>
                <Button onClick={() => {
                  setIsAddingSpeaker(false);
                  setNewSpeakerLabel('');
                  setNewSpeakerDescription('');
                  setNewSpeakerColor('#1890ff');
                }}>
                  Cancel
                </Button>
              </Space>
            </Space>
          </div>
        ) : (
          <Button
            type="dashed"
            icon={<PlusOutlined />}
            onClick={() => setIsAddingSpeaker(true)}
            block
          >
            Add Speaker Label
          </Button>
        )}
      </Space>
    </Card>
  );

  // Add function to extract unique speakers from transcript
  const extractUniqueTranscriptSpeakers = useCallback(() => {
    const uniqueSpeakers = new Set<string>();
    segments.forEach(segment => {
      if (segment.valid && segment.speaker) {
        uniqueSpeakers.add(segment.speaker);
      }
    });
    return Array.from(uniqueSpeakers);
  }, [segments]);

  // Update the effect that initializes speaker labels
  useEffect(() => {
    if (currentDoc?.content && !currentDoc.isGenerated) {
      const uniqueSpeakers = extractUniqueTranscriptSpeakers();
      const defaultColors = ['#1890ff', '#52c41a', '#722ed1', '#fa8c16', '#eb2f96'];
      
      // Create a map of existing labels
      const existingLabelsMap = new Map(speakerLabels.map(label => [label.label.toLowerCase(), label]));
      
      // Only add new speakers that don't already have labels
      const newSpeakers = uniqueSpeakers.filter(speaker => 
        !existingLabelsMap.has(speaker.toLowerCase())
      );

      if (newSpeakers.length > 0) {
        setSpeakerLabels(prevLabels => [
          ...prevLabels,
          ...newSpeakers.map((speaker, index) => ({
            id: `speaker-${speaker.toLowerCase().replace(/\s+/g, '-')}-${Date.now()}`,
            label: speaker,
            color: defaultColors[(prevLabels.length + index) % defaultColors.length],
            description: `Auto-detected speaker from transcript`
          }))
        ]);
      }
    }
  }, [currentDoc?.content, currentDoc?.isGenerated, extractUniqueTranscriptSpeakers]);

  // Update the renderSpeakerSelect function to handle speaker matching better
  const renderSpeakerSelect = (segment: TranscriptSegment) => {
    if (!currentDoc || currentDoc.isGenerated) {
      // For new recordings or generated docs, use simple speaker selection
      return (
        <Select
          value={segment.speaker}
          onChange={(value) => handleUpdateSegment(segment.id, { speaker: value })}
          style={{ width: 120 }}
        >
          <Select.Option value="Speaker">Speaker</Select.Option>
          <Select.Option value="Client">Client</Select.Option>
          <Select.Option value="Therapist">Therapist</Select.Option>
        </Select>
      );
    }

    // Find the current speaker label, trying different matching strategies
    const currentSpeakerLabel = 
      speakerLabels.find(s => s.id === segment.speaker) || // Match by ID
      speakerLabels.find(s => s.label.toLowerCase() === segment.speaker.toLowerCase()) || // Match by label (case insensitive)
      speakerLabels.find(s => s.id === segment.speakerLabel?.id); // Match by saved speaker label ID
    
    return (
      <Select
        value={currentSpeakerLabel?.id || segment.speaker}
        onChange={(value) => {
          const selectedLabel = speakerLabels.find(s => s.id === value);
          handleUpdateSegment(segment.id, { 
            speaker: selectedLabel?.label || value,
            speakerLabel: selectedLabel
          });
        }}
        style={{ width: 160 }}
      >
        {speakerLabels.map(speaker => (
          <Select.Option key={speaker.id} value={speaker.id}>
            <Space>
              <div style={{
                width: 12,
                height: 12,
                borderRadius: '50%',
                background: speaker.color,
                display: 'inline-block',
                marginRight: 4
              }} />
              {speaker.label}
            </Space>
          </Select.Option>
        ))}
      </Select>
    );
  };

  // Update the renderSpeakerLabel function to match the same speaker finding logic
  const renderSpeakerLabel = (segment: TranscriptSegment) => {
    if (!currentDoc || currentDoc.isGenerated) {
      return (
        <span 
          className="segment-text"
          style={{ 
            color: generateSpeakerColor(segment.speaker),
            fontWeight: 600
          }}
        >
          {segment.speaker}:
        </span>
      );
    }

    // Use the same speaker finding logic as in renderSpeakerSelect
    const speakerLabel = 
      speakerLabels.find(s => s.id === segment.speaker) ||
      speakerLabels.find(s => s.label.toLowerCase() === segment.speaker.toLowerCase()) ||
      speakerLabels.find(s => s.id === segment.speakerLabel?.id);
    
    return (
      <span 
        className="segment-text"
        style={{ 
          color: speakerLabel?.color || generateSpeakerColor(segment.speaker),
          fontWeight: 600
        }}
      >
        {speakerLabel?.label || segment.speaker}:
      </span>
    );
  };

  // Add function to handle segment updates
  const handleUpdateSegments = async () => {
    if (!currentDoc?.id || !segments.length) return;

    try {
      setIsUpdating(true);
      const transcriptId = currentDoc.id.replace(/^(doc-|session-)/, '');

      // Map segments, excluding those marked as deleted
      const updatedSegments = segments
        .filter(segment => !modifiedSegments.some(id => id === `deleted-${segment.id}`))
        .map(segment => ({
          speaker: segment.speakerLabel?.label || segment.speaker,
          text: segment.text,
          isEdited: modifiedSegments.some(id => 
            id === segment.id || 
            id.startsWith('merged-') || 
            id.startsWith('segment-')
          )
        }));

      // Only make API call if client is linked
      if (isClientLinked && selectedClientId) {
        // Get the original transcript to ensure it exists
        const response = await ApiClient.get(
          `/api/v1/ai/transcriptions/${transcriptId}`,
          { params: { clientId } }
        );

        if (!response.data) {
          throw new Error('Transcript not found');
        }

        // Update the transcript with remaining segments
        await dispatch(updateTranscriptAction({
          transcriptId,
          clientId,
          segments: updatedSegments
        })).unwrap();
      }

      // For both linked and unlinked clients, update the UI
      // Format the updated content
      const formattedContent = updatedSegments.map((segment, index) => {
        const startTime = segments[index].startTime;
        const endTime = segments[index].endTime;
        
        const formatTimeMMSS = (timeInSeconds: number) => {
          const minutes = Math.floor(timeInSeconds / 60);
          const seconds = Math.floor(timeInSeconds % 60);
          return `${minutes}:${seconds.toString().padStart(2, '0')}`;
        };

        const startTimeStr = formatTimeMMSS(startTime);
        const endTimeStr = formatTimeMMSS(endTime);
        
        // Ensure confidence is between 0 and 100
        const confidenceFormatted = Math.min(100, Math.round(segments[index].confidence * (segments[index].confidence > 1 ? 1 : 100)));
        const sentimentFormatted = Math.round(Math.abs(segments[index].sentiment.score) * 100);
        const sentimentLabel = segments[index].sentiment.label.toUpperCase();
        
        return `[ID:${transcriptId}-${index}] [${segment.speaker}] [CONF ${confidenceFormatted}%] [${sentimentLabel} ${sentimentFormatted}%] [${startTimeStr} - ${endTimeStr}]: ${segment.text}`;
      }).join('\n\n');

      // Update the document in the list
      const updatedDoc = {
        ...currentDoc,
        content: formattedContent
      };

      const updatedDocs = generatedDocs.map(doc => 
        doc.id === currentDoc.id ? updatedDoc : doc
      );

      onGeneratedDocsChange?.(updatedDocs);
      setModifiedSegments([]);
      message.success(isClientLinked ? 'Transcript updated successfully' : 'Transcript updated in current session');
    } catch (error) {
      console.error('Error updating transcript:', error);
      message.error('Failed to update transcript');
    } finally {
      setIsUpdating(false);
    }
  };

  // Add a warning when there are unsaved changes and user tries to switch tabs
  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      if (modifiedSegments.length > 0) {
        e.preventDefault();
        e.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, [modifiedSegments.length]);

  // Start timer for processing duration
  const startProcessingTimer = useCallback(() => {
    if (processingTimerRef.current) {
      clearInterval(processingTimerRef.current);
    }
    setProcessingElapsedSeconds(0);
    processingTimerRef.current = setInterval(() => {
      setProcessingElapsedSeconds(prev => prev + 1);
    }, 1000);
  }, []);

  // Stop timer for processing duration
  const stopProcessingTimer = useCallback(() => {
    if (processingTimerRef.current) {
      clearInterval(processingTimerRef.current);
      processingTimerRef.current = null;
    }
  }, []);

  // Format time display (MM:SS)
  const formatProcessingTime = (seconds: number): string => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  // Update useEffect for processing recording to handle the timer
  useEffect(() => {
    if (isProcessingRecording && recordedAudio) {
      // Start the processing timer
      startProcessingTimer();
      
      // Add a timeout reference to cancel processing if it takes too long
      let processingTimeoutId: NodeJS.Timeout | null = null;
      
      const processRecording = async () => {
        try {
          setProcessingStep('processing');
          const formData = new FormData();
          formData.append('file', recordedAudio);
          formData.append('recordingDate', new Date().toISOString());

          if (isClientLinked && selectedClientId) {
            formData.append('clientId', selectedClientId);
          }
          if (clientName) {
            formData.append('clientName', clientName);
          }

          // Set a timeout to prevent indefinite waiting
          const PROCESSING_TIMEOUT = 180000; // 3 minutes in milliseconds
          
          // Create a promise that rejects after the timeout
          const timeoutPromise = new Promise<never>((_, reject) => {
            processingTimeoutId = setTimeout(() => {
              reject(new Error("Transcription processing timed out. The audio file might be too large or the server is busy."));
            }, PROCESSING_TIMEOUT);
          });
          
          // Race the processing against the timeout
          setProcessingStep('generating');
          
          const response = await Promise.race([
            dispatch(processAudioWithWhisperAction({
            formData,
            piiSettings: piiSettings?.enabled ? {
              enabled: true,
              redactTypes: piiSettings.redactTypes
            } : undefined
            })).unwrap(),
            timeoutPromise
          ]);
          
          // Clear the timeout if processing completed successfully
          if (processingTimeoutId) {
            clearTimeout(processingTimeoutId);
            processingTimeoutId = null;
          }
          
          if (response.status === 'error') {
            throw new Error(response.message || 'Failed to transcribe audio');
          }

          const averageConfidence = response.segments.reduce(
            (acc: number, seg: WhisperTranscriptionResponse['segments'][0]) => 
              acc + (seg.confidence || 0), 0
          ) / response.segments.length;

          // Initialize transcriptId variable
          let transcriptId: string;

          // Only upload to client if we have a linked client
          if (isClientLinked && selectedClientId) {
            // Upload the transcript to the client
            const uploadResponse = await dispatch(uploadTranscriptToClientAction({
              clientId: selectedClientId,
              transcript: {
                segments: response.segments.map((segment: WhisperTranscriptionResponse['segments'][0]) => ({
                  speaker: segment.speaker || 'Speaker',
                  text: segment.text,
                  startTime: segment.start,
                  sentiment: segment.sentiment
                })),
                rawText: response.text
              },
              metadata: {
                recordingDate: new Date().toISOString(),
                totalSegments: response.segments.length,
                averageConfidence,
                duration: response.segments[response.segments.length - 1]?.end || 0
              }
            })).unwrap();

            if (uploadResponse.status !== 'success') {
              throw new Error('Failed to upload transcript to client');
            }

            transcriptId = uploadResponse.id;
          } else {
            // For unlinked clients, generate a temporary ID
            transcriptId = `temp-${Date.now()}`;
          }

          // Format the transcript content
          const formattedContent = response.segments.map((segment: WhisperTranscriptionResponse['segments'][0], index: number) => {
            const startTime = segment.start;
            const endTime = segment.end || startTime + 2;
            
            const formatTimeMMSS = (timeInSeconds: number) => {
              const minutes = Math.floor(timeInSeconds / 60);
              const seconds = Math.floor(timeInSeconds % 60);
              return `${minutes}:${seconds.toString().padStart(2, '0')}`;
            };

            const startTimeStr = formatTimeMMSS(startTime);
            const endTimeStr = formatTimeMMSS(endTime);
            
            // Ensure confidence is between 0 and 100
            const confidenceFormatted = Math.min(100, Math.round(segment.confidence * (segment.confidence > 1 ? 1 : 100)));
            const sentimentFormatted = Math.round(Math.abs(segment.sentiment?.score || 0.5) * 100);
            const sentimentLabel = segment.sentiment?.label?.toUpperCase() || 'NEUTRAL';
            
            return `[ID:${transcriptId}-${index}] [${segment.speaker || 'Speaker'}] [CONF ${confidenceFormatted}%] [${sentimentLabel} ${sentimentFormatted}%] [${startTimeStr} - ${endTimeStr}]: ${segment.text}`;
          }).join('\n\n');

          // Create and add the new document
          const savedDoc: GeneratedDoc = {
            id: `doc-${transcriptId}`,
            sessionId: transcriptId,
            name: `Uploaded - ${format(new Date(), 'MMM dd, yyyy HH:mm')}`,
            content: formattedContent,
            isGenerated: false,
            duration: response.segments[response.segments.length - 1]?.end || 0
          };

          // Add the document to the list
          const updatedDocs = [...generatedDocs, savedDoc];
          onGeneratedDocsChange?.(updatedDocs);
          
          // Switch to the new document's tab
          setActiveTab(`doc-${savedDoc.id}`);

          // Reset all the states
          setIsProcessingRecording(false);
          setProcessingStep('idle');
          setHasStoppedRecording(false);
          setRecordedAudio(null);
          audioChunksRef.current = [];
          stopProcessingTimer();

          // This is just to show success in the UI immediately
          message.success(isClientLinked ? 'File processed and saved successfully' : 'File processed successfully');
        } catch (error) {
          console.error('Error processing recording:', error);
          const errorMessage = error instanceof Error ? error.message : 'Failed to process recording';
          setUploadError(errorMessage);
          setFailedUploadAudio(recordedAudio);  // Store the failed audio for retry
          setIsProcessingRecording(false);
          setProcessingStep('idle');
          
          // Stop the processing timer
          stopProcessingTimer();
          
          // Clear the timeout if it exists
          if (processingTimeoutId) {
            clearTimeout(processingTimeoutId);
            processingTimeoutId = null;
          }
          
          return; // Exit early on error
        }

        // Reset error states on success
        setUploadError(null);
        setFailedUploadAudio(null);
      };

      processRecording();
      
      // Cleanup function
      return () => {
        // Stop the processing timer
        stopProcessingTimer();
        
        // Clear any timeout if the component unmounts during processing
        if (processingTimeoutId) {
          clearTimeout(processingTimeoutId);
        }
      };
    }
  }, [isProcessingRecording, recordedAudio, setActiveTab, generatedDocs, onGeneratedDocsChange, isClientLinked, selectedClientId, clientName, piiSettings, dispatch, startProcessingTimer, stopProcessingTimer]);

  const triggerFileUpload = () => {
    if (audioInputRef.current) {
      audioInputRef.current.click();
    }
  };

  // Add function to handle audio file upload
  const handleAudioFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    try {
      setUploadError(null);
      setUploadingAudio(true);
      
      // Check if format is supported
      const supportedFormats = ['audio/mpeg', 'audio/mp3', 'audio/mp4', 'audio/m4a', 'audio/x-m4a', 'audio/wav', 'audio/webm'];
      if (!isAudioFormatSupported(file.type)) {
        const supportedFormatsText = supportedFormats
          .map(format => format.replace('audio/', '').toUpperCase())
          .join(', ');
        
        throw new Error(`Unsupported audio format: ${file.type}. Please use one of the following formats: ${supportedFormatsText}`);
      }
      
      // Check file size - limit to 50MB to prevent potential timeouts
      const MAX_FILE_SIZE = 50 * 1024 * 1024; // 50MB in bytes
      if (file.size > MAX_FILE_SIZE) {
        throw new Error(`File size exceeds 50MB limit. Please use a smaller file or compress your audio.`);
      }
      
      // Display info message with file size
      message.info(`Processing audio file: ${file.name} (${(file.size / (1024 * 1024)).toFixed(1)}MB)`);
      
      // Set UI states
      setIsProcessingRecording(true);
      setProcessingStep('processing');
      setHasStoppedRecording(true);
      startProcessingTimer();
      
      // Read file as array buffer
      const arrayBuffer = await file.arrayBuffer();
      
      // Determine correct MIME type - fix for 'audio/x-m4a' to 'audio/m4a'
      let mimeType = file.type;
      if (mimeType === 'audio/x-m4a') {
        mimeType = 'audio/m4a';
      }
      
      // Create a blob with the correct MIME type
      const audioBlob = new Blob([arrayBuffer], { 
        type: mimeType  // Use the corrected MIME type
      });
      
      // Create a FormData object manually instead of relying on the onProcessRecording handler
      const formData = new FormData();
      
      // Add the file with the exact same key that's used during recording
      formData.append('file', audioBlob, file.name);
      formData.append('recordingDate', new Date().toISOString());
      
      // Add client information if available
      if (isClientLinked && selectedClientId) {
        formData.append('clientId', selectedClientId);
      }
      if (clientName) {
        formData.append('clientName', clientName);
      }
      
      // Set timeout to prevent indefinite waiting
      const PROCESSING_TIMEOUT = 180000; // 3 minutes
      const controller = new AbortController();
      const timeoutId = setTimeout(() => {
        controller.abort();
      }, PROCESSING_TIMEOUT);
      
      try {
        // Process the audio directly like in the useEffect
        setProcessingStep('generating');
        
        const response = await dispatch(processAudioWithWhisperAction({
          formData,
          piiSettings: piiSettings?.enabled ? {
            enabled: true,
            redactTypes: piiSettings.redactTypes
          } : undefined
        })).unwrap();
        
        // Clear the timeout
        clearTimeout(timeoutId);
        
        if (response.status === 'error') {
          throw new Error(response.message || 'Failed to transcribe audio');
        }
        
        // Process successful response...
        // Process the successful response
        const averageConfidence = response.segments.reduce(
          (acc: number, seg: WhisperTranscriptionResponse['segments'][0]) => 
            acc + (seg.confidence || 0), 0
        ) / response.segments.length;

        // Initialize transcriptId variable
        let transcriptId: string;

        // Only upload to client if we have a linked client
        if (isClientLinked && selectedClientId) {
          // Upload the transcript to the client
          const uploadResponse = await dispatch(uploadTranscriptToClientAction({
            clientId: selectedClientId,
            transcript: {
              segments: response.segments.map((segment: WhisperTranscriptionResponse['segments'][0]) => ({
                speaker: segment.speaker || 'Speaker',
                text: segment.text,
                startTime: segment.start,
                sentiment: segment.sentiment
              })),
              rawText: response.text
            },
            metadata: {
              recordingDate: new Date().toISOString(),
              totalSegments: response.segments.length,
              averageConfidence,
              duration: response.segments[response.segments.length - 1]?.end || 0
            }
          })).unwrap();

          if (uploadResponse.status !== 'success') {
            throw new Error('Failed to upload transcript to client');
          }

          transcriptId = uploadResponse.id;
        } else {
          // For unlinked clients, generate a temporary ID
          transcriptId = `temp-${Date.now()}`;
        }

        // Format the transcript content
        const formattedContent = response.segments.map((segment: WhisperTranscriptionResponse['segments'][0], index: number) => {
          const startTime = segment.start;
          const endTime = segment.end || startTime + 2;
          
          const formatTimeMMSS = (timeInSeconds: number) => {
            const minutes = Math.floor(timeInSeconds / 60);
            const seconds = Math.floor(timeInSeconds % 60);
            return `${minutes}:${seconds.toString().padStart(2, '0')}`;
          };

          const startTimeStr = formatTimeMMSS(startTime);
          const endTimeStr = formatTimeMMSS(endTime);
          
          // Ensure confidence is between 0 and 100
          const confidenceFormatted = Math.min(100, Math.round(segment.confidence * (segment.confidence > 1 ? 1 : 100)));
          const sentimentFormatted = Math.round(Math.abs(segment.sentiment?.score || 0.5) * 100);
          const sentimentLabel = segment.sentiment?.label?.toUpperCase() || 'NEUTRAL';
          
          return `[ID:${transcriptId}-${index}] [${segment.speaker || 'Speaker'}] [CONF ${confidenceFormatted}%] [${sentimentLabel} ${sentimentFormatted}%] [${startTimeStr} - ${endTimeStr}]: ${segment.text}`;
        }).join('\n\n');

        // Create and add the new document
        const savedDoc: GeneratedDoc = {
          id: `doc-${transcriptId}`,
          sessionId: transcriptId,
          name: `Uploaded - ${file.name} - ${format(new Date(), 'MMM dd, yyyy HH:mm')}`,
          content: formattedContent,
          isGenerated: false,
          duration: response.segments[response.segments.length - 1]?.end || 0
        };

        // Add the document to the list
        const updatedDocs = [...generatedDocs, savedDoc];
        onGeneratedDocsChange?.(updatedDocs);
        
        // Switch to the new document's tab
        setActiveTab(`doc-${savedDoc.id}`);

        // This is just to show success in the UI immediately
        message.success(isClientLinked ? 'File processed and saved successfully' : 'File processed successfully');
        
      } catch (error) {
        clearTimeout(timeoutId);
        throw error; // Re-throw to be handled in the outer catch
      }
      
    } catch (error) {
      console.error('Error uploading audio file:', error);
      const errorMessage = error instanceof Error ? error.message : 'Failed to upload audio file';
      message.error(errorMessage);
      setIsProcessingRecording(false);
      setProcessingStep('idle');
      setHasStoppedRecording(false);
      stopProcessingTimer();
      
      // Store failed audio for retry if it's past the beginning stages
      if (processingStep === 'generating') {
        setUploadError(errorMessage);
        setFailedUploadAudio(file);
      }
    } finally {
      setUploadingAudio(false);
      // Reset the input so the same file can be selected again
      if (audioInputRef.current) {
        audioInputRef.current.value = '';
      }
    }
  };

  // Define supported audio formats
  const SUPPORTED_AUDIO_FORMATS = [
    'audio/mpeg', 
    'audio/mp3', 
    'audio/mp4', 
    'audio/m4a',
    'audio/x-m4a', // Add x-m4a explicitly 
    'audio/wav', 
    'audio/webm'
  ];

  // Helper function to check if file type is supported
  const isAudioFormatSupported = (mimeType: string): boolean => {
    // Some browsers may return types like 'audio/x-m4a' instead of 'audio/m4a'
    // Normalize these cases
    const normalizedType = mimeType.replace('x-m4a', 'm4a')
                                   .replace('x-mp3', 'mp3')
                                   .replace('x-mpeg', 'mpeg');
    
    return SUPPORTED_AUDIO_FORMATS.includes(normalizedType) || SUPPORTED_AUDIO_FORMATS.includes(mimeType);
  };

  // Update the renderStoppedRecordingState to include elapsed time
  const renderStoppedRecordingState = () => (
    <>
      {isProcessingRecording ? (
        <>
          <LoadingOutlined style={{ 
            fontSize: isMobile ? '40px' : '48px', 
            color: '#1890ff' 
          }} />
          <Title level={3} style={{
            fontSize: isMobile ? '20px' : '24px',
            textAlign: 'center',
            margin: 0,
            marginBottom: '8px'
          }}>Converting Your Session</Title>
          <Text type="secondary" style={{ marginBottom: '16px' }}>
            Processing time: {formatProcessingTime(processingElapsedSeconds)}
          </Text>
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            gap: isMobile ? '12px' : '16px',
            width: '100%',
            maxWidth: isMobile ? '100%' : '400px',
            padding: isMobile ? '0 16px' : '0'
          }}>
            <Steps
              direction="vertical"
              size="small"
              items={[
                {
                  title: 'Processing Audio',
                  description: processingStep === 'processing' ? 'Analyzing your audio file...' : 'Audio processing complete',
                  status: processingStep === 'processing' ? 'process' : 
                          processingStep === 'generating' ? 'finish' : 'wait',
                  icon: processingStep === 'processing' ? <LoadingOutlined /> : 
                        processingStep === 'generating' ? <CheckCircleOutlined /> : 
                        <SoundOutlined />
                },
                {
                  title: 'Generating Transcript',
                  description: processingStep === 'generating' ? `Converting speech to text with AI (${formatProcessingTime(processingElapsedSeconds)})...` : 'Waiting to start',
                  status: processingStep === 'generating' ? 'process' : 'wait',
                  icon: processingStep === 'generating' ? <LoadingOutlined /> : <FileTextOutlined />
                }
              ]}
            />
            {processingElapsedSeconds > 120 && (
              <Alert
                message="Processing is taking longer than usual"
                description="Larger files may take several minutes to process. Please be patient."
                type="info"
                showIcon
              />
            )}
          </div>
        </>
      ) : uploadError ? (
        <>
          <WarningOutlined style={{ 
            fontSize: isMobile ? '40px' : '48px', 
            color: '#ff4d4f' 
          }} />
          <Title level={3} style={{
            fontSize: isMobile ? '20px' : '24px',
            textAlign: 'center',
            margin: 0,
            color: '#ff4d4f'
          }}>Transcription Failed</Title>
          <Alert
            message="Failed to Process Session"
            description={uploadError}
            type="error"
            showIcon
            style={{ 
              width: '100%',
              maxWidth: '400px',
              marginBottom: '16px'
            }}
          />
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '12px',
            width: '100%',
            maxWidth: '400px'
          }}>
            <Button
              type="primary"
              icon={<ReloadOutlined />}
              onClick={async () => {
                if (failedUploadAudio) {
                  setUploadError(null);
                  setIsProcessingRecording(true);
                  setProcessingStep('processing');
                  try {
                    // Start the processing timer again
                    startProcessingTimer();
                    await onProcessRecording(failedUploadAudio);
                  } catch (error) {
                    const errorMessage = error instanceof Error ? error.message : 'Unknown error';
                    setUploadError(errorMessage);
                  }
                }
              }}
              size={isMobile ? 'large' : 'middle'}
              style={{
                height: isMobile ? '44px' : '40px'
              }}
              block
            >
              Retry Transcription
            </Button>
            <Button
              type="default"
              icon={<DeleteOutlined />}
              onClick={() => {
                setUploadError(null);
                setFailedUploadAudio(null);
                setHasStoppedRecording(false);
                setRecordedAudio(null);
                setIsProcessingRecording(false);
                setProcessingStep('idle');
                audioChunksRef.current = [];
              }}
              size={isMobile ? 'large' : 'middle'}
              style={{
                height: isMobile ? '44px' : '40px'
              }}
              block
            >
              Clear & Start Over
            </Button>
          </div>
        </>
      ) : (
        <>
          <CheckCircleOutlined style={{ 
            fontSize: isMobile ? '40px' : '48px', 
            color: '#52c41a' 
          }} />
          <Title level={3} style={{
            fontSize: isMobile ? '20px' : '24px',
            textAlign: 'center',
            margin: 0
          }}>Session Complete</Title>
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '12px'
          }}>
            <Button
              type="default"
              icon={<DeleteOutlined />}
              onClick={() => {
                Modal.confirm({
                  title: 'Clear Session',
                  content: 'Are you sure you want to clear the current session? This action cannot be undone.',
                  okText: 'Clear',
                  okType: 'danger',
                  cancelText: 'Cancel',
                  onOk() {
                    onTranscriptStateChange(false);
                    setError(null);
                    setHasStoppedRecording(false);
                    setRecordedAudio(null);
                    setIsProcessingRecording(false);
                    setProcessingStep('idle');
                    audioChunksRef.current = [];
                  }
                });
              }}
              size={isMobile ? 'large' : 'middle'}
              style={{
                height: isMobile ? '44px' : '40px'
              }}
              block
            >
              Clear
            </Button>
            <Button
              type="primary"
              icon={<CaretRightOutlined />}
              onClick={handleResumeSession}
              size={isMobile ? 'large' : 'middle'}
              style={{
                background: '#52c41a',
                borderColor: '#52c41a',
                height: isMobile ? '44px' : '40px'
              }}
              block
            >
              Start New Session
            </Button>
          </div>
        </>
      )}
    </>
  );

  return (
    <div className="LiveTranscribeTab" style={{ 
      width: '100%', 
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: '8px',
      position: 'relative',
      overflow: 'hidden'
    }}>
      {error && (
        <Alert
          message="Error"
          description={error}
          type="error"
          closable
          onClose={() => setError(null)}
          style={{ 
            borderRadius: 12,
            border: 'none',
            boxShadow: '0 2px 8px rgba(255, 0, 0, 0.1)',
            flexShrink: 0
          }}
        />
      )}

      <div style={{ flex: 1, display: 'flex', flexDirection: 'column', minHeight: 0, overflow: 'hidden' }}>
        <Tabs 
          activeKey={activeTab} 
          onChange={handleTabChange}
          className="live-transcribe-tabs"
          style={{ flex: 1, display: 'flex', flexDirection: 'column', height: '100%' }}
          items={[
            {
              key: 'transcript',
              label: <><AudioOutlined /> Start a Session</>,
              children: (
                <div style={{ 
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  background: '#fff',
                  borderRadius: '12px',
                  border: '1px solid rgba(0, 0, 0, 0.06)',
                  boxShadow: '0 2px 8px rgba(0, 0, 0, 0.04)',
                  overflow: 'hidden',
                  height: '100%'
                }}>
                  <div style={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: isMobile ? '16px' : '24px',
                    gap: '16px',
                    maxWidth: '100%',
                    margin: '0 auto',
                    width: '100%'
                  }}>
                    {isRecording ? (
                      <>
                        <div style={{
                          width: isMobile ? '100px' : '120px',
                          height: isMobile ? '100px' : '120px',
                          borderRadius: '50%',
                          background: isPaused ? '#faad14' : '#52c41a',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          animation: isPaused ? 'none' : 'pulse 2s infinite',
                          transition: 'all 0.3s ease',
                          flexShrink: 0
                        }}>
                          {isPaused ? (
                            <PauseOutlined style={{ fontSize: isMobile ? '40px' : '48px', color: '#fff' }} />
                          ) : (
                            <AudioOutlined style={{ fontSize: isMobile ? '40px' : '48px', color: '#fff' }} />
                          )}
                        </div>
                        <Title level={3} style={{
                          color: isPaused ? '#faad14' : '#52c41a',
                          transition: 'color 0.3s ease',
                          margin: 0,
                          fontSize: isMobile ? '20px' : '24px',
                          textAlign: 'center'
                        }}>
                          {isPaused ? 'Session Paused' : 'Session in Progress...'}
                        </Title>
                        <div style={{ 
                          display: 'flex', 
                          flexDirection: 'column', 
                          alignItems: 'center', 
                          gap: '8px',
                          width: '100%',
                          maxWidth: isMobile ? '100%' : '600px',
                          padding: isMobile ? '0 16px' : '0'
                        }}>
                          <Text style={{ 
                            fontSize: isMobile ? '20px' : '24px', 
                            fontFamily: 'monospace',
                            color: isPaused ? '#faad14' : '#52c41a'
                          }}>
                            {formatTime(recordingDuration)}
                          </Text>
                          <AudioVisualizer 
                            analyser={analyserNode} 
                            color={isPaused ? '#faad14' : '#52c41a'}
                            height={isMobile ? 60 : 80}
                          />
                          <Text type="secondary" style={{ 
                            textAlign: 'center',
                            fontSize: isMobile ? '14px' : '16px'
                          }}>
                            {isPaused 
                              ? "Session is paused. Click resume when you're ready to continue" 
                              : 'Actively listening to your session...'}
                          </Text>
                        </div>
                        {isPaused && (
                          <Alert
                            message="Session Paused"
                            description="Your session is currently paused. No audio is being recorded."
                            type="warning"
                            showIcon
                            style={{
                              maxWidth: isMobile ? '100%' : '400px',
                              width: '100%',
                              margin: isMobile ? '8px 16px' : '16px 0'
                            }}
                          />
                        )}
                        <div style={{
                          display: 'flex',
                          flexDirection: isMobile ? 'column' : 'row',
                          gap: isMobile ? '12px' : '16px',
                          width: '100%',
                          maxWidth: isMobile ? '100%' : '400px',
                          padding: isMobile ? '0 16px' : '0'
                        }}>
                          <Button
                            type="primary"
                            style={{
                              background: isPaused ? '#52c41a' : '#faad14',
                              borderColor: isPaused ? '#52c41a' : '#faad14',
                              boxShadow: '0 2px 0 rgba(0,0,0,0.045)',
                              flex: isMobile ? 'unset' : 1,
                              height: isMobile ? '44px' : '40px'
                            }}
                            icon={isPaused ? <CaretRightOutlined /> : <PauseOutlined />}
                            onClick={() => handlePauseRecording(!isPaused)}
                            size={isMobile ? 'large' : 'middle'}
                            className={isPaused ? 'resume-button' : 'pause-button'}
                            block={isMobile}
                          >
                            {isPaused ? 'Resume Recording' : 'Pause Recording'}
                          </Button>
                          <Button
                            type="primary"
                            danger
                            icon={<StopOutlined />}
                            onClick={handleStopRecording}
                            size={isMobile ? 'large' : 'middle'}
                            style={{
                              flex: isMobile ? 'unset' : 1,
                              height: isMobile ? '44px' : '40px'
                            }}
                            block={isMobile}
                          >
                            End Session
                          </Button>
                        </div>
                      </>
                    ) : hasStoppedRecording ? (
                      renderStoppedRecordingState()
                    ) : (
                      <>
                        <AudioOutlined style={{ fontSize: '48px', opacity: 0.45 }} />
                        <Text type="secondary" style={{ 
                          fontSize: '16px',
                          textAlign: 'center',
                          padding: '0 16px'
                        }}>
                          {!clientName ? (
                            'Please select or enter a client name to begin'
                          ) : (
                            'Configure your audio settings and click Start Listening to begin'
                          )}
                        </Text>
                        {clientName && (
                          <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '16px',
                            width: '100%',
                            maxWidth: '400px',
                            padding: '16px'
                          }}>
                            {renderAudioSettings()}
                            <Button
                              type="primary"
                              icon={<AudioOutlined />}
                              onClick={handleStartRecording}
                              size="large"
                              block
                            >
                              Start Listening
                            </Button>
                            <div style={{ 
                              display: 'flex', 
                              alignItems: 'center',
                              justifyContent: 'center',
                              margin: '8px 0'
                            }}>
                              <span style={{ color: '#8c8c8c' }}>or</span>
                            </div>
                            <Tooltip title="Upload pre-recorded audio file for transcription (MP3, MP4, M4A, WAV, or WEBM format, max 50MB)">
                              <Button
                                icon={<CloudUploadOutlined />}
                                onClick={triggerFileUpload}
                                size="large"
                                block
                                loading={uploadingAudio}
                                disabled={!clientName || isRecording || isProcessingRecording}
                              >
                                Upload Audio File
                              </Button>
                            </Tooltip>
                            <input
                              type="file"
                              ref={audioInputRef}
                              onChange={handleAudioFileUpload}
                              accept=".mp3,.mp4,.m4a,.wav,.webm,audio/mpeg,audio/mp3,audio/mp4,audio/m4a,audio/wav,audio/webm"
                              style={{ display: 'none' }}
                            />
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
              )
            },
            // Only show Choose a Template tab when there's a client name AND no active template
            ...(clientName && !selectedTemplate ? [{
              key: 'choose-template',
              label: (
                <span style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                  <FontAwesomeIcon icon={faHatWizard} /> Choose a Template
                </span>
              ),
              children: null
            }] : []),
            ...(selectedTemplate ? [{
              key: 'template',
              label: (
                <span style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                  <FileTextOutlined /> {selectedTemplate.name}
                  <Button
                    type="text"
                    size="small"
                    icon={<CloseOutlined />}
                    onClick={(e) => {
                      e.stopPropagation();
                      onTemplateClose?.();
                    }}
                  />
                </span>
              ),
              children: renderTemplateContent()
            }] : []),
            ...generatedDocs.map(doc => ({
              key: `doc-${doc.id}`,
              label: (
                <span 
                  className={doc.isGenerated ? 'magical-tab-label' : ''}
                  style={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    gap: '8px',
                    padding: '4px 8px',
                    borderRadius: '4px',
                    position: 'relative'
                  }}
                >
                  <FileTextOutlined /> 
                  <span className="tab-text">{doc.name}</span>
                  <Button
                    type="text"
                    size="small"
                    icon={<CloseOutlined />}
                    onClick={(e) => handleCloseDocument(doc.id, e)}
                    className="close-btn"
                    disabled={isGeneratingDocument && doc.content === ''}
                    style={{
                      cursor: isGeneratingDocument && doc.content === '' ? 'not-allowed' : 'pointer',
                      opacity: isGeneratingDocument && doc.content === '' ? 0.5 : 1
                    }}
                  />
                </span>
              ),
              children: (
                <div style={{ 
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  background: '#fff',
                  borderRadius: '12px',
                  border: '1px solid rgba(0, 0, 0, 0.06)',
                  boxShadow: '0 2px 8px rgba(0, 0, 0, 0.04)',
                  overflow: 'hidden',
                  padding: '16px',
                  minHeight: 0
                }}>
                  {!doc.isGenerated && renderTranscriptToolbar()}
                  {isGeneratingDocument && doc.content === '' ? (
                    <div style={{ 
                      flex: 1,
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      gap: '16px'
                    }}>
                      <Spin size="large" />
                      <Text>Generating document...</Text>
                      <Text type="secondary" style={{ fontSize: '14px' }}>Please wait while your document is being generated</Text>
                    </div>
                  ) : (
                    <div style={{ 
                      display: 'flex', 
                      flexDirection: 'column', 
                      gap: '16px', 
                      height: '100%',
                      overflow: 'hidden'
                    }}>
                      {doc.isGenerated ? (
                        <div style={{ 
                          display: 'flex', 
                          flexDirection: 'column',
                          height: '100%',
                          overflow: 'hidden'
                        }}>
                          <div style={{ 
                            display: 'flex', 
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            borderBottom: '1px solid rgba(0, 0, 0, 0.06)',
                            paddingBottom: '12px',
                            flexShrink: 0
                          }}>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                              <Text strong>{doc.name}</Text>
                              <Tag color="blue">
                                {doc.content.split(/\s+/).length} words
                              </Tag>
                              <Tag color="blue">
                                {Math.ceil(doc.content.split(/\s+/).length / 200)} min read
                              </Tag>
                            </div>
                            <Space>
                              <Button
                                icon={<CopyOutlined />}
                                onClick={() => {
                                  navigator.clipboard.writeText(doc.content);
                                  message.success('Document copied to clipboard');
                                }}
                              >
                                Copy
                              </Button>
                              <Button
                                type="primary"
                                icon={<FilePdfOutlined />}
                                onClick={() => handleExportDocument(doc)}
                              >
                                Export as PDF
                              </Button>
                            </Space>
                          </div>
                          <div style={{
                            flex: 1,
                            overflowY: 'auto',
                            padding: '16px 0'
                          }}>
                            <Input.TextArea
                              value={doc.content}
                              readOnly
                              style={{ 
                                resize: 'none',
                                border: 'none',
                                padding: '0',
                                fontSize: '16px',
                                lineHeight: '1.6',
                                height: '100%'
                              }}
                              autoSize={{ minRows: 1 }}
                            />
                          </div>
                        </div>
                      ) : (
                        <div style={{ 
                          flex: 1,
                          overflowY: 'auto',
                          background: '#ffffff',
                          height: '100%',
                          minHeight: 0,
                          padding: '8px'
                        }}>
                          {segments.map((segment: TranscriptSegment, index: number) => (
                            <div key={segment.id} className="transcript-segment">
                              {segment.valid ? (
                                <div className="segment-container">
                                  <div className="segment-content">
                                    {segment.isEditing ? (
                                      <div className="segment-edit-form">
                                        <div className="edit-form-header">
                                          {renderSpeakerSelect(segment)}
                                          <Space>
                                            <Button
                                              type="primary"
                                              size="small"
                                              icon={<SaveOutlined />}
                                              onClick={() => handleToggleEdit(segment.id)}
                                            >
                                              Save
                                            </Button>
                                            <Button
                                              size="small"
                                              icon={<CloseOutlined />}
                                              onClick={() => {
                                                handleUpdateSegment(segment.id, {
                                                  text: segment.originalText,
                                                  isEditing: false
                                                });
                                              }}
                                            >
                                              Cancel
                                            </Button>
                                          </Space>
                                        </div>
                                        <Input.TextArea
                                          value={segment.text}
                                          onChange={(e) => handleUpdateSegment(segment.id, { text: e.target.value })}
                                          autoSize={{ minRows: 3 }}
                                          style={{ 
                                            width: '100%',
                                            marginTop: '8px',
                                            fontSize: '16px',
                                            lineHeight: '1.6',
                                            padding: '12px'
                                          }}
                                          placeholder="Enter segment text..."
                                        />
                                        {(segment.confidence === 0 || segment.sentiment.score === 0) && (
                                          <Alert
                                            message="This segment has been edited"
                                            description="Confidence and sentiment scores have been cleared as the content was manually modified."
                                            type="info"
                                            showIcon
                                            style={{ marginTop: '8px' }}
                                          />
                                        )}
                                      </div>
                                    ) : (
                                      <>
                                        {renderSpeakerLabel(segment)}
                                        <span className="segment-text">{segment.text}</span>
                                        <div className="segment-pills">
                                          {!segment.isEditing && segment.confidence > 0 && (
                                            <>
                                              <Tag className="confidence-pill">
                                                {segment.confidence}%
                                              </Tag>
                                              <Tag 
                                                color={
                                                  segment.sentiment.label === 'positive' ? 'green' :
                                                  segment.sentiment.label === 'negative' ? 'red' : 'gold'
                                                }
                                                className="sentiment-pill"
                                              >
                                                {segment.sentiment.label.toUpperCase()}
                                              </Tag>
                                            </>
                                          )}
                                          {segment.isEditing && (
                                            <Tag className="time-pill" color="blue">
                                              Editing...
                                            </Tag>
                                          )}
                                          <Space className="segment-actions">
                                            <Button
                                              type="text"
                                              size="small"
                                              icon={<EditOutlined />}
                                              onClick={() => {
                                                handleUpdateSegment(segment.id, {
                                                  isEditing: true,
                                                  originalText: segment.text
                                                });
                                              }}
                                            />
                                            <Button
                                              type="text"
                                              size="small"
                                              icon={<DeleteOutlined />}
                                              danger
                                              onClick={() => {
                                                Modal.confirm({
                                                  title: 'Delete Segment',
                                                  content: 'Are you sure you want to delete this segment?',
                                                  okText: 'Delete',
                                                  okType: 'danger',
                                                  cancelText: 'Cancel',
                                                  onOk() {
                                                    handleDeleteSegment(segment.id);
                                                  }
                                                });
                                              }}
                                            />
                                          </Space>
                                        </div>
                                      </>
                                    )}
                                  </div>
                                  <Button
                                    type="dashed"
                                    size="small"
                                    icon={<PlusOutlined />}
                                    className="add-segment-button"
                                    onClick={() => handleCreateSegment(index + 1)}
                                  >
                                    Add Segment
                                  </Button>
                                </div>
                              ) : segment.isSystem ? (
                                <div className="system-message">
                                  <Text type="secondary" italic>
                                    {segment.text}
                                  </Text>
                                </div>
                              ) : (
                                <div className="raw-line">
                                  {segment.text}
                                </div>
                              )}
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )
            }))
          ]}
        />
      </div>

      {renderTemplateModal()}

      <style>
        {`
          /* Make tabs container fill available space */
          .live-transcribe-tabs {
            height: 100%;
            display: flex;
            flex-direction: column;
            min-height: 0;
          }

          .live-transcribe-tabs .ant-tabs-content-holder {
            flex: 1;
            overflow: hidden;
            min-height: 0;
          }

          .live-transcribe-tabs .ant-tabs-content {
            height: 100%;
            min-height: 0;
          }

          .live-transcribe-tabs .ant-tabs-tabpane {
            height: 100%;
            min-height: 0;
          }

          .live-transcribe-tabs .ant-tabs-tabpane-active {
            display: flex;
            flex-direction: column;
            min-height: 0;
            height: 100%;
          }

          /* Add styles for transcript container */
          .transcript-container {
            flex: 1;
            overflow-y: auto;
            min-height: 0;
            padding: 16px;
            height: 100%;
          }

          .transcript-segment {
            margin-bottom: 16px;
          }

          @keyframes pulse {
            0% {
              box-shadow: 0 0 0 0 rgba(82, 196, 26, 0.4);
            }
            70% {
              box-shadow: 0 0 0 20px rgba(82, 196, 26, 0);
            }
            100% {
              box-shadow: 0 0 0 0 rgba(82, 196, 26, 0);
            }
          }

          /* Base tab styles */
          .live-transcribe-tabs .ant-tabs-nav {
            margin-bottom: 16px;
          }

          /* Remove default Ant Design tab styles */
          .live-transcribe-tabs .ant-tabs-tab {
            background: white !important;
            border: 1px solid #d9d9d9 !important;
            border-radius: 4px !important;
            margin: 0 8px 0 0 !important;
            padding: 6px 16px !important;
            transition: all 0.3s;
            height: 40px;
            display: flex;
            align-items: center;
          }

          /* Specific style for template tab */
          .live-transcribe-tabs .ant-tabs-tab[data-node-key="choose-template"] {
            border-style: dashed !important;
          }

          /* Override specific tab styles for generated docs */
          .live-transcribe-tabs .ant-tabs-tab:has(.magical-tab-label) {
            background: white !important;
            border: none !important;
            position: relative;
            margin: 0 8px 0 0 !important;
            padding: 0 !important;
            height: 40px;
            display: flex;
            align-items: center;
            transition: all 0.3s;
          }

          /* Magical tab label styles */
          .magical-tab-label {
            background: white !important;
            position: relative;
            z-index: 1;
            transition: all 0.3s ease;
            padding: 6px 16px !important;
            height: 100%;
            width: 100%;
            display: flex;
            align-items: center;
          }

          /* Gradient border using pseudo-element */
          .magical-tab-label::before {
            content: '';
            position: absolute;
            inset: 0;
            border-radius: 4px;
            background: linear-gradient(45deg, #722ed1, #1890ff);
            z-index: -1;
            transition: all 0.3s ease;
            opacity: 0.8;
          }

          /* White background layer */
          .magical-tab-label::after {
            content: '';
            position: absolute;
            inset: 1px;
            background: white;
            border-radius: 3px;
            z-index: -1;
          }

          /* Gradient text */
          .magical-tab-label .tab-text,
          .magical-tab-label .anticon:not(.close-btn *) {
            background: linear-gradient(45deg, #722ed1, #1890ff);
            -webkit-background-clip: text;
            background-clip: text;
            -webkit-text-fill-color: transparent;
            font-weight: 500;
          }

          /* Hover effects */
          .magical-tab-label:hover::before {
            opacity: 1;
            box-shadow: 0 4px 12px rgba(114, 46, 209, 0.25);
          }

          /* Active state */
          .ant-tabs-tab-active .magical-tab-label::before {
            background: linear-gradient(45deg, #531dab, #096dd9);
            opacity: 1;
            box-shadow: 0 4px 12px rgba(114, 46, 209, 0.35);
          }

          /* Close button */
          .magical-tab-label .close-btn {
            background: transparent !important;
            border: none !important;
            padding: 2px !important;
            transition: all 0.3s ease;
            margin-left: 4px;
          }

          .magical-tab-label .close-btn .anticon {
            background: linear-gradient(45deg, #722ed1, #1890ff);
            -webkit-background-clip: text;
            background-clip: text;
            -webkit-text-fill-color: transparent;
          }

          .magical-tab-label .close-btn:hover {
            transform: scale(1.1);
          }

          .magical-tab-label .close-btn:hover .anticon {
            background: linear-gradient(45deg, #531dab, #096dd9);
            -webkit-background-clip: text;
            background-clip: text;
            -webkit-text-fill-color: transparent;
          }

          /* Remove default tab bottom border */
          .live-transcribe-tabs .ant-tabs-nav .ant-tabs-nav-list {
            border-bottom: none !important;
          }

          .live-transcribe-tabs .ant-tabs-nav::before {
            border-bottom: none !important;
          }

          .pause-button:hover {
            background-color: #389e0d !important;
            border-color: #389e0d !important;
          }

          .resume-button:hover {
            background-color: #d48806 !important;
            border-color: #d48806 !important;
          }

          .segment-container {
            position: relative;
            padding: 12px;
            border: 1px solid #f0f0f0;
            border-radius: 8px;
            margin-bottom: 8px;
            background: white;
            transition: all 0.3s ease;
          }

          .segment-container:hover {
            border-color: #1890ff;
            box-shadow: 0 2px 8px rgba(24, 144, 255, 0.15);
          }

          .segment-content {
            display: flex;
            flex-direction: column;
            gap: 8px;
            width: 100%;
          }

          .segment-text {
            display: inline;
            font-size: 16px;
            line-height: 1.6;
          }

          .segment-edit-form {
            display: flex;
            flex-direction: column;
            width: 100%;
            padding: 16px;
            background: #fafafa;
            border-radius: 4px;
            border: 1px solid #f0f0f0;
          }

          .edit-form-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
          }

          .segment-pills {
            display: flex;
            align-items: center;
            gap: 8px;
            margin-top: 4px;
          }

          .segment-actions {
            margin-left: auto;
            opacity: 0;
            transition: opacity 0.3s ease;
          }

          .segment-container:hover .segment-actions {
            opacity: 1;
          }

          .add-segment-button {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: -16px;
            z-index: 1;
            opacity: 0;
            transition: opacity 0.3s ease;
          }

          .segment-container:hover .add-segment-button {
            opacity: 1;
          }

          .system-message {
            padding: 8px 12px;
            background: #f8f8f8;
            border-radius: 4px;
            margin-bottom: 8px;
          }

          .raw-line {
            padding: 8px 12px;
            margin-bottom: 8px;
            border-left: 3px solid #d9d9d9;
          }

          .segment-edit-form .ant-input-textarea-show-count::after {
            margin-top: 4px;
            color: #8c8c8c;
          }

          .segment-edit-form .ant-input {
            border-color: #d9d9d9;
            transition: all 0.3s ease;
          }

          .segment-edit-form .ant-input:hover,
          .segment-edit-form .ant-input:focus {
            border-color: #1890ff;
            box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.1);
          }
        `}
      </style>
    </div>
  );
});

LiveTranscribeTab.displayName = 'LiveTranscribeTab';

export default LiveTranscribeTab; 