import { EUserType } from 'src/variables/enum-variables';
import { ESidebarItemLabel } from './sidebar-types';

export const SIDEBAR_ITEMS_BY_TYPE: { [key: string]: ESidebarItemLabel[] } = {
  [EUserType.CLINIC_OWNER]: [
    ESidebarItemLabel.PRACTITIONER,
    ESidebarItemLabel.MESSAGES,
    ESidebarItemLabel.CLIENTS,
    ESidebarItemLabel.HOMEWORK,
    ESidebarItemLabel.FILES,
    ESidebarItemLabel.PSYCHOEDUCATION,
    ESidebarItemLabel.SESSIONS,
    ESidebarItemLabel.TEMPLATES,
  ],
  [EUserType.PRACTITIONER]: [
    ESidebarItemLabel.MESSAGES,
    ESidebarItemLabel.CLIENTS,
    ESidebarItemLabel.HOMEWORK,
    ESidebarItemLabel.FILES,
    ESidebarItemLabel.PSYCHOEDUCATION,
    ESidebarItemLabel.SESSIONS,
    ESidebarItemLabel.TEMPLATES,
  ],
  [EUserType.SOLO_PRACTITIONER]: [
    ESidebarItemLabel.MESSAGES,
    ESidebarItemLabel.CLIENTS,
    ESidebarItemLabel.HOMEWORK,
    ESidebarItemLabel.FILES,
    ESidebarItemLabel.PSYCHOEDUCATION,
    ESidebarItemLabel.SESSIONS,
    ESidebarItemLabel.TEMPLATES,
  ],
};
