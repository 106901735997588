import { EHomeworkStatus } from 'src/variables/enum-variables';
import {
  FileTextOutlined,
  ProfileOutlined,
  ScheduleOutlined,
  AlertOutlined,
  SolutionOutlined,
  TeamOutlined,
  BookOutlined,
  FormOutlined,
  SafetyOutlined,
  MedicineBoxOutlined,
  BarChartOutlined,
  FileSearchOutlined,
  MessageOutlined,
  SendOutlined,
} from '@ant-design/icons';
import { DocumentType } from 'src/stores/clients/clients-actions';


export enum EClientDetailTabKey {
  GENERAL_INFO = 'generalInfo',
  HOMEWORK = 'homework',
  INSIGHTS = 'insights',
  AI = 'ai',
  FILES = 'files'
}

export const CLIENT_DETAIL_TAB_LABEL = {
  [EClientDetailTabKey.GENERAL_INFO]: 'Client information',
  [EClientDetailTabKey.HOMEWORK]: 'Homework',
  [EClientDetailTabKey.INSIGHTS]: 'Insights',
  [EClientDetailTabKey.FILES]: 'Files',
};

export const ALL_ASSIGNED_TAB_KEYS = {
  ONGOING: EHomeworkStatus.ACTIVE,
  EXPIRED: EHomeworkStatus.EXPIRED,
  REMOVED_TASKS: EHomeworkStatus.ARCHIVED,
};

export enum EHomeworkDetailTabKeys {
  DETAILS = 'DETAILS',
  HISTORY = 'HISTORY',
}

export enum EDragDropColumnId {
  OWN = 'OWN',
  GENERAL = 'GENERAL',
  ASSIGNED = 'ASSIGNED',
  ALL = 'ALL',
}

export const ALL_ASSIGNED_TASKS_TABS = [
  {
    key: ALL_ASSIGNED_TAB_KEYS.ONGOING,
    label: 'Ongoing',
  },
  {
    key: ALL_ASSIGNED_TAB_KEYS.EXPIRED,
    label: 'Expired',
  },
  {
    key: ALL_ASSIGNED_TAB_KEYS.REMOVED_TASKS,
    label: 'Removed tasks',
  },
];

export enum EHomeworkTabView {
  DEFAULT = 'DEFAULT',
  ASSIGN = 'ASSIGN',
  HOMEWORK_DETAIL = 'HOMEWORK_DETAIL',
}

export const HOMEWORK_DETAIL_TABS = [
  {
    key: EHomeworkDetailTabKeys.DETAILS,
    label: 'Homework Details',
  },
  {
    key: EHomeworkDetailTabKeys.HISTORY,
    label: 'Homework History',
  },
];

// Base interface for all document types
export interface BaseDocumentType {
  title: string;
  type: DocumentType;
  description: string;
  category: string;
  icon: React.ComponentType;
}

// Document types that can only be generated from multiple sessions
export const BULK_SESSION_DOCUMENT_TYPES: BaseDocumentType[] = [
  {
    title: 'Progress Note',
    type: 'progress_note',
    description: 'Documentation of client progress and treatment effectiveness',
    category: 'Clinical Documentation',
    icon: ScheduleOutlined,
  },
  {
    title: 'Treatment Plan',
    type: 'treatment_plan',
    description: 'Comprehensive treatment planning document',
    category: 'Clinical Documentation',
    icon: ProfileOutlined,
  },
  {
    title: 'Clinical Assessment',
    type: 'clinical_assessment',
    description: 'Detailed clinical assessment based on multiple sessions',
    category: 'Assessment',
    icon: SolutionOutlined,
  },
  {
    title: 'Care Coordination',
    type: 'care_coordination',
    description: 'Documentation for coordinating care between providers',
    category: 'Professional Communication',
    icon: TeamOutlined,
  },
  {
    title: 'Referral Letter',
    type: 'referral_letter',
    description: 'Formal referral letter to another healthcare provider',
    category: 'Professional Communication',
    icon: SendOutlined,
  },
  {
    title: "Quick Summary",
    type: "summary",
    description: "Generate a concise summary of the session, including key points and outcomes.",
    category: "Session Documentation",
    icon: FileTextOutlined
  },
  {
    title: "Doctor's Letter",
    type: "letter",
    description: "Create a professional letter for referring doctors or healthcare providers.",
    category: "Clinical Communication",
    icon: FormOutlined
  },
  {
    title: "Session Notes",
    type: "notes",
    description: "Generate detailed clinical notes from the session transcript.",
    category: "Session Documentation",
    icon: FileTextOutlined
  }
];

// Combined document types for shared functionality
export const DOCUMENT_TYPES = [...BULK_SESSION_DOCUMENT_TYPES];
