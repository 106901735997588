export type DassSeverityCategory = 'Normal' | 'Mild' | 'Moderate' | 'Severe' | 'Extremely Severe';

export interface DassSeverityRange {
  from: number;
  to: number;
  category: DassSeverityCategory;
  color: string;
}

export const SEVERITY_COLORS = {
  Normal: '#52c41a',
  Mild: '#faad14',
  Moderate: '#fa8c16',
  Severe: '#f5222d',
  'Extremely Severe': '#a8071a'
};

export const getDass21SeverityRanges = (metric: 'Depression' | 'Anxiety' | 'Stress'): DassSeverityRange[] => {
  const ranges: Record<typeof metric, DassSeverityRange[]> = {
    Depression: [
      { from: 0, to: 4, category: 'Normal', color: SEVERITY_COLORS.Normal },
      { from: 5, to: 6, category: 'Mild', color: SEVERITY_COLORS.Mild },
      { from: 7, to: 10, category: 'Moderate', color: SEVERITY_COLORS.Moderate },
      { from: 11, to: 13, category: 'Severe', color: SEVERITY_COLORS.Severe },
      { from: 14, to: Infinity, category: 'Extremely Severe', color: SEVERITY_COLORS['Extremely Severe'] }
    ],
    Anxiety: [
      { from: 0, to: 3, category: 'Normal', color: SEVERITY_COLORS.Normal },
      { from: 4, to: 5, category: 'Mild', color: SEVERITY_COLORS.Mild },
      { from: 6, to: 7, category: 'Moderate', color: SEVERITY_COLORS.Moderate },
      { from: 8, to: 9, category: 'Severe', color: SEVERITY_COLORS.Severe },
      { from: 10, to: Infinity, category: 'Extremely Severe', color: SEVERITY_COLORS['Extremely Severe'] }
    ],
    Stress: [
      { from: 0, to: 7, category: 'Normal', color: SEVERITY_COLORS.Normal },
      { from: 8, to: 9, category: 'Mild', color: SEVERITY_COLORS.Mild },
      { from: 10, to: 12, category: 'Moderate', color: SEVERITY_COLORS.Moderate },
      { from: 13, to: 16, category: 'Severe', color: SEVERITY_COLORS.Severe },
      { from: 17, to: Infinity, category: 'Extremely Severe', color: SEVERITY_COLORS['Extremely Severe'] }
    ]
  };
  return ranges[metric];
};

export const getDass42SeverityRanges = (metric: 'Depression' | 'Anxiety' | 'Stress'): DassSeverityRange[] => {
  const ranges: Record<typeof metric, DassSeverityRange[]> = {
    Depression: [
      { from: 0, to: 9, category: 'Normal', color: SEVERITY_COLORS.Normal },
      { from: 10, to: 13, category: 'Mild', color: SEVERITY_COLORS.Mild },
      { from: 14, to: 20, category: 'Moderate', color: SEVERITY_COLORS.Moderate },
      { from: 21, to: 27, category: 'Severe', color: SEVERITY_COLORS.Severe },
      { from: 28, to: Infinity, category: 'Extremely Severe', color: SEVERITY_COLORS['Extremely Severe'] }
    ],
    Anxiety: [
      { from: 0, to: 7, category: 'Normal', color: SEVERITY_COLORS.Normal },
      { from: 8, to: 9, category: 'Mild', color: SEVERITY_COLORS.Mild },
      { from: 10, to: 14, category: 'Moderate', color: SEVERITY_COLORS.Moderate },
      { from: 15, to: 19, category: 'Severe', color: SEVERITY_COLORS.Severe },
      { from: 20, to: Infinity, category: 'Extremely Severe', color: SEVERITY_COLORS['Extremely Severe'] }
    ],
    Stress: [
      { from: 0, to: 14, category: 'Normal', color: SEVERITY_COLORS.Normal },
      { from: 15, to: 18, category: 'Mild', color: SEVERITY_COLORS.Mild },
      { from: 19, to: 25, category: 'Moderate', color: SEVERITY_COLORS.Moderate },
      { from: 26, to: 33, category: 'Severe', color: SEVERITY_COLORS.Severe },
      { from: 34, to: Infinity, category: 'Extremely Severe', color: SEVERITY_COLORS['Extremely Severe'] }
    ]
  };
  return ranges[metric];
};

export const getSeverityForScore = (
  score: number,
  metric: 'Depression' | 'Anxiety' | 'Stress',
  version: 'DASS-21' | 'DASS-42'
): DassSeverityRange => {
  const ranges = version === 'DASS-21' ? getDass21SeverityRanges(metric) : getDass42SeverityRanges(metric);
  return ranges.find(range => score >= range.from && score <= range.to) || ranges[ranges.length - 1];
}; 