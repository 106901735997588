import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Card, Typography, Row, Col, Tooltip } from 'antd';
  import {
  getAverageTimeBetweenUpdates,
  getAverageMoodByFlag,
  getLongestMoodStreakByFlag,
  getMoodChangeRate
} from 'src/services/client-service';
import ResponseError from 'src/interfaces/error-response-interface';
import { showErrorToast } from 'src/components/toast/Toast';
import { EmotionIcon } from 'src/assets/icons';
import { BaseText } from 'src/components/typography';
import './Statistics.scss';

const { Title, Text } = Typography;

interface StatisticProps {
  title: string;
  isLoading: boolean;
  value: number;
}
interface EmojiProps {
  title: string;
  isLoading: boolean;
  value: number;
  badge: string;
}

interface TMoodStreakResponse {
  count: number;
  flag: number;
}

const blankResponse: TMoodStreakResponse = { count: 0, flag: 0};

function millisecondsToHours(milliseconds: number): number {
  // There are 3,600,000 milliseconds in an hour (1000 milliseconds * 60 seconds * 60 minutes).
  return milliseconds / 3600000;
}
/* eslint-disable react/prop-types */
const StatisticCard: React.FC<StatisticProps & { tooltipTitle: string, unitType: string }> = ({ title, isLoading, value, tooltipTitle, unitType }) => (
  <Col xs={24} sm={12} md={8} lg={6}>
    <Tooltip title={tooltipTitle}>
      <Card className="custom-card">
        <div className="card-content">
          <Text className="metric-title">{title}</Text>
          {isLoading ? (
            <Text className="loading-text">Loading...</Text>
          ) : (
            <div className="value-container">
              {/* Check if value is a number and convert it or display 'N/A' */}
              <Text className="value">{typeof value === 'number' ? value.toFixed(2) : 'N/A'}</Text>
              {/* Render unitType or an empty string if unitType is 'N/A' */}
              <Text className="unit">{typeof value === 'number' ? unitType : ''}</Text>
            </div>
          )}
        </div>
      </Card>
    </Tooltip>
  </Col>
);


/* eslint-disable react/prop-types */
const EmojiCard: React.FC<EmojiProps & { tooltipTitle: string }> = ({ title, isLoading, value, tooltipTitle, badge }) => (
  <Col xs={24} sm={12} md={8} lg={6}>
    <Tooltip title={tooltipTitle}>
      <Card className="custom-card">
        <div className="card-content">
          <Text className="metric-title">{title}</Text>
          {isLoading ? (
            <Text className="loading-text">Loading...</Text>
          ) : (
            <div className="emoji-container">
              <div className="badge" style={{ height: 24, width: 24, fontWeight: 'bold', backgroundColor: '#66CC33' }}>
                <div className="badge-text" style={{ color: 'white' }}>{badge}</div>
              </div>
              <div style={{ marginTop: 10 }}>
                <EmotionIcon rate={value} size={64} />
              </div>
            </div>
          )}
        </div>
      </Card>
    </Tooltip>
  </Col>
);

const Statistics: React.FC = () => {
  const params = useParams<{ clientId?: string }>();
  const clientId = params.clientId || '';

  const [isLoadingAverageTimeBetweenUpdates, setIsLoadingAverageTimeBetweenUpdates] = useState<boolean>(true);
  const [isLoadingAverageMoodByFlag, setIsLoadingAverageMoodByFlag] = useState<boolean>(true);
  const [isLoadingLongestMoodStreak, setIsLoadingLongestMoodStreak] = useState<boolean>(true);
  const [isLoadingMoodChangeRateByDay, setIsLoadingMoodChangeRateByDay] = useState<boolean>(true);

  const [averageTimeBetweenUpdates, setAverageTimeBetweenUpdates] = useState<number>(0);
  const [averageMoodByFlag, setAverageMoodByFlag] = useState<TMoodStreakResponse>(blankResponse);
  const [longestMoodStreak, setLongestMoodStreak] = useState<TMoodStreakResponse>(blankResponse);
  const [moodChangeRateByDay, setMoodChangeRateByDay] = useState<number>(0);

  const tryGetAverageTimeBetweenUpdates = async () => {
    try {
      setIsLoadingAverageTimeBetweenUpdates(true);
      const averageTimeBetweenUpdates = await getAverageTimeBetweenUpdates(clientId);
      setIsLoadingAverageTimeBetweenUpdates(false);
      setAverageTimeBetweenUpdates(averageTimeBetweenUpdates);
    } catch (e) {
      setIsLoadingAverageTimeBetweenUpdates(false);
      const { message } = e as ResponseError;
      showErrorToast(message);
      setAverageTimeBetweenUpdates(0);
    }
  };

  const tryGetAverageMoodByFlag = async () => {
    try {
      setIsLoadingAverageMoodByFlag(true);
      const averageMoodByFlag = await getAverageMoodByFlag(clientId);
      setIsLoadingAverageMoodByFlag(false);
      setAverageMoodByFlag(averageMoodByFlag);
    } catch (e) {
      setIsLoadingAverageMoodByFlag(false);
      const { message } = e as ResponseError;
      showErrorToast(message);
      setAverageMoodByFlag(blankResponse);
    }
  };

  const tryGetLongestMoodStreakInMilliseconds = async () => {
    try {
      setIsLoadingLongestMoodStreak(true);
      const longestMoodStreakInMilliseconds = await getLongestMoodStreakByFlag(clientId);
      setIsLoadingLongestMoodStreak(false);
      setLongestMoodStreak(longestMoodStreakInMilliseconds);
    } catch (e) {
      setIsLoadingLongestMoodStreak(false);
      const { message } = e as ResponseError;
      showErrorToast(message);
      setLongestMoodStreak(blankResponse);
    }
  };

  const tryGetMoodChangeRateByDay = async () => {
    try {
      setIsLoadingMoodChangeRateByDay(true);
      const moodChangeRateByDay = await getMoodChangeRate(clientId, "day");
      setIsLoadingMoodChangeRateByDay(false);
      setMoodChangeRateByDay(moodChangeRateByDay);
    } catch (e) {
      setIsLoadingMoodChangeRateByDay(false);
      const { message } = e as ResponseError;
      showErrorToast(message);
      setMoodChangeRateByDay(0);
    }
  };

  useEffect(() => {
    tryGetAverageTimeBetweenUpdates();
    tryGetAverageMoodByFlag();
    tryGetLongestMoodStreakInMilliseconds();
    tryGetMoodChangeRateByDay();
  }, []);

  return (
    <div className="Statistics">
      <div className="Statistics__header">
        <BaseText type="title">Statistics</BaseText>
      </div>
      <div className="Statistics__item">
        <Row gutter={[16, 16]}>
          <StatisticCard
            title="Avg. Time"
            isLoading={isLoadingAverageTimeBetweenUpdates}
            value={millisecondsToHours(averageTimeBetweenUpdates)}
            tooltipTitle="This metric represents the average time between mood updates in hours. It indicates how frequently moods are updated by the client."
            unitType="hours"
          />
          <StatisticCard
            title="Change Rate"
            isLoading={isLoadingMoodChangeRateByDay}
            value={moodChangeRateByDay}
            tooltipTitle="This metric represents the rate of mood change per day. It measures how frequently mood updates occur on a daily basis."
            unitType="days"
          />
          <EmojiCard
            title="Top Mood"
            isLoading={isLoadingAverageMoodByFlag}
            value={averageMoodByFlag?.flag}
            badge={`x${averageMoodByFlag?.count}`}
            tooltipTitle="This metric represents the most frequently occurring mood. It provides insight into the mood that the client uses most often."          
          />
          <EmojiCard
            title="Longest Streak"
            isLoading={isLoadingLongestMoodStreak}
            value={longestMoodStreak?.flag}
            badge={`x${longestMoodStreak?.count}`}
            tooltipTitle="This metric represents the longest streak of consistent moods recorded by the client. It measures the duration of the longest consecutive mood updates."
          />
        </Row>
      </div>
    </div>
  );
};

export default Statistics;
