export enum EProfileActions {
  UPDATE_CLINICS_PROFILES = 'UPDATE_CLINICS_PROFILES',
  UPDATE_PRACTITIONERS_PROFILES = 'UPDATE_PRACTITIONERS_PROFILES',
  GET_PRACTITIONER_TYPES = 'GET_PRACTITIONER_TYPES',
  GET_PRACTITIONER_PROFILE_ID = 'GET_PRACTITIONER_PROFILE_ID',
  START_TRIAL = 'START_TRIAL',
  CREATE_CARD = 'CREATE_CARD',
  GET_CARD_LIST = 'GET_CARD_LIST',
  SET_CARD_DEFAULT = 'SET_CARD_DEFAULT',
  DELETE_CARD = 'DELETE_CARD',
  UPDATE_CARD = 'UPDATE_CARD',
  GET_CURRENT_SUBSCRIPTION = 'GET_CURRENT_SUBSCRIPTION',
  GET_DETAIL_PROFILE = 'GET_DETAIL_PROFILE',
  UPDATE_DETAIL_PROFILE = 'UPDATE_DETAIL_PROFILE',
  GET_CLINIC_DETAIL = 'GET_CLINIC_DETAIL',
  UPDATE_CLINIC_DETAIL = 'UPDATE_CLINIC_DETAIL',
  SEND_CONTACT_HELP = 'SEND_CONTACT_HELP',
  LOOKUP_ADDRESS = 'profile/lookupAddress',
  SEND_PHONE_VERIFICATION = 'profile/sendPhoneVerification',
  VERIFY_PHONE_CODE = 'profile/verifyPhoneCode',
}
