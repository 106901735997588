/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from '@reduxjs/toolkit';

import { AxiosResponse } from 'axios';
import {
  TClientInfomationResponse,
  TClientUpdateInfoRequest,
  TClientUpdateInfoResponse,
  TClientUploadFileRequest,
  TClientUploadFileResponse,
  TDeleteClientFileRequest,
  TDeleteClientFileResponse,
  TDischargeClientRequest,
  TGetAssignedHomeworkDetailsParams,
  TGetAssignedHomeworkDetailsResponse,
  TGetAssignedHomeworkHistoriesParams,
  TGetAssignedHomeworkHistoriesResponse,
  TGetAssignedHomeworkParams,
  TGetClientFileParams,
  TGetClientFilesResponse,
  TGetClientsParams,
  TGetClientsResponse,
  TGetHomeworkHistoryListParams,
  TGetHomeworkHistoryParams,
  TGetHomeworkHistoryListResponse,
  TGetHomeworkResultParams,
  TGetHomeworkResultResponse,
  TGetTotalAssignedHomeworkParams,
  TGetTotalAssignedHomeworkResponse,
  TInviteClientRequest,
  TInviteClientResponse,
  TMedicalProfileRequest,
  TRemoveAssignedHomeworkParams,
  TRemoveAssignedHomeworkResponse,
  TResendResponse,
  TRevokeResponse,
  TUpdateHomeworkDetailsRequest,
  TUpdateHomeworkDetailsResponse,
  TUpdateMedicalProfileResponse,
  TGetAiConversationParams,
  TUpdateAiConversationParams,
  TSendPromptResponse,
  TGetAIConversationsParams,
  TGetAIConversationsResponse,
  TSummariseConversationsParams,
} from 'src/interfaces/clients-interface';
import { ApiClient } from 'src/services/api-client';
import { EClientsAction } from './clients-constants';
import { TDownloadClientFileRequest, TDownloadHomeworkHistoryFileRequest } from 'src/interfaces/common-interface';
import ResponseError from 'src/interfaces/error-response-interface';
import { GenerateDocumentRequest, GenerateDocumentResponse, GetTranscriptDetailsResponse, GetTranscriptsResponse } from 'src/interfaces/transcript-interface';
import { TRootState } from '..';

export const getClients = createAsyncThunk(
  EClientsAction.GET_CLIENTS,
  async (params: TGetClientsParams, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<TGetClientsResponse> = await ApiClient.get('/api/v1/practitioners/clients', {
        params,
      });

      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.data);
    }
  },
);

export const inviteClient = createAsyncThunk(
  EClientsAction.INVITE_CLIENT,
  async (bodyRequest: TInviteClientRequest) => {
    try {
      const response: AxiosResponse<TInviteClientResponse> = await ApiClient.post(
        '/api/v1/practitioners/invite-client-code',
        bodyRequest,
      );

      return response.data;
    } catch (error: any) {
      return error.data;
    }
  },
);

export const dischargeClient = createAsyncThunk(
  EClientsAction.DISCHARGE_CLIENT,
  async ({ id }: TDischargeClientRequest) => {
    try {
      await ApiClient.put(`/api/v1/practitioners/discharge/${id}`);
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);

export const getAssignedHomeworkListAction = createAsyncThunk(
  EClientsAction.GET_CLIENT_HOMEWORK,
  async (params: TGetAssignedHomeworkParams) => {
    try {
      const response = await ApiClient.get(`/api/v1/practitioners/homework-assign`, { params });
      return response.data;
    } catch (error: any) {
      return error.data;
    }
  },
);

export const loadMoreAssignedHomeworkListAction = createAsyncThunk(
  EClientsAction.LOAD_MORE_CLIENT_HOMEWORK,
  async (params: TGetAssignedHomeworkParams) => {
    try {
      const response = await ApiClient.get(`/api/v1/practitioners/homework-assign`, { params });
      return response.data;
    } catch (error: any) {
      return error.data;
    }
  },
);

export const getTotalAssignedHomeworkAction = createAsyncThunk(
  EClientsAction.GET_TOTAL_ASSIGNED_HOMEWORK,
  async (params: TGetTotalAssignedHomeworkParams) => {
    try {
      const response: AxiosResponse<TGetTotalAssignedHomeworkResponse> = await ApiClient.get(
        'api/v1/practitioners/homework-assign/total',
        { params },
      );
      return response.data;
    } catch (error: any) {
      console.error(error);
      throw error;
    }
  },
);

export const removeAssignedHomeworkAction = createAsyncThunk(
  EClientsAction.REMOVE_ASSIGNED_HOMEWORK,
  async (params: TRemoveAssignedHomeworkParams) => {
    try {
      const response: AxiosResponse<TRemoveAssignedHomeworkResponse> = await ApiClient.delete(
        `api/v1/practitioners/homework-assign/${params.homeworkAssignId}`,
        { params },
      );
      return response.data;
    } catch (error: any) {
      return error.data;
    }
  },
);

export const getHomeworkHistoryListAction = createAsyncThunk(
  EClientsAction.GET_HOMEWORK_HISTORY_LIST,
  async (bodyRequest: TGetHomeworkHistoryListParams) => {
    try {
      const response: AxiosResponse<TGetHomeworkHistoryListResponse> = await ApiClient.post(
        '/api/v1/practitioners/homework-history',
        bodyRequest,
      );
      return response.data;
    } catch (error: any) {
      return error.data;
    }
  },
);

export const getHomeworkHistoryByIdAction = createAsyncThunk(
  EClientsAction.GET_HOMEWORK_HISTORY_BY_ID,
  async (bodyRequest: TGetHomeworkHistoryParams) => {
    try {
      const response: AxiosResponse<TGetHomeworkHistoryListResponse> = await ApiClient.post(
        '/api/v1/practitioners/homework-history/homework',
        bodyRequest,
      );
      return response.data;
    } catch (error: any) {
      return error.data;
    }
  },
);

export const loadMoreHomeworkHistoryListAction = createAsyncThunk(
  EClientsAction.LOAD_MORE_HOMEWORK_HISTORY_LIST,
  async (bodyRequest: TGetHomeworkHistoryListParams): Promise<TGetHomeworkHistoryListResponse> => {
    try {
      const response: AxiosResponse<TGetHomeworkHistoryListResponse> = await ApiClient.post(
        '/api/v1/practitioners/homework-history',
        bodyRequest,
      );
      return response.data;
    } catch (error: any) {
      return error.data;
    }
  },
);

export const getAssignedHomeworkDetailsAction = createAsyncThunk(
  EClientsAction.GET_ASSIGNED_HOMEWORK_DETAILS,
  async (params: TGetAssignedHomeworkDetailsParams): Promise<TGetAssignedHomeworkDetailsResponse> => {
    try {
      const response: AxiosResponse<TGetAssignedHomeworkDetailsResponse> = await ApiClient.get(
        `/api/v1/practitioners/homework-assign/${params.homeworkAssignId}`,
        {
          params,
        },
      );
      return response.data;
    } catch (error: any) {
      return error.data;
    }
  },
);

export const updateHomeworkDetailsAction = createAsyncThunk(
  EClientsAction.UPDATE_HOMEWORK_DETAILS,
  async (bodyRequest: TUpdateHomeworkDetailsRequest) => {
    try {
      const response: AxiosResponse<TUpdateHomeworkDetailsResponse> = await ApiClient.put(
        `/api/v1/practitioners/homework-assign/${bodyRequest.id}`,
        bodyRequest,
      );
      return response.data;
    } catch (error: any) {
      return error.data;
    }
  },
);

export const getAssignedHomeworkHistoriesAction = createAsyncThunk(
  EClientsAction.GET_ASSIGNED_HOMEWORK_HISTORIES,
  async (params: TGetAssignedHomeworkHistoriesParams): Promise<TGetAssignedHomeworkHistoriesResponse> => {
    try {
      const response: AxiosResponse<TGetAssignedHomeworkHistoriesResponse> = await ApiClient.post(
        `/api/v1/practitioners/homework-history/${params.id}`,
        params,
      );
      return response.data;
    } catch (error: any) {
      return error.data;
    }
  },
);

export const getHomeworkResultAction = createAsyncThunk(
  EClientsAction.GET_HOMEWORK_RESULT,
  async (params: TGetHomeworkResultParams): Promise<TGetHomeworkResultResponse> => {
    try {
      const response: AxiosResponse<TGetHomeworkResultResponse> = await ApiClient.get(
        `/api/v1/practitioners/homework-history/${params.id}/result/${params.homeworkHistoryId}`,
      );
      return response.data;
    } catch (error: any) {
      return error.data;
    }
  },
);

export const getAiConversationAction = createAsyncThunk(
  EClientsAction.GET_AI_CONVERSATION_RESPONSES,
  async (params: TGetAiConversationParams) => {
    try {
      const response: AxiosResponse<TSendPromptResponse[]> = await ApiClient.get(
        `/api/v1/gpt/client/${params.clientId}/messages/${params.homeworkAssignId}`,
      );

      return response.data;
    } catch (error: any) {
      return error.data;
    }
  },
);

export const updateAiConversationDataAction = createAsyncThunk(
  EClientsAction.UPDATE_AI_CONVERSATION_RESPONSE,
  async (payload: TUpdateAiConversationParams) => {
    try {
      const response: AxiosResponse<TSendPromptResponse[]> = await ApiClient.patch(
        `/api/v1/gpt/client/messages`,
        payload
      );

      return response.data;
    } catch (error: any) {
      return error.data;
    }
  },
);

export const downloadHomeworkFileAction = createAsyncThunk(
  EClientsAction.DOWNLOAD_HOMEWORK_FILE,
  async (request: TDownloadHomeworkHistoryFileRequest) => {
    const { homeworkAssignId, attachmentId, homeworkResultId } = request;
    try {
      const response = await ApiClient.post(
        `api/v1/practitioners/homework-history/${homeworkAssignId}/result/${homeworkResultId}/download/${attachmentId}`,
        {},
        { responseType: 'blob' },
      );
      return response.data;
    } catch (error: any) {
      return error.data;
    }
  },
);

export const getClientGeneralInfo = createAsyncThunk(
  EClientsAction.GET_CLIENT_GENERAL_INFO,
  async (clientId: string) => {
    try {
      const response: AxiosResponse<TClientInfomationResponse> = await ApiClient.get(
        `api/v1/practitioners/client-management/${clientId}/general-info`,
      );
      return response.data;
    } catch (error: any) {
      return error.data;
    }
  },
);

export const getClientMedicalInfo = createAsyncThunk(
  EClientsAction.GET_CLIENT_MEDICAL_INFORMATION,
  async (clientId: string): Promise<TMedicalProfileRequest> => {
    try {
      const response: AxiosResponse<TMedicalProfileRequest> = await ApiClient.get(
        `/api/v1/practitioners/client-management/${clientId}/medical-info`,
      );
      return response.data;
    } catch (error: any) {
      return error.data;
    }
  },
);

export const updateClientGeneralInfo = createAsyncThunk(
  EClientsAction.UPDATE_CLIENT_GENERAL_INFO,
  async (payload: TClientUpdateInfoRequest, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<TClientUpdateInfoResponse> = await ApiClient.put(
        `api/v1/practitioners/client-management/${payload.clientId}/general-info`,
        payload,
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error as ResponseError);
    }
  },
);

export const updateClientMedicalInfo = createAsyncThunk(
  EClientsAction.UPDATE_CLIENT_MEDICAL_INFORMATION,
  async (params: TMedicalProfileRequest) => {
    try {
      const response: AxiosResponse<TUpdateMedicalProfileResponse> = await ApiClient.put(
        `/api/v1/practitioners/client-management/${params.clientId}/medical-info`,
        params,
      );
      return response.data;
    } catch (error: any) {
      return error.data;
    }
  },
);

export const resendClientInvitation = createAsyncThunk(
  EClientsAction.RESEND_INVITATION_CLIENT,
  async (clientId: string): Promise<TResendResponse> => {
    try {
      const response: AxiosResponse<TResendResponse> = await ApiClient.post(
        `/api/v1/practitioners/client-management/${clientId}/resend-invitation-code`,
      );
      return response.data;
    } catch (error: any) {
      return error.data;
    }
  },
);

export const revokeClientInvitation = createAsyncThunk(
  EClientsAction.RESEND_INVITATION_CLIENT,
  async (clientId: string): Promise<TRevokeResponse> => {
    try {
      const response: AxiosResponse<TRevokeResponse> = await ApiClient.post(
        `/api/v1/practitioners/client-management/${clientId}/revoke-invitation`,
      );
      return response.data;
    } catch (error: any) {
      return error.data;
    }
  },
);

export const reactivateClient = createAsyncThunk(
  EClientsAction.REACTIVATE_CLIENT,
  async (clientId: string): Promise<TResendResponse> => {
    try {
      const response: AxiosResponse<TResendResponse> = await ApiClient.put(
        `/api/v1/practitioners/client-management/${clientId}/reactive`,
      );
      return response.data;
    } catch (error: any) {
      return error.data;
    }
  },
);

export const clientUploadFile = createAsyncThunk(
  EClientsAction.CLIENT_UPLOAD_FILE,
  async (payload: TClientUploadFileRequest) => {
    try {
      const form = new FormData();
      if (payload.file) {
        form.append('file', payload.file.file as unknown as Blob);
      }
      form.append('name', payload.name);

      const response: AxiosResponse<TClientUploadFileResponse> = await ApiClient.post(
        `/api/v1/practitioners/client-management/${payload.clientId}/files`,
        form,
      );
      return response.data;
    } catch (error: any) {
      return error.data;
    }
  },
);

export const getClientFiles = createAsyncThunk(
  EClientsAction.GET_CLIENT_FILES,
  async (params: TGetClientFileParams, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<TGetClientFilesResponse> = await ApiClient.get(
        `/api/v1/practitioners/client-management/${params.clientId}/files`,
        {
          params: params.filter,
        },
      );

      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.data);
    }
  },
);

export const deleteClientFile = createAsyncThunk(
  EClientsAction.DELETE_CLIENT_FILE,
  async (params: TDeleteClientFileRequest) => {
    try {
      const response: AxiosResponse<TDeleteClientFileResponse> = await ApiClient.delete(
        `/api/v1/practitioners/client-management/${params.clientId}/files/${params.fileId}`,
      );
      return response.data;
    } catch (error: any) {
      return error.data;
    }
  },
);

export const downloadClientFileAction = createAsyncThunk(
  EClientsAction.DOWNLOAD_CLIENT_FILE,
  async (request: TDownloadClientFileRequest) => {
    const { clientId, fileId } = request;
    try {
      const response = await ApiClient.post(
        `/api/v1/practitioners/client-management/${clientId}/download/${fileId}`,
        {},
        { responseType: 'blob' },
      );
      return response.data;
    } catch (error: any) {
      return error.data;
    }
  },
);

export const summariseConversationAction = createAsyncThunk(
  EClientsAction.SUMMARISE_CONVERSATION,
  async (params: TSummariseConversationsParams, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<{ summary: string }> = await ApiClient.post(
        `/api/v1/gpt/client/${params.clientId}/messages/summarise`,
        {
          homeworkAssignIds: params.homeworkAssignIds
        }
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.data);
    }
  },
);
// Add these to your existing client actions file

export const getTranscriptsAction = createAsyncThunk(
  EClientsAction.GET_TRANSCRIPTS,
  async (params: { clientId: string; startDate?: string; endDate?: string; page?: number; limit?: number }, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<GetTranscriptsResponse> = await ApiClient.get(
        '/api/v1/ai/transcriptions',
        { params }
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.data);
    }
  }
);

export const getTranscriptDetailsAction = createAsyncThunk(
  EClientsAction.GET_TRANSCRIPT_DETAILS,
  async ({ transcriptId, clientId }: { transcriptId: string; clientId: string }, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<GetTranscriptDetailsResponse> = await ApiClient.get(
        `/api/v1/ai/transcriptions/${transcriptId}`,
        { params: { clientId } }
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.data);
    }
  }
);

export const analyseTranscriptAction = createAsyncThunk(
  EClientsAction.ANALYSE_TRANSCRIPT,
  async ({ transcriptId, clientId }: { transcriptId: string; clientId: string }, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<any> = await ApiClient.post(
        `/api/v1/ai/transcriptions/${transcriptId}/analyse`,
        { clientId }
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.data);
    }
  }
);

export type DocumentType = 
  | 'session_notes' 
  | 'treatment_plan'
  | 'progress_note'
  | 'risk_assessment'
  | 'clinical_assessment'
  | 'diagnostic_summary'
  | 'homework_plan'
  | 'care_coordination'
  | 'summary'
  | 'letter'
  | 'notes'
  | 'referral_letter'
  | 'session_summary';

export const generateDocumentAction = createAsyncThunk(
  'clients/generateDocument',
  async (params: { 
    transcriptId: string; 
    clientId: string; 
    documentType: DocumentType;
  }) => {
    try {
      const response: AxiosResponse<GenerateDocumentResponse> = await ApiClient.post(
        `/api/v1/ai/transcriptions/${params.transcriptId}/generate-document`,
        {
          clientId: params.clientId,
          documentType: params.documentType
        }
      );
      return response.data;
    } catch (error: any) {
      return error.data;
    }
  }
);

export const deleteTranscriptsAction = createAsyncThunk(
  'clients/deleteTranscripts',
  async ({ transcriptIds, clientId }: { transcriptIds: string[]; clientId: string }) => {
    try {
      await ApiClient.post('/api/v1/ai/transcriptions/delete', {
        transcriptIds,
        clientId
      });
      return transcriptIds;
    } catch (error: any) {
      return error.data;
    }
  }
);

export const updateTranscriptSegmentAction = createAsyncThunk<
  any,
  {
    transcriptId: string;
    segmentId: string;
    text: string;
    clientId: string;
    speakerLabelId?: string;
    speaker?: string;
  },
  { state: TRootState }
>('clients/updateTranscriptSegment', async (params, thunkAPI) => {
  try {
    // Check if this is a timestamp-based ID (from new segment creation)
    if (params.segmentId.match(/^\d{13,}$/)) {
      // Get the current transcript details to find segment positions
      const transcriptResponse = await ApiClient.get(
        `/api/v1/ai/transcriptions/${params.transcriptId}?clientId=${params.clientId}`
      );
      
      const segments = transcriptResponse.data.segments || [];
      let startTime = 0;

      if (segments.length > 0) {
        // Sort segments by start time
        const sortedSegments = [...segments].sort((a, b) => a.startTime - b.startTime);
        const lastSegment = sortedSegments[sortedSegments.length - 1];
        
        // Add 5 seconds to the last segment's time
        startTime = lastSegment.startTime + 5;

        // Use the last segment's speaker if no speaker provided
        if (!params.speaker) {
          params.speaker = lastSegment.speaker;
        }
      }

      // Add the new segment
      const response = await ApiClient.post<TranscriptSegment[]>(
        `/api/v1/ai/transcriptions/${params.transcriptId}/segments`,
        {
          clientId: params.clientId,
          segments: [{
            text: params.text,
            speaker: params.speaker || 'Unknown Speaker',
            startTime
          }]
        }
      );
      return response.data[0];
    } else {
      // This is an existing segment, use the update endpoint
      const response = await ApiClient.put(
        `/api/v1/ai/transcriptions/${params.transcriptId}/segments/${params.segmentId}`,
        {
          text: params.text,
          speaker: params.speaker, // Include speaker in update
          clientId: params.clientId
        }
      );
      return response.data;
    }
  } catch (error: any) {
    console.error('Error in updateTranscriptSegmentAction:', error);
    throw error;
  }
});

export const getAIConversationsAction = createAsyncThunk(
  EClientsAction.GET_AI_CONVERSATIONS,
  async (params: TGetAIConversationsParams, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<TGetAIConversationsResponse> = await ApiClient.get(
        `/api/v1/gpt/client/${params.clientId}/conversations`,
        { params }
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.data);
    }
  }
);

// Add template-related actions
export interface Template {
  id: string;
  name: string;
  description: string;
  content: string;
  type: string;
  createdBy: string;
  isPrivate?: boolean;
  icon?: string;
  color?: string;
  tags?: string[];
  clinicId?: string;
  usageCount?: number;
  createdAt: string;
  updatedAt: string;
}

export const getTemplatesAction = createAsyncThunk(
  'clients/getTemplates',
  async (params: { keyword?: string } = {}) => {
    const response = await ApiClient.get('/api/v1/templates', { params });
    return response.data;
  }
);

export const getTemplateByIdAction = createAsyncThunk(
  'clients/getTemplateById',
  async (id: string, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<Template> = await ApiClient.get(`/api/v1/templates/${id}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.data);
    }
  }
);

export const createTemplateAction = createAsyncThunk(
  'clients/createTemplate',
  async (template: {
    name: string;
    description: string;
    content: string;
    isPrivate: boolean;
    clinicId?: string;
    createdBy: string;
    icon?: string;
    color?: string;
    tags: string[];
  }, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<Template> = await ApiClient.post('/api/v1/templates', template);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.data);
    }
  }
);

export const updateTemplateAction = createAsyncThunk(
  'clients/updateTemplate',
  async ({ id, template }: { id: string; template: Partial<Template> }, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<Template> = await ApiClient.put(`/api/v1/templates/${id}`, template);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.data);
    }
  }
);

export const deleteTemplateAction = createAsyncThunk(
  'clients/deleteTemplate',
  async (id: string, { rejectWithValue }) => {
    try {
      await ApiClient.delete(`/api/v1/templates/${id}`);
      return id;
    } catch (error: any) {
      return rejectWithValue(error.data);
    }
  }
);

export const generateDocumentFromTemplateAction = createAsyncThunk(
  'clients/generateDocumentFromTemplate',
  async (params: { 
    transcript: {
      segments: {
        speaker: string;
        text: string;
        startTime: number;
      }[];
    };
    template: {
      id: string;
      name: string;
      content: string;
      variables?: { name: string; value: string }[];
    };
    clientId?: string;
    clientName?: string;
  }) => {
    try {
      const response: AxiosResponse<GenerateDocumentResponse> = await ApiClient.post(
        `/api/v1/ai/generate-document-from-template`,
        {
          transcript: params.transcript,
          template: params.template,
          clientId: params.clientId,
          clientName: params.clientName
        }
      );
      return response.data;
    } catch (error: any) {
      return error.data;
    }
  }
);

export const uploadTranscriptToClientAction = createAsyncThunk(
  'clients/uploadTranscriptToClient',
  async (params: {
    clientId: string;
    transcript: {
      segments: {
        speaker: string;
        text: string;
        startTime: number;
        sentiment?: {
          score: number;
          label: 'positive' | 'negative' | 'neutral';
        };
      }[];
      rawText: string;
    };
    metadata: {
      recordingDate: string;
      totalSegments: number;
      averageConfidence: number;
      duration: number;
    };
  }) => {
    try {
      const response: AxiosResponse<{ id: string; status: 'success' | 'error' }> = await ApiClient.post(
        '/api/v1/ai/transcriptions/upload-to-client',
        params
      );
      return response.data;
    } catch (error: any) {
      return error.data;
    }
  }
);

export const getPractitionerTranscriptsAction = createAsyncThunk(
  'clients/getPractitionerTranscripts',
  async (params: {
    page?: number;
    limit?: number;
    startDate?: string;
    endDate?: string;
    clientName?: string;
    sortBy?: 'recordingDate' | 'clientName' | 'duration';
    sortOrder?: 'ASC' | 'DESC';
  }) => {
    try {
      const response = await ApiClient.get('/api/v1/ai/practitioner/transcriptions', { params });
      return response.data;
    } catch (error: any) {
      return error.data;
    }
  }
);

// Add new interface for Whisper response
export interface WhisperTranscriptionResponse {
  id: string;
  text: string;
  segments: Array<{
    id: string;
    start: number;
    end: number;
    text: string;
    speaker: string;
    confidence: number;
    sentiment?: {
      label: 'positive' | 'negative' | 'neutral';
      score: number;
    };
  }>;
  status: 'success' | 'error';
  message?: string;
}

export interface PIIRedactionSettings {
  enabled: boolean;
  redactTypes?: {
    personalInfo: boolean;  // phone_number, email, ssn
    financialInfo: boolean; // credit_card_number, bank_account_number
    locationInfo: boolean;  // address, location, ip_address
    temporalInfo: boolean;  // date_time
    identityInfo: boolean;  // person, organization
    otherInfo: boolean;    // product, event, url, other
  };
}

// Update the existing processAudioWithWhisperAction
export const processAudioWithWhisperAction = createAsyncThunk(
  'clients/processAudioWithWhisper',
  async (params: { 
    formData: FormData;
    piiSettings?: PIIRedactionSettings;
  }) => {
    try {
      // Log the incoming parameters
      console.log('processAudioWithWhisperAction called with params:', {
        hasPiiSettings: !!params.piiSettings,
        piiSettings: params.piiSettings
      });

      // Log FormData contents
      const formDataEntries: { [key: string]: any } = {};
      params.formData.forEach((value, key) => {
        formDataEntries[key] = value;
      });
      console.log('FormData contents before sending:', formDataEntries);

      // Add PII settings to formData if provided
      if (params.piiSettings) {
        console.log('Adding PII settings to FormData:', params.piiSettings);
        
        // Convert piiSettings to a string and append as a separate field
        const piiSettingsJson = JSON.stringify(params.piiSettings);
        console.log('PII settings JSON string:', piiSettingsJson);
        params.formData.delete('piiSettings'); // Remove any existing piiSettings
        params.formData.append('piiSettings', piiSettingsJson);
        params.formData.append('piiEnabled', params.piiSettings.enabled.toString());
        
        if (params.piiSettings.redactTypes) {
          Object.entries(params.piiSettings.redactTypes).forEach(([key, value]) => {
            const fieldName = `piiRedactTypes[${key}]`;
            params.formData.delete(fieldName); // Remove any existing field
            params.formData.append(fieldName, value.toString());
            console.log(`Added PII field: ${fieldName} = ${value}`);
          });
        }

        // Log final FormData contents
        const finalFormDataEntries: { [key: string]: any } = {};
        params.formData.forEach((value, key) => {
          finalFormDataEntries[key] = value;
        });
        console.log('Final FormData contents:', finalFormDataEntries);
      }

      console.log('Sending request to transcribe endpoint...');
      const response = await ApiClient.post<WhisperTranscriptionResponse>(
        '/api/v1/ai/whisper/transcribe',
        params.formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          timeout: 600000, // 10 minutes timeout
          timeoutErrorMessage: 'Request timed out - the audio file may be too large or the server is busy',
          maxContentLength: Infinity,
          maxBodyLength: Infinity,
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            console.log(`Upload Progress: ${percentCompleted}%`);
          }
        }
      );

      console.log('Received response from transcribe endpoint:', {
        status: response.data.status,
        hasSegments: !!response.data.segments?.length
      });

      if (response.data.status === 'error') {
        throw new Error(response.data.message || 'Failed to transcribe audio');
      }

      // Map the response to include any additional fields from AssemblyAI
      const mappedResponse: WhisperTranscriptionResponse = {
        ...response.data,
        segments: response.data.segments.map(segment => ({
          ...segment,
          speaker: segment.speaker || 'Unknown Speaker',
          sentiment: segment.sentiment || {
            label: 'neutral',
            score: 0.5
          }
        }))
      };

      return mappedResponse;
    } catch (error: any) {
      console.error('Error processing audio with AssemblyAI:', error);
      // Add more detailed error information
      if (error.code === 'ECONNABORTED') {
        throw new Error('Request timed out - the audio file may be too large or the server is busy');
      }
      if (error.response) {
        throw new Error(`Server error: ${error.response.data?.message || error.message}`);
      }
      throw error;
    }
  }
);

export interface AddTranscriptSegmentsRequest {
  transcriptId: string;
  clientId: string;
  segments: Array<{
    text: string;
    speaker: string;
    startTime?: number;
    beforeSegmentId?: string;
    afterSegmentId?: string;
  }>;
}

export const addTranscriptSegmentsAction = createAsyncThunk(
  'clients/addTranscriptSegments',
  async (params: AddTranscriptSegmentsRequest) => {
    try {
      const response = await ApiClient.post<TranscriptSegment[]>(
        `/api/v1/ai/transcriptions/${params.transcriptId}/segments`,
        {
          clientId: params.clientId,
          segments: params.segments
        }
      );

      return response.data;
    } catch (error: any) {
      console.error('Error adding transcript segments:', error);
      throw error;
    }
  }
);

export interface TranscriptSegment {
  id: string;
  transcriptId: string;
  speaker: string;
  startTime: number;
  text: string;
  createdAt: string;
  updatedAt: string;
}

export interface UpdateTranscriptRequest {
  transcriptId: string;
  clientId: string;
  segments: Array<{
    speaker: string;
    text: string;
    isEdited?: boolean;
  }>;
}

export const updateTranscriptAction = createAsyncThunk(
  'clients/updateTranscript',
  async (params: UpdateTranscriptRequest) => {
    try {
      const response = await ApiClient.put(
        `/api/v1/ai/transcriptions/${params.transcriptId}`,
        {
          clientId: params.clientId,
          segments: params.segments
        }
      );
      return response.data;
    } catch (error: any) {
      console.error('Error updating transcript:', error);
      throw error;
    }
  }
);

export const generateTemplatePreviewAction = createAsyncThunk(
  'clients/generateTemplatePreview',
  async (template: Template, { rejectWithValue }) => {
    try {
      // Create a mock transcript with instructions for the LLM
      const mockTranscript = {
        segments: [{
          speaker: 'SYSTEM',
          text: `This is a preview generation request. Please generate a realistic example of this template type: "${template.name}". 
                 Use realistic but fictional data to showcase the template's structure and capabilities. 
                 Make sure to include all sections and demonstrate how each part would look when filled with actual client data.`,
          startTime: Date.now()
        }]
      };

      const response = await ApiClient.post('/api/v1/ai/generate-document-from-template', {
        template: {
          id: template.id,
          name: template.name,
          content: template.content
        },
        transcript: mockTranscript
      });

      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.data);
    }
  }
);

// Add new interfaces for settings
export interface PIISettings {
  enabled: boolean;
  redactTypes: {
    personalInfo: boolean;
    financialInfo: boolean;
    locationInfo: boolean;
    temporalInfo: boolean;
    identityInfo: boolean;
    otherInfo: boolean;
  };
}

export interface AccountSettings {
  id: string;
  accountId: string;
  transcriptionSettings: {
    pii: PIISettings;
  };
  createdAt: Date;
  updatedAt: Date;
}

// Add new actions
export const getAccountSettingsAction = createAsyncThunk(
  'clients/getAccountSettings',
  async () => {
    try {
      const response: AxiosResponse<AccountSettings> = await ApiClient.get('/api/v1/account/settings');
      return response.data;
    } catch (error: any) {
      return error.data;
    }
  }
);

export const updateAccountSettingsAction = createAsyncThunk(
  'clients/updateAccountSettings',
  async (settings: { transcriptionSettings: { pii: PIISettings } }) => {
    try {
      const response: AxiosResponse<AccountSettings> = await ApiClient.put('/api/v1/account/settings', settings);
      return response.data;
    } catch (error: any) {
      return error.data;
    }
  }
);


