import { useSelector } from 'react-redux';
import { Checkbox } from 'antd';
import { DropResult } from 'react-beautiful-dnd';

import Collapse from 'src/components/collapse';
import { SearchInput } from 'src/components/input';
import { BaseText } from 'src/components/typography';
import { THomework, TTaskItemData } from 'src/interfaces/clients-interface';
import { THomeworkTopic } from 'src/interfaces/homework-topics';
import { EDragDropColumnId } from 'src/pages/clients/client-details-page-constants';
import { TRootState } from 'src/stores';
import { SEPARATE_TYPE_HOMEWORK } from 'src/variables/client';
import DragDropTasksColumn from './DragDropTasksColumn';
import './HomeworkTasksColumn.scss';

interface IProps {
  className?: string;
  selectedTopic?: THomeworkTopic;
  selectedHomeworkIds: string[];
  searchValue: string;
  onLoadMore: (page: number, type?: EDragDropColumnId) => void;
  onSearchHomework: (keyword: string) => void;
  onClickHomework: (item: TTaskItemData) => void;
  onDragEnd: (result: DropResult) => void;
  onRemove?: (item: TTaskItemData) => void;
  isMobile?: boolean;
  renderMobileActions?: (task: TTaskItemData) => React.ReactNode;
}

const HomeworkTasksColumn = ({
  className,
  selectedTopic,
  selectedHomeworkIds,
  searchValue,
  onLoadMore,
  onSearchHomework,
  onClickHomework,
  onDragEnd,
  onRemove,
  isMobile,
  renderMobileActions,
}: IProps) => {
  const homework = useSelector((state: TRootState) => state.homeworkTopics.homework);

  const isSeparateHomeworkList = selectedTopic?.homeworkType ? 
    SEPARATE_TYPE_HOMEWORK.includes(selectedTopic.homeworkType) : 
    false;
    
  const allHomework = selectedTopic?.id ? homework[selectedTopic.id] : undefined;
  const ownerHomework = selectedTopic?.id ? homework[`${selectedTopic.id}-owner`] : undefined;
  const generalHomework = selectedTopic?.id ? homework[`${selectedTopic.id}-general`] : undefined;

  const convertHomework = (homework?: THomework[]): TTaskItemData[] => {
    return (
      homework?.map((homework) => ({
        id: homework.id,
        title: homework.title,
        type: homework.type,
        videoUrl: homework.videoLink ?? '',
        description: homework.description,
        remindAtFormat: homework.reminderAtFormat ? { ...homework.reminderAtFormat } : undefined,
        showPreviewImg: true,
        timezone: homework.timezone,
        createdAt: homework.createdAt,
        updatedAt: homework.updatedAt,
        category: homework.category,
      })) ?? []
    );
  };

  const renderMobileItem = (item: TTaskItemData) => {
    const isSelected = selectedHomeworkIds.includes(item.id);
    
    return (
        <div 
            className="HomeworkTasksColumn__item mobile"
            onClick={() => onClickHomework?.(item)}
        >
            <div className="HomeworkTasksColumn__item-content">
                <div className="HomeworkTasksColumn__item-left">
                    <Checkbox
                        checked={isSelected}
                        onClick={(e) => {
                            e.stopPropagation();
                            if (isSelected) {
                                onRemove?.(item);
                            } else {
                                // Simulate drag and drop
                                const fakeDropResult: DropResult = {
                                    draggableId: item.id,
                                    type: 'DEFAULT',
                                    source: {
                                        index: 0,
                                        droppableId: 'source'
                                    },
                                    destination: {
                                        index: 0,
                                        droppableId: EDragDropColumnId.ASSIGNED
                                    },
                                    mode: 'FLUID',
                                    reason: 'DROP',
                                    combine: null
                                };
                                onDragEnd(fakeDropResult);
                            }
                        }}
                    />
                    <BaseText type="body1">{item.title}</BaseText>
                </div>
            </div>
        </div>
    );
  };

  return (
    <div className={`HomeworkTasksColumn ${className ?? ''}`}>
      <BaseText type="title">{selectedTopic?.name}</BaseText>
      <SearchInput className="HomeworkTasksColumn__search" value={searchValue} onChange={onSearchHomework} />
      {!isMobile && (
        <BaseText type="caption" className="HomeworkTasksColumn__note">
          Drag to assign homework tasks to the customer
        </BaseText>
      )}

      {isSeparateHomeworkList && (
        <Collapse
          className="HomeworkTasksColumn__collapse"
          items={[
            {
              key: 'general',
              header: 'General tasks',
              content: isMobile ? (
                <div className="HomeworkTasksColumn__mobile-list">
                  {convertHomework(generalHomework?.data).map(item => renderMobileItem(item))}
                </div>
              ) : (
                <DragDropTasksColumn
                  className="HomeworkTasksColumn__list"
                  itemClassName="HomeworkTasksColumn__item"
                  columnId={EDragDropColumnId.GENERAL}
                  items={convertHomework(generalHomework?.data)}
                  selectedItemIds={selectedHomeworkIds}
                  hasMore={(generalHomework?.currentPage ?? 0) < (generalHomework?.totalPage ?? 0)}
                  onLoadMore={() => onLoadMore(Number(generalHomework?.currentPage) + 1, EDragDropColumnId.GENERAL)}
                  onClick={onClickHomework}
                />
              ),
            },
            {
              key: 'my owner',
              header: 'My own tasks',
              content: isMobile ? (
                <div className="HomeworkTasksColumn__mobile-list">
                  {convertHomework(ownerHomework?.data).map(item => renderMobileItem(item))}
                </div>
              ) : (
                <DragDropTasksColumn
                  className="HomeworkTasksColumn__list"
                  itemClassName="HomeworkTasksColumn__item"
                  columnId={EDragDropColumnId.OWN}
                  items={convertHomework(ownerHomework?.data)}
                  selectedItemIds={selectedHomeworkIds}
                  hasMore={(ownerHomework?.currentPage ?? 0) < (ownerHomework?.totalPage ?? 0)}
                  onLoadMore={() => onLoadMore(Number(ownerHomework?.currentPage) + 1, EDragDropColumnId.OWN)}
                  onClick={onClickHomework}
                />
              ),
            },
          ]}
        />
      )}

      {!isSeparateHomeworkList && (
        isMobile ? (
          <div className="HomeworkTasksColumn__mobile-list">
            {convertHomework(allHomework?.data).map(item => renderMobileItem(item))}
          </div>
        ) : (
          <DragDropTasksColumn
            className="HomeworkTasksColumn__list all"
            itemClassName="HomeworkTasksColumn__item"
            columnId={EDragDropColumnId.ALL}
            items={convertHomework(allHomework?.data)}
            selectedItemIds={selectedHomeworkIds}
            hasMore={(allHomework?.currentPage ?? 0) < (allHomework?.totalPage ?? 0)}
            onLoadMore={() => onLoadMore(Number(allHomework?.currentPage) + 1)}
            onClick={onClickHomework}
          />
        )
      )}
    </div>
  );
};

export default HomeworkTasksColumn;
