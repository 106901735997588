import React, { useState, useEffect, useRef, useCallback, useMemo, FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Input, Space, Typography, Modal, Tooltip, Spin, Tag, Drawer, Select, message, Card, Switch, Progress, List, AutoComplete, Form, Radio, Alert, DatePicker, Table, Divider, Checkbox, Collapse } from 'antd';
import type { SizeType } from 'antd/es/config-provider/SizeContext';
import { ArrowLeftOutlined, UserOutlined, AudioOutlined, AudioMutedOutlined, DeleteOutlined, InfoCircleOutlined, CheckCircleOutlined, FlagOutlined, EditOutlined, SaveOutlined, SettingOutlined, PieChartOutlined, ReloadOutlined, SmileOutlined, MehOutlined, FrownOutlined, ArrowRightOutlined, LoadingOutlined, FileTextOutlined, WarningOutlined, PlusOutlined, GlobalOutlined, TeamOutlined, LockOutlined, CloseOutlined, DownOutlined, UpOutlined, FilePdfOutlined, UploadOutlined, HistoryOutlined, QuestionCircleOutlined, SoundOutlined, UserSwitchOutlined, ClockCircleOutlined, CopyOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHatWizard, faWandSparkles } from '@fortawesome/free-solid-svg-icons';
import { RoutePaths } from 'src/routes/routes-constants';
import LiveTranscribeTab from '../clients/client-details-components/sessions/LiveTranscribeTab';
import useWindowSize from 'src/hooks/useWindowSize';
import { useSelector } from 'react-redux';
import { TRootState, useAppDispatch } from 'src/stores';
import { analyseTranscriptAction, getTemplatesAction, createTemplateAction, updateTemplateAction, deleteTemplateAction, generateDocumentFromTemplateAction, uploadTranscriptToClientAction, getPractitionerTranscriptsAction, getTranscriptDetailsAction, getTranscriptsAction, processAudioWithWhisperAction, deleteTranscriptsAction, WhisperTranscriptionResponse } from 'src/stores/clients/clients-actions';
import { TClient } from 'src/interfaces/clients-interface';
import { TCommonGetListParams } from 'src/interfaces/common-interface';
import { getClients } from 'src/stores/clients';
import debounce from 'lodash/debounce';
import { EUserProfile } from 'src/variables/storage';
import { EUserType } from 'src/variables/enum-variables';
import { unwrapResult } from '@reduxjs/toolkit';
import './LiveTranscribePage.scss';  // Add this import
import { exportToPDF } from 'src/utils/document-export';
import { format } from 'date-fns';
import { useDispatch } from 'react-redux';
import { Tour } from 'antd';
import type { TourProps } from 'antd';
import { ApiClient } from 'src/services/api-client';
import { getAccountSettingsAction, updateAccountSettingsAction } from 'src/stores/clients/clients-actions';

// Add with other constants at the top of the file
const MOBILE_BREAKPOINT = 768;
const SMALL_SCREEN_BREAKPOINT = 480;
const TEMPLATE_SELECTION_STEP = 14; // Index of the "Document Templates" step in the tour

interface Session {
  id: string;
  sessionId: string;
  recordingDate: string;
  duration: number;
  totalSegments: number;
  averageConfidence: number;
  createdAt: string;
  clientName?: string;
  clientId: string;
}

interface GeneratedDoc {
  id: string;
  content: string;
  name: string;
  isGenerated?: boolean;
  sessionId?: string;  // Add optional sessionId field
  duration?: number;  // Add optional duration field
}


interface TranscriptionSegment {
  id: string;
  speaker: string;
  text: string;
  start: number;
  end: number;
  confidence: number;
  sentiment: {
    label: 'positive' | 'negative' | 'neutral';
    score: number;
  };
}

interface ClientOption {
  value: string;
  label: string;
  id: string;
}

interface SessionSummary {
  keyPoints: string[];
  sentiment: {
    positive: number;
    neutral: number;
    negative: number;
  };
  suggestedFollowUp: string[];
}

// Define interfaces for quick notes
interface QuickNote {
  timestamp: number;
  note: string;
  type: 'important' | 'followup' | 'observation';
}

// Define a local interface that extends the API Template
interface UITemplate {
  id: string;
  name: string;
  description: string;
  icon: React.ReactNode;
  color: string;
  tags: string[];
  content: string;
  createdBy?: string;
  isPrivate?: boolean;
  clinicId?: string;
  usage_count: number;
}

// Add interface for active template
interface ActiveTemplate {
  id: string;
  name: string;
  content: string;
  variables: TemplateVariable[];
  usage_count: number;
  createdBy: string;
}

// Add new interface for template form
interface TemplateFormValues {
  name: string;
  description: string;
  content: string;
  isPrivate: 'public' | 'clinic' | 'private';
  icon?: string;
  color?: string;
  tags: string[];
}


// Base template interface for API responses
interface BaseTemplate {
  id: string;
  name: string;
  description: string;
  content: string;
  icon?: string;
  color?: string;
  tags?: string[];
  isPrivate?: boolean;
  clinicId?: string;
  createdBy: string;
  usage_count: number;
}

// Add new interfaces for variable mapping
interface TemplateVariable {
  name: string;
  value: string;
  type: 'custom';
  description: string;
}

interface SystemVariable {
  key: string;
  description: string;
  source: 'client' | 'profile' | 'clinic' | 'practitioner';
  path: string;
}

const { Title, Text } = Typography;
const { confirm } = Modal;
const { Option } = Select;

// Template notes
const DEFAULT_TEMPLATES = [
  { id: 'mood', text: 'Client mood appears ', category: 'observation' },
  { id: 'anxiety', text: 'Anxiety levels: ', category: 'observation' },
  { id: 'progress', text: 'Progress noted in ', category: 'important' },
  { id: 'followup', text: 'Schedule follow-up for ', category: 'followup' },
];

interface LiveTranscribeTabProps {
  clientId: string;
  clientName: string;
  isRecording: boolean;
  isPaused: boolean;
  onRecordingStateChange: (recording: boolean, initializing: boolean) => void;
  onPauseStateChange: (paused: boolean) => void;
  onProcessRecording: (audio: Blob) => void;
  onTranscriptStateChange: (hasTranscript: boolean) => void;
  onTranscriptUpdate: (segments: APITranscriptSegmentWithSentiment[]) => void;
  isGeneratingDocument?: boolean;
  generatedDocument?: string;
  generatedDocs: Array<{id: string; content: string; name: string; isGenerated?: boolean}>;
  activeTab: string;
  setActiveTab: (tab: string) => void;
  onCloseDocument: (docId: string) => void;
  customVariables?: TemplateVariable[];
  isTemplateVariablesCollapsed?: boolean;
  onSessionLoad?: (session: Session) => void;
  quickNotes?: QuickNote[];
  selectedTemplate?: ActiveTemplate | null;
  onTemplateClose?: () => void;
  renderTemplateVariables?: () => React.ReactNode;
  isAddingVariable?: boolean;
  setIsAddingVariable?: (isAdding: boolean) => void;
  newVariableName?: string;
  setNewVariableName?: (name: string) => void;
  newVariableValue?: string;
  setNewVariableValue?: (value: string) => void;
  handleAddCustomVariable?: () => void;
  onSegmentsChange?: (count: number) => void;
  segmentRefs?: {
    speakerLabelRef: React.RefObject<HTMLDivElement>;
    confidenceRef: React.RefObject<HTMLDivElement>;
    sentimentIconRef: React.RefObject<HTMLDivElement>;
    timestampRef: React.RefObject<HTMLDivElement>;
    editButtonRef: React.RefObject<HTMLDivElement>;
    deleteButtonRef?: React.RefObject<HTMLDivElement>;
    transcriptionSegmentRef?: React.RefObject<HTMLDivElement>;
    overallSentimentRef?: React.RefObject<HTMLDivElement>;
    selectedTemplateRef?: React.RefObject<HTMLDivElement>;
    generatedDocumentRef?: React.RefObject<HTMLDivElement>;
    piiSettingsRef?: React.RefObject<HTMLDivElement>;
  };
}

// Add new interface for API transcript segments with sentiment
interface APITranscriptSegmentWithSentiment {
  speaker: string;
  text: string;
  startTime: number;
  endTime: number;  // Add this field
  confidence: number;
  sentiment: {
    score: number;
    label: 'positive' | 'negative' | 'neutral';
  };
}

interface SessionFilters {
  page?: number;
  limit?: number;
  startDate?: string;
  endDate?: string;
  clientName?: string;
  sortBy?: 'recordingDate' | 'clientName' | 'duration';
  sortOrder?: 'ASC' | 'DESC';
}

interface LiveTranscribePageProps {
  onTranscriptUpdate?: (segments: APITranscriptSegmentWithSentiment[]) => void;
  onTranscriptStateChange?: (hasTranscript: boolean) => void;
}

// Default noop functions for optional props
const noopTranscriptUpdate = (segments: APITranscriptSegmentWithSentiment[]) => {
  // Optional callback - no default implementation needed
};

const noopTranscriptStateChange = (hasTranscript: boolean) => {
  // Optional callback - no default implementation needed
};

// Add this type declaration at the top of the file, after the imports
interface Window {
  webkitAudioContext: typeof AudioContext;
}

// Add these type declarations at the top of the file, after the imports
declare global {
  interface Window {
    webkitAudioContext: typeof AudioContext;
  }
}

// Add new interface for PII settings
interface PIISettings {
  enabled: boolean;
  redactTypes: {
    personalInfo: boolean;
    financialInfo: boolean;
    locationInfo: boolean;
    temporalInfo: boolean;
    identityInfo: boolean;
    otherInfo: boolean;
  };
}

// Add with other interfaces
interface WhisperSegment {
  speaker: string;
  text: string;
  start: number;
  end: number;
  confidence: number;
  sentiment?: {
    label: string;
    score: number;
  };
}

interface WhisperResponse {
  status: string;
  message?: string;
  segments: WhisperSegment[];
  text: string;
  id: string;
}

interface UploadResponse {
  status: string;
  id: string;
}

const LiveTranscribePage: FC<LiveTranscribePageProps> = ({
  onTranscriptUpdate = noopTranscriptUpdate,
  onTranscriptStateChange = noopTranscriptStateChange
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const profile = useSelector((state: TRootState) => state.user.profile);
  const [isProcessing, setIsProcessing] = useState(false);
  const [clientName, setClientName] = useState('');
  const [selectedClientId, setSelectedClientId] = useState<string | null>(null);
  const [isRecording, setIsRecording] = useState(false);
  const [isInitializing, setIsInitializing] = useState(false);
  const [hasExistingTranscript, setHasExistingTranscript] = useState(false);
  const [isGeneratingDocument, setIsGeneratingDocument] = useState(false);
  const { width } = useWindowSize();
  const [isSettingsVisible, setIsSettingsVisible] = useState(false);
  const [sessionSummary, setSessionSummary] = useState<SessionSummary | null>(null);
  const [isAnalyzing, setIsAnalyzing] = useState(false);
  const sessionIdRef = useRef<string | null>(null);
  const [clientSearchParams, setClientSearchParams] = useState<TCommonGetListParams>({
    page: 1,
    size: 10,
    keyword: '',
    status: 'ACTIVE,PENDING'
  });
  const [clientOptions, setClientOptions] = useState<ClientOption[]>([]);
  const [isLoadingClients, setIsLoadingClients] = useState(false);
  const [hasMoreClients, setHasMoreClients] = useState(true);
  const clientSearchRef = useRef<HTMLDivElement>(null);
  const [isClientLinked, setIsClientLinked] = useState(false);
  const [showTemplateModal, setShowTemplateModal] = useState(false);
  const [templateSearchQuery, setTemplateSearchQuery] = useState('');
  const [isLoadingTemplates, setIsLoadingTemplates] = useState(false);
  const [showCreateTemplate, setShowCreateTemplate] = useState(false);
  const [isCreatingTemplate, setIsCreatingTemplate] = useState(false);
  const [form] = Form.useForm<TemplateFormValues>();
  const [templateTypeFilter, setTemplateTypeFilter] = useState<'all' | 'private' | 'clinic' | 'public'>('all');
  const userRole = profile?.role || '';
  const [templateModalView, setTemplateModalView] = useState<'list' | 'create' | 'edit'>('list');
  const [activeTemplate, setActiveTemplate] = useState<ActiveTemplate | null>(null);
  const [detectedVariables, setDetectedVariables] = useState<TemplateVariable[]>([]);
  const [customVariables, setCustomVariables] = useState<TemplateVariable[]>([]);
  const [isAddingVariable, setIsAddingVariable] = useState(false);
  const [newVariableName, setNewVariableName] = useState('');
  const [newVariableValue, setNewVariableValue] = useState('');
  const [clients, setClients] = useState<TClient[]>([]);
  const [isTemplateVariablesCollapsed, setIsTemplateVariablesCollapsed] = useState(false);
  const [isEditingTemplate, setIsEditingTemplate] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [transcriptSegments, setTranscriptSegments] = useState<TranscriptionSegment[]>([]);
  const [apiTranscriptSegments, setApiTranscriptSegments] = useState<APITranscriptSegmentWithSentiment[]>([]);
  const [generatedDocument, setGeneratedDocument] = useState<string>('');
  const [generatedDocs, setGeneratedDocs] = useState<GeneratedDoc[]>([]);
  const [activeTab, setActiveTab] = useState<string>('transcript');
  const [templateContent, setTemplateContent] = useState('');
  const [templates, setTemplates] = useState<UITemplate[]>([]);
  const [selectedTemplate, setSelectedTemplate] = useState<UITemplate | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [hasUploaded, setHasUploaded] = useState(false);
  const [showSessionsDrawer, setShowSessionsDrawer] = useState(false);
  const [sessions, setSessions] = useState<Session[]>([]);
  const [sessionFilters, setSessionFilters] = useState<SessionFilters>({
    page: 1,
    limit: 10,
    startDate: undefined,
    endDate: undefined,
    clientName: '',
    sortBy: 'recordingDate',
    sortOrder: 'DESC'
  });
  const [totalSessions, setTotalSessions] = useState(0);
  const [loadingSessions, setLoadingSessions] = useState(false);
  const [transcriptionResults, setTranscriptionResults] = useState<TranscriptionSegment[]>([]);
  const [isTourOpen, setIsTourOpen] = useState(false);
  const [isTourEnabled, setIsTourEnabled] = useState(true);
  const [quickNotes, setQuickNotes] = useState<QuickNote[]>([]);
  const [segmentCount, setSegmentCount] = useState(0);
  const [isPaused, setIsPaused] = useState(false);
  const [recordedAudio, setRecordedAudio] = useState<Blob | null>(null);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const audioChunksRef = useRef<Blob[]>([]);

  // Add PII settings state
  const [piiSettings, setPiiSettings] = useState<PIISettings>({
    enabled: false,
    redactTypes: {
      personalInfo: false,
      financialInfo: false,
      locationInfo: false,
      temporalInfo: false,
      identityInfo: false,
      otherInfo: false
    }
  });

  // Load settings on mount
  useEffect(() => {
    const loadSettings = async () => {
      try {
        const settings = await dispatch(getAccountSettingsAction()).unwrap();
        setPiiSettings(settings.transcriptionSettings.pii);
      } catch (error) {
        console.error('Error loading settings:', error);
      }
    };
    loadSettings();
  }, [dispatch]);

  // Update the PII settings handlers to save to backend
  const handlePIIToggle = useCallback(async (checked: boolean) => {
    try {
      const newSettings = {
        ...piiSettings,
        enabled: checked
      };
      
      await dispatch(updateAccountSettingsAction({
        transcriptionSettings: {
          pii: newSettings
        }
      })).unwrap();
      
      setPiiSettings(newSettings);
      message.success('Settings updated successfully');
    } catch (error) {
      console.error('Error updating PII settings:', error);
      message.error('Failed to update settings');
    }
  }, [piiSettings, dispatch]);

  const handlePIITypeChange = useCallback(async (type: keyof PIISettings['redactTypes'], checked: boolean) => {
    try {
      const newSettings = {
        ...piiSettings,
        redactTypes: {
          ...piiSettings.redactTypes,
          [type]: checked
        }
      };
      
      await dispatch(updateAccountSettingsAction({
        transcriptionSettings: {
          pii: newSettings
        }
      })).unwrap();
      
      setPiiSettings(newSettings);
      message.success('Settings updated successfully');
    } catch (error) {
      console.error('Error updating PII settings:', error);
      message.error('Failed to update settings');
    }
  }, [piiSettings, dispatch]);

  // Memoize the width-dependent values
  const layoutConfig = useMemo(() => ({
    isMobile: width <= MOBILE_BREAKPOINT,
    isVerySmallScreen: width <= SMALL_SCREEN_BREAKPOINT,
    inputWidth: width <= 960 ? '100%' : '240px',
    inputFlex: width <= 960 ? '1 1 auto' : '0 0 auto'
  }), [width]);

  const { isMobile, isVerySmallScreen, inputWidth, inputFlex } = layoutConfig;

  const handleBack = () => {
    navigate(RoutePaths.HOME);
  };

  // Add this before the renderRecordButton function
  const handleUploadTranscript = async () => {
    if (!selectedClientId || !hasExistingTranscript || !apiTranscriptSegments.length || hasUploaded) {
      return;
    }

    try {
      const loadingMessage = message.loading('Uploading transcript to client...', 0);
      
      // Calculate total duration from the last segment
      const totalDuration = apiTranscriptSegments.length > 0 
        ? Math.ceil(apiTranscriptSegments[apiTranscriptSegments.length - 1].endTime)
        : 0;
      
      const result = await dispatch(uploadTranscriptToClientAction({
        clientId: selectedClientId,
        transcript: {
          segments: apiTranscriptSegments,
          rawText: apiTranscriptSegments.map(s => s.text).join(' ')
        },
        metadata: {
          recordingDate: new Date().toISOString(),
          totalSegments: apiTranscriptSegments.length,
          averageConfidence: apiTranscriptSegments.reduce((acc, seg) => acc + (seg.confidence || 0), 0) / apiTranscriptSegments.length,
          duration: totalDuration
        }
      })).unwrap();

      // Close loading message
      loadingMessage();

      if (result.status === 'success') {
        message.success('Transcript uploaded successfully');
        setHasUploaded(true);

        // Format the transcript content similar to session history
        const formattedContent = apiTranscriptSegments.map((segment, index) => {
          const startTime = segment.startTime;
          const endTime = segment.endTime;
          
          // Format timestamps as MM:SS
          const formatTimeMMSS = (timeInSeconds: number) => {
            const minutes = Math.floor(timeInSeconds / 60);
            const seconds = Math.floor(timeInSeconds % 60);
            return `${minutes}:${seconds.toString().padStart(2, '0')}`;
          };

          const startTimeStr = formatTimeMMSS(startTime);
          const endTimeStr = formatTimeMMSS(endTime);
          
          const confidenceFormatted = Math.round(segment.confidence * 100);
          const sentimentFormatted = Math.round(Math.abs(segment.sentiment.score) * 100);
          
          return `[ID:${result.id}-${index}] [${segment.speaker}] [CONF ${confidenceFormatted}%] [${segment.sentiment.label.toUpperCase()} ${sentimentFormatted}%] [${startTimeStr} - ${endTimeStr}]: ${segment.text}`;
        }).join('\n\n');

        // Create a new document for the saved transcript
        const savedDoc: GeneratedDoc = {
          id: `session-${result.id}`,
          sessionId: result.id,
          name: `Session - ${format(new Date(), 'MMM dd, yyyy HH:mm')}`,
          content: formattedContent,
          isGenerated: false,
          duration: totalDuration
        };

        // Add the document and switch to its tab
        setGeneratedDocs(prev => [...prev, savedDoc]);
        setActiveTab(`doc-${savedDoc.id}`);
      } else {
        message.error('Failed to upload transcript');
      }
    } catch (error) {
      console.error('Error uploading transcript:', error);
      message.error('Failed to upload transcript');
    }
  };

  // Add current step state
  const [currentTourStep, setCurrentTourStep] = useState(0);

  // Add new state for microphone permissions
  const [hasMicrophonePermission, setHasMicrophonePermission] = useState<boolean | null>(null);
  const [isCheckingPermissions, setIsCheckingPermissions] = useState(true);

  // Add function to check microphone permissions
  const checkMicrophonePermissions = useCallback(async () => {
    try {
      setIsCheckingPermissions(true);
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      // Stop the stream immediately after getting permission
      stream.getTracks().forEach(track => track.stop());
      setHasMicrophonePermission(true);
    } catch (error) {
      console.error('Microphone permission error:', error);
      setHasMicrophonePermission(false);
    } finally {
      setIsCheckingPermissions(false);
    }
  }, []);

  // Add effect to check permissions on mount
  useEffect(() => {
    checkMicrophonePermissions();
  }, [checkMicrophonePermissions]);

  // Define recording toggle handler
  const handleRecordingToggle = useCallback(async () => {
    if (!isRecording && !hasMicrophonePermission) {
      await checkMicrophonePermissions();
      return;
    }

    if (isRecording) {
      // Stop recording
      if (mediaRecorderRef.current) {
        try {
          mediaRecorderRef.current.stop();
          // Stop all tracks
          mediaRecorderRef.current.stream.getTracks().forEach(track => track.stop());
          setIsRecording(false);
          setIsPaused(false);
        } catch (error: unknown) {
          console.error('Error stopping recording:', error);
          const errorMessage = error instanceof Error ? error.message : 'Unknown error';
          message.error('Failed to stop recording: ' + errorMessage);
        }
      }
    } else {
      try {
        // Reset any existing recorded audio
        setRecordedAudio(null);
        audioChunksRef.current = [];

        const stream = await navigator.mediaDevices.getUserMedia({ 
          audio: {
            channelCount: 1,
            sampleRate: 48000,
            echoCancellation: true,
            noiseSuppression: true,
            autoGainControl: true
          } 
        });

        // Determine supported mime type
        const mimeType = MediaRecorder.isTypeSupported('audio/webm;codecs=opus') 
          ? 'audio/webm;codecs=opus'
          : MediaRecorder.isTypeSupported('audio/webm') 
            ? 'audio/webm'
            : 'audio/mp4';

        console.log('Using mime type:', mimeType);

        const mediaRecorder = new MediaRecorder(stream, {
          mimeType,
          audioBitsPerSecond: 128000
        });
        
        mediaRecorderRef.current = mediaRecorder;

        // Handle data available event
        mediaRecorder.ondataavailable = (event) => {
          if (event.data.size > 0) {
            console.log('Received audio chunk:', event.data.size, 'bytes');
            audioChunksRef.current.push(event.data);
          }
        };

        // Handle recording stop
        mediaRecorder.onstop = () => {
          try {
            console.log('Recording stopped, processing chunks...');
            console.log('Total chunks:', audioChunksRef.current.length);
            
            if (audioChunksRef.current.length > 0) {
              const audioBlob = new Blob(audioChunksRef.current, { type: mimeType });
              console.log('Created audio blob:', audioBlob.size, 'bytes');
              setRecordedAudio(audioBlob);
              setHasExistingTranscript(true);
            } else {
              throw new Error('No audio data recorded');
            }
          } catch (error: unknown) {
            console.error('Error in onstop handler:', error);
            const errorMessage = error instanceof Error ? error.message : 'Unknown error';
            message.error('Failed to process recorded audio: ' + errorMessage);
          }
        };

        // Handle recording errors
        mediaRecorder.onerror = (event) => {
          console.error('MediaRecorder error:', event);
          message.error('Recording error occurred');
        };

        // Start recording - collect data every second
        mediaRecorder.start(1000);
        console.log('Started recording with mime type:', mimeType);
        setIsRecording(true);

        // Tour handling
        if (isTourOpen && currentTourStep === 2) {
          setTimeout(() => setCurrentTourStep(prev => prev + 1), 500);
        }
      } catch (error: unknown) {
        console.error('Error starting recording:', error);
        const errorMessage = error instanceof Error ? error.message : 'Unknown error';
        message.error('Failed to start recording: ' + errorMessage);
      }
    }

    // Tour handling for stop
    if (isRecording && isTourOpen && currentTourStep === 4) {
      setTimeout(() => setCurrentTourStep(prev => prev + 1), 500);
    }
  }, [isRecording, hasMicrophonePermission, checkMicrophonePermissions, isTourOpen, currentTourStep]);

  // Add pause/resume handler
  const handlePauseToggle = useCallback((paused: boolean) => {
    if (!mediaRecorderRef.current || mediaRecorderRef.current.state === 'inactive') return;

    try {
      if (paused) {
        mediaRecorderRef.current.pause();
        console.log('Recording paused');
      } else {
        mediaRecorderRef.current.resume();
        console.log('Recording resumed');
      }
      setIsPaused(paused);
    } catch (error: unknown) {
      console.error('Error toggling pause state:', error);
      const errorMessage = error instanceof Error ? error.message : 'Unknown error';
      message.error('Failed to ' + (paused ? 'pause' : 'resume') + ' recording: ' + errorMessage);
    }
  }, []);

  // Add cleanup effect
  useEffect(() => {
    return () => {
      if (mediaRecorderRef.current) {
        mediaRecorderRef.current.stream.getTracks().forEach(track => track.stop());
      }
    };
  }, []);

  // Add process recording handler
  const handleProcessRecording = async (audioBlob: Blob) => {
    try {
      setIsProcessing(true);
      const formData = new FormData();
      formData.append('file', audioBlob);
      formData.append('recordingDate', new Date().toISOString());

      if (isClientLinked && selectedClientId) {
        formData.append('clientId', selectedClientId);
      }
      if (clientName) {
        formData.append('clientName', clientName);
      }

      // Add PII settings to the request
      const response = await dispatch(processAudioWithWhisperAction({
        formData,
        piiSettings: piiSettings.enabled ? {
          enabled: true,
          redactTypes: piiSettings.redactTypes
        } : undefined
      })).unwrap();

      if (response.status === 'error') {
        throw new Error(response.message || 'Failed to transcribe audio');
      }

      const averageConfidence = response.segments.reduce(
        (acc: number, seg: WhisperSegment) => acc + (seg.confidence || 0),
        0
      ) / response.segments.length;

      setIsProcessing(false);
      setHasUploaded(false);

      // Format and set the API transcript segments
      const formattedSegments = response.segments.map((seg, index, array) => {
        const startTime = seg.start || 0;
        // For end time, use either:
        // 1. The start time of the next segment (if there is one)
        // 2. The current segment's end time
        // 3. The current segment's start time plus a small buffer
        const endTime = index < array.length - 1 
          ? (array[index + 1].start || startTime + 2) // Use next segment's start time if available
          : (seg.end || startTime + 2); // For last segment, use its end time or add buffer

        return {
          speaker: seg.speaker || 'Unknown Speaker',
          text: seg.text,
          startTime,
          endTime,
          confidence: seg.confidence || 0,
          sentiment: {
            label: (seg.sentiment?.label as 'positive' | 'negative' | 'neutral') || 'neutral',
            score: seg.sentiment?.score || 0.5
          }
        };
      });

      // Calculate total duration from the last segment's end time
      const totalDuration = formattedSegments.length > 0 
        ? Math.ceil(formattedSegments[formattedSegments.length - 1].endTime)
        : 0;

      setApiTranscriptSegments(formattedSegments);
      onTranscriptUpdate(formattedSegments);
      
      // Create a new document with the transcription
      const transcriptDoc: GeneratedDoc = {
        id: response.id,
        name: `Transcript - ${format(new Date(), 'MMM dd, yyyy HH:mm')}`,
        content: formattedSegments.map((seg, index) => {
          const speaker = seg.speaker;
          const confidence = Math.round(seg.confidence * 100);
          const sentimentLabel = seg.sentiment.label.toUpperCase();
          const sentimentScore = Math.round(Math.abs(seg.sentiment.score) * 100);
          
          // Format time range
          const startMinutes = Math.floor(seg.startTime / 60);
          const startSeconds = Math.floor(seg.startTime % 60);
          const endMinutes = Math.floor(seg.endTime / 60);
          const endSeconds = Math.floor(seg.endTime % 60);
          const formattedTimeRange = `${startMinutes}:${startSeconds.toString().padStart(2, '0')} - ${endMinutes}:${endSeconds.toString().padStart(2, '0')}`;
          // Include segment ID in the formatted string
          return `[ID:segment-${response.id}-${index}] [${speaker}] [CONF ${confidence}%] [${sentimentLabel} ${sentimentScore}%] [${formattedTimeRange}]: ${seg.text}`;
        }).join('\n\n'),
        isGenerated: false,
        duration: totalDuration,
        sessionId: response.id
      };

      setGeneratedDocs(prev => [...prev, transcriptDoc]);
      setActiveTab(`doc-${transcriptDoc.id}`);
      onTranscriptStateChange(true);
      setHasExistingTranscript(true);

      // Update custom variables if needed
      const detectedVars = extractVariablesFromText(formattedSegments.map(s => s.text).join(' '));
      if (detectedVars.length > 0) {
        const newVariables = detectedVars
          .filter(v => !customVariables.some(cv => cv.name === v.name));

        setCustomVariables(prev => [...prev, ...newVariables]);
        setDetectedVariables(detectedVars);
      }
    } catch (error) {
      console.error('Error processing audio:', error);
      message.error('Failed to process audio');
    } finally {
      setIsProcessing(false);
    }
  };

  // Add function to detect variables in template content
  const detectTemplateVariables = useCallback((content: string) => {
    const variableRegex = /\{\{([^}]+)\}\}/g;
    const matches = Array.from(content.matchAll(variableRegex));
    const variables: TemplateVariable[] = [];

    matches.forEach(match => {
      const varName = match[1].trim();
      // Check if we already have this custom variable
      const existingCustomVar = customVariables.find(v => v.name === varName);
      if (existingCustomVar) {
        variables.push(existingCustomVar);
      } else {
        variables.push({
          name: varName,
          value: '',
          type: 'custom',
          description: 'Custom variable'
        });
      }
    });

    setDetectedVariables(variables);
    return variables;
  }, [customVariables]);

  // Update the handleDocumentTemplateSelect function
  const handleDocumentTemplateSelect = useCallback((template: UITemplate) => {
    // Set the active template
    setActiveTemplate({
      id: template.id,
      name: template.name,
      content: template.content,
      variables: detectTemplateVariables(template.content),
      usage_count: template.usage_count,
      createdBy: template.createdBy || ''
    });

    // Close the modal and reset view
    setShowTemplateModal(false);
    setTemplateModalView('list');
    setSelectedTemplate(null);
  }, [detectTemplateVariables]);

  // Add template close handler
  const handleTemplateClose = useCallback(() => {
    setActiveTemplate(null);
  }, []);

  // Settings drawer content
  const renderSettingsContent = () => (
    <div className="settings-content">
      {/* Existing settings */}
      <Divider orientation="left">Privacy Settings</Divider>
      <Form layout="vertical">
        <Form.Item>
          <Switch
            checked={piiSettings.enabled}
            onChange={handlePIIToggle}
          />
          <span style={{ marginLeft: '8px' }}>Redact Personal Information (PII)</span>
        </Form.Item>
        
        <div style={{ marginLeft: '24px', opacity: piiSettings.enabled ? 1 : 0.5, pointerEvents: piiSettings.enabled ? 'auto' : 'none' }}>
          <Collapse
            ghost
            expandIconPosition="end"
            defaultActiveKey={['1']}
          >
            <Collapse.Panel header="Redaction Options" key="1">
              <Space direction="vertical" style={{ width: '100%' }}>
                <Checkbox
                  checked={piiSettings.redactTypes.personalInfo}
                  onChange={(e) => handlePIITypeChange('personalInfo', e.target.checked)}
                  disabled={!piiSettings.enabled}
                >
                  Personal Information
                  <div className="text-secondary" style={{ fontSize: '12px' }}>
                    Phone numbers, emails, SSN
                  </div>
                </Checkbox>

                <Checkbox
                  checked={piiSettings.redactTypes.financialInfo}
                  onChange={(e) => handlePIITypeChange('financialInfo', e.target.checked)}
                  disabled={!piiSettings.enabled}
                >
                  Financial Information
                  <div className="text-secondary" style={{ fontSize: '12px' }}>
                    Credit card numbers, bank accounts
                  </div>
                </Checkbox>

                <Checkbox
                  checked={piiSettings.redactTypes.locationInfo}
                  onChange={(e) => handlePIITypeChange('locationInfo', e.target.checked)}
                  disabled={!piiSettings.enabled}
                >
                  Location Information
                  <div className="text-secondary" style={{ fontSize: '12px' }}>
                    Addresses, IP addresses, locations
                  </div>
                </Checkbox>

                <Checkbox
                  checked={piiSettings.redactTypes.identityInfo}
                  onChange={(e) => handlePIITypeChange('identityInfo', e.target.checked)}
                  disabled={!piiSettings.enabled}
                >
                  Identity Information
                  <div className="text-secondary" style={{ fontSize: '12px' }}>
                    Names of people and organizations
                  </div>
                </Checkbox>

                <Checkbox
                  checked={piiSettings.redactTypes.temporalInfo}
                  onChange={(e) => handlePIITypeChange('temporalInfo', e.target.checked)}
                  disabled={!piiSettings.enabled}
                >
                  Temporal Information
                  <div className="text-secondary" style={{ fontSize: '12px' }}>
                    Dates and times
                  </div>
                </Checkbox>

                <Checkbox
                  checked={piiSettings.redactTypes.otherInfo}
                  onChange={(e) => handlePIITypeChange('otherInfo', e.target.checked)}
                  disabled={!piiSettings.enabled}
                >
                  Other Information
                  <div className="text-secondary" style={{ fontSize: '12px' }}>
                    Products, events, URLs
                  </div>
                </Checkbox>
              </Space>
            </Collapse.Panel>
          </Collapse>
        </div>
      </Form>
    </div>
  );

  // Render settings button
  const renderSettingsButton = () => (
    <Tooltip title="Settings">
      <Button
        type={isSettingsVisible ? 'primary' : 'default'}
        icon={<SettingOutlined />}
        onClick={() => setIsSettingsVisible(true)}
        style={{ 
          height: '36px',
          borderRadius: '6px',
          padding: isVerySmallScreen ? '8px' : '0 16px',
          fontSize: '14px',
          display: 'flex',
          alignItems: 'center',
          gap: 8,
          minWidth: isVerySmallScreen ? '36px' : 'auto',
          justifyContent: 'center'
        }}
      >
        {!isVerySmallScreen && 'Settings'}
      </Button>
    </Tooltip>
  );

  // Memoize the analyze session callback
  const analyzeSession = useCallback(async () => {
    if (!hasExistingTranscript || !selectedClientId) return;

    try {
      setIsAnalyzing(true);
      const result = await dispatch(analyseTranscriptAction({
        transcriptId: sessionIdRef.current || '',
        clientId: selectedClientId
      })).unwrap();

      setSessionSummary(result);
    } catch (error) {
      message.error('Failed to analyze session');
    } finally {
      setIsAnalyzing(false);
    }
  }, [dispatch, hasExistingTranscript, selectedClientId]);

  // Add this effect to handle infinite scroll
  useEffect(() => {
    const handleScroll = (e: Event) => {
      const element = e.target as HTMLDivElement;
      if (
        element.scrollHeight - element.scrollTop === element.clientHeight &&
        !isLoadingClients &&
        hasMoreClients
      ) {
        setClientSearchParams(prev => ({
          ...prev,
          page: prev.page + 1
        }));
      }
    };

    const currentRef = clientSearchRef.current;
    if (currentRef) {
      currentRef.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (currentRef) {
        currentRef.removeEventListener('scroll', handleScroll);
      }
    };
  }, [isLoadingClients, hasMoreClients]);

  // Add client search functionality
  const searchClients = useCallback(
    debounce(async (searchText: string) => {
      setIsLoadingClients(true);
      try {
        const params: TCommonGetListParams = {
          ...clientSearchParams,
          page: 1,
          keyword: searchText,
          status: 'ACTIVE,PENDING'
        };
        
        const result = await dispatch(getClients(params)).unwrap();
        
        const options = result.data.map((client: TClient) => ({
          value: `${client.firstName} ${client.lastName}`.trim(),
          label: `${client.firstName} ${client.lastName}${client.email ? ` (${client.email})` : ''}`.trim(),
          id: client.clientId
        }));

        setClientOptions(options);
        setHasMoreClients(result.data.length === params.size);
        setClientSearchParams(params);
      } catch (error) {
        console.error('Error searching clients:', error);
      } finally {
        setIsLoadingClients(false);
      }
    }, 300),
    [dispatch, clientSearchParams]
  );

  // Add load more clients functionality
  const loadMoreClients = useCallback(async () => {
    if (isLoadingClients || !hasMoreClients) return;
    
    setIsLoadingClients(true);
    try {
      const result = await dispatch(getClients(clientSearchParams)).unwrap();
      
      const newOptions = result.data.map((client: TClient) => ({
        value: `${client.firstName} ${client.lastName}`.trim(),
        label: `${client.firstName} ${client.lastName}${client.email ? ` (${client.email})` : ''}`.trim(),
        id: client.clientId
      }));

      setClientOptions(prev => [...prev, ...newOptions]);
      setHasMoreClients(result.data.length === clientSearchParams.size);
    } catch (error) {
      console.error('Error loading more clients:', error);
    } finally {
      setIsLoadingClients(false);
    }
  }, [dispatch, clientSearchParams, isLoadingClients, hasMoreClients]);

  // Update the clientName variable handling
  const handleClientNameChange = (value: string) => {
    setClientName(value);
    setIsClientLinked(false);
    setHasUploaded(false); // Reset upload state
    setApiTranscriptSegments([]); // Reset transcript segments
    searchClients(value);
    setActiveTemplate(null);
    setGeneratedDocs([]);
    setSelectedTemplate(null);
    
    // Update custom variables if clientName exists
    const clientNameVar: TemplateVariable = {
      name: 'clientName',
      value: value || '(No client selected)',
      type: 'custom',
      description: 'Client name'
    };

    setCustomVariables(prev => {
      const updated = prev.filter(v => v.name !== 'clientName');
      return [...updated, clientNameVar];
    });
  };

  // Update the client selection handler
  const handleClientSelect = (value: string, option: ClientOption) => {
    setClientName(value);
    setSelectedClientId(option.id);
    setIsClientLinked(true);
    setApiTranscriptSegments([]); // Reset transcript segments
    
    // Update custom variables if clientName exists
    const clientNameVar: TemplateVariable = {
      name: 'clientName',
      value: value,
      type: 'custom',
      description: 'Client name'
    };

    setCustomVariables(prev => {
      const updated = prev.filter(v => v.name !== 'clientName');
      return [...updated, clientNameVar];
    });
  };

  // Replace the existing renderClientInput function
  const renderClientInput = useCallback(() => (
    <div className="client-input-wrapper" style={{ 
      display: 'flex', 
      alignItems: 'center', 
      gap: '8px',
      width: '100%',
      flexWrap: 'wrap'
    }}>
      <AutoComplete<string, ClientOption>
        value={clientName}
        onChange={handleClientNameChange}
        onSelect={handleClientSelect}
        options={clientOptions}
        style={{
          flex: '1 1 200px',
          minWidth: '200px'
        }}
        className="client-search-input"
        placeholder="Search or enter client name..."
        allowClear
        showSearch
        size="large"
        notFoundContent={
          isLoadingClients ? (
            <div style={{ padding: '8px', textAlign: 'center' }}>
              <Spin size="small" />
            </div>
          ) : clientOptions.length === 0 ? (
            <div style={{ padding: '8px', textAlign: 'center', color: '#999' }}>
              No existing clients found
            </div>
          ) : null
        }
        dropdownRender={(menu) => (
          <div style={{ maxHeight: '300px', overflow: 'auto' }}>
            {menu}
            {hasMoreClients && (
              <div style={{ textAlign: 'center', padding: '8px' }}>
                <Spin spinning={isLoadingClients} size="small" />
              </div>
            )}
          </div>
        )}
      />

      <style>
        {`
          .client-search-input.ant-select-lg .ant-select-selector {
            height: 40px !important;
          }
          .client-search-input.ant-select-lg .ant-select-selection-search-input {
            height: 38px !important;
            line-height: 38px !important;
          }
          .client-search-input.ant-select-lg .ant-select-selection-placeholder {
            line-height: 38px !important;
          }
          .client-search-input.ant-select-lg .ant-select-selection-item {
            line-height: 38px !important;
          }
        `}
      </style>
      {clientName && (
        <Tag 
          color={isClientLinked ? '#52c41a' : '#8c8c8c'} 
          icon={isClientLinked ? <CheckCircleOutlined /> : <InfoCircleOutlined />}
          style={{ 
            margin: 0,
            flexShrink: 0,
            whiteSpace: 'nowrap',
            maxWidth: '100%',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            height: '40px',
            lineHeight: '38px',
            padding: '0 11px',
            display: 'flex',
            alignItems: 'center',
            gap: '6px'
          }}
        >
          {isClientLinked ? 'Client Linked' : 'Manual Entry'}
        </Tag>
      )}
    </div>
  ), [clientName, clientOptions, isLoadingClients, hasMoreClients, isClientLinked, handleClientNameChange, handleClientSelect]);

  // Add this effect to perform initial client search
  useEffect(() => {
    const loadInitialClients = async () => {
      setIsLoadingClients(true);
      try {
        const initialParams: TCommonGetListParams = {
          page: 1,
          size: 10,
          keyword: '',
          status: 'ACTIVE,PENDING'
        };
        
        const result = await dispatch(getClients(initialParams)).unwrap();
        
        const options = result.data.map((client: TClient) => ({
          value: `${client.firstName} ${client.lastName}`.trim(),
          label: `${client.firstName} ${client.lastName}${client.email ? ` (${client.email})` : ''}`.trim(),
          id: client.clientId
        }));

        setClientOptions(options);
        setHasMoreClients(result.data.length === initialParams.size);
        setClientSearchParams(initialParams);
      } catch (error) {
        console.error('Error loading initial clients:', error);
      } finally {
        setIsLoadingClients(false);
      }
    };

    loadInitialClients();
  }, []); // Run once when component mounts

  // Update template content when selected template changes
  useEffect(() => {
    if (selectedTemplate) {
      setTemplateContent(selectedTemplate.content);
    } else {
      setTemplateContent('');
    }
  }, [selectedTemplate]);  // Remove activeTab from dependencies

  // Add this at the top level of the component
  const setActiveTabCallback = useCallback((tab: string) => {
    if (tab !== activeTab) {  // Only update if the tab is actually changing
      setActiveTab(tab);
    }
  }, [activeTab]); // Include activeTab in dependencies since we're using it

  // Update the templates effect to avoid unnecessary updates
  useEffect(() => {
    const fetchTemplates = async () => {
      if (!showTemplateModal) return;
      
      setIsLoadingTemplates(true);
      try {
        const templates = await dispatch(getTemplatesAction({})).unwrap();
        const apiTemplates: UITemplate[] = templates
          .filter((template: BaseTemplate) => template.content)
          .map((template: BaseTemplate) => ({
            id: template.id,
            name: template.name,
            description: template.description,
            content: template.content!,
            icon: <FileTextOutlined />,
            color: template.color || '#1890ff',
            tags: template.tags || [],
            isPrivate: template.isPrivate,
            clinicId: template.clinicId,
            createdBy: template.createdBy,
            usage_count: template.usage_count
          }));

        setTemplates(apiTemplates);
      } catch (error) {
        message.error('Failed to load templates');
      } finally {
        setIsLoadingTemplates(false);
      }
    };

    fetchTemplates();
  }, [showTemplateModal, dispatch]); // Keep minimal dependencies

  // Add template creation handler
  const handleCreateTemplate = async (values: TemplateFormValues) => {
    try {
      setIsCreatingTemplate(true);
      
      const profileId = localStorage.getItem(EUserProfile.PROFILE_ID);
      const templateData = {
        ...values,
        isPrivate: values.isPrivate !== 'public',
        clinicId: values.isPrivate === 'clinic' ? profileId || undefined : undefined,
        createdBy: profileId || '',
        icon: 'FileTextOutlined', // Default icon
        color: '#1890ff', // Default color
      };

      await dispatch(createTemplateAction(templateData)).unwrap();
      message.success('Template created successfully');

      // Reset form and view state
      setTemplateModalView('list');
      setShowCreateTemplate(false);
      form.resetFields();
      
      // Refresh templates list
      setIsLoadingTemplates(true);
      try {
        const refreshedTemplates = await dispatch(getTemplatesAction({})).unwrap();
        
        // Convert API templates to UI templates
        const uiTemplates: UITemplate[] = refreshedTemplates.map((template: BaseTemplate) => ({
          id: template.id,
          name: template.name,
          description: template.description,
          content: template.content,
          icon: <FileTextOutlined />,
          color: template.color || '#1890ff',
          tags: template.tags || [],
          isPrivate: template.isPrivate,
          clinicId: template.clinicId,
          createdBy: template.createdBy,
          usage_count: template.usage_count
        }));
        
        // Update both templates and filtered templates
        setTemplates(uiTemplates);
      } catch (error) {
        message.error('Failed to refresh templates list');
        console.error('Error refreshing templates:', error);
      } finally {
        setIsLoadingTemplates(false);
      }
    } catch (error) {
      message.error('Failed to create template');
      console.error('Error creating template:', error);
    } finally {
      setIsCreatingTemplate(false);
    }
  };

  // Add function to handle variable value changes
  const handleVariableValueChange = (varName: string, value: string) => {
    setCustomVariables(prev => {
      const updated = [...prev];
      const index = updated.findIndex(v => v.name === varName);
      if (index >= 0) {
        updated[index] = { ...updated[index], value };
      } else {
        updated.push({
          name: varName,
          value,
          type: 'custom',
          description: 'Custom variable'
        });
      }
      return updated;
    });
  };

  // Add function to add new custom variable
  const handleAddCustomVariable = () => {
    if (newVariableName && newVariableValue) {
      // Implementation for adding custom variables
    }
  };

  // Update the getVariablePreviewValue function
  const getVariablePreviewValue = (variable: TemplateVariable) => {
    return customVariables.find(v => v.name === variable.name)?.value || '';
  };

  // Update the getPreviewContent function
  const getPreviewContent = (content: string) => {
    return content.replace(/\{\{([^}]+)\}\}/g, (match, varName) => {
      const variable = detectedVariables.find(v => v.name === varName.trim());
      return variable ? getVariablePreviewValue(variable) : match;
    });
  };

  // Update the template preview section in renderTemplateModal
  const renderTemplatePreview = () => {
    if (!selectedTemplate) return null;

    const isOwnTemplate = selectedTemplate.createdBy === localStorage.getItem(EUserProfile.PROFILE_ID);

    const handleEditClick = () => {
      setIsEditingTemplate(true);
      form.setFieldsValue({
        name: selectedTemplate.name,
        description: selectedTemplate.description,
        content: selectedTemplate.content,
        isPrivate: selectedTemplate.clinicId ? 'clinic' : (selectedTemplate.isPrivate ? 'private' : 'public'),
        tags: selectedTemplate.tags || []
      });
    };

    const handleEditSubmit = async (values: TemplateFormValues) => {
      try {
        setIsCreatingTemplate(true);
        await dispatch(updateTemplateAction({
          id: selectedTemplate.id,
          template: {
            ...values,
            isPrivate: values.isPrivate !== 'public',
            clinicId: values.isPrivate === 'clinic' ? profile?.id || undefined : undefined,
          }
        })).unwrap();
        
        message.success('Template updated successfully');
        setIsEditingTemplate(false);
        
        // Refresh templates list
        setIsLoadingTemplates(true);
        try {
          const refreshedTemplates = await dispatch(getTemplatesAction({})).unwrap();
          const uiTemplates: UITemplate[] = refreshedTemplates.map((template: BaseTemplate) => ({
            id: template.id,
            name: template.name,
            description: template.description,
            content: template.content,
            icon: <FileTextOutlined />,
            color: template.color || '#1890ff',
            tags: template.tags || [],
            isPrivate: template.isPrivate,
            clinicId: template.clinicId,
            createdBy: template.createdBy,
            usage_count: template.usage_count
          }));
          setTemplates(uiTemplates);
          
          // Update selected template
          const updatedTemplate = uiTemplates.find(t => t.id === selectedTemplate.id);
          if (updatedTemplate) {
            setSelectedTemplate(updatedTemplate);
          }
        } catch (error) {
          message.error('Failed to refresh templates list');
        } finally {
          setIsLoadingTemplates(false);
        }
      } catch (error) {
        message.error('Failed to update template');
      } finally {
        setIsCreatingTemplate(false);
      }
    };

    const handleDeleteClick = () => {
      setShowDeleteConfirm(true);
    };

    const handleDeleteConfirm = async () => {
      try {
        setIsDeleting(true);
        await dispatch(deleteTemplateAction(selectedTemplate.id)).unwrap();
        message.success('Template deleted successfully');
        setShowDeleteConfirm(false);
        setTemplateModalView('list');
        setSelectedTemplate(null);
        
        // Refresh templates list
        setIsLoadingTemplates(true);
        try {
          const refreshedTemplates = await dispatch(getTemplatesAction({})).unwrap();
          const uiTemplates: UITemplate[] = refreshedTemplates.map((template: BaseTemplate) => ({
            id: template.id,
            name: template.name,
            description: template.description,
            content: template.content,
            icon: <FileTextOutlined />,
            color: template.color || '#1890ff',
            tags: template.tags || [],
            isPrivate: template.isPrivate,
            clinicId: template.clinicId,
            createdBy: template.createdBy,
            usage_count: template.usage_count
          }));
          setTemplates(uiTemplates);
        } catch (error) {
          message.error('Failed to refresh templates list');
        } finally {
          setIsLoadingTemplates(false);
        }
      } catch (error) {
        message.error('Failed to delete template');
      } finally {
        setIsDeleting(false);
      }
    };

    return (
      <Space direction="vertical" size={24} style={{ width: '100%' }}>
        {/* Template Header */}
        <div style={{ 
          display: 'flex', 
          alignItems: 'flex-start',
          gap: 16 
        }}>
          <div style={{
            width: 48,
            height: 48,
            borderRadius: '8px',
            background: selectedTemplate.color || '#1890ff',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: '#fff',
            fontSize: '24px'
          }}>
            {selectedTemplate.icon}
          </div>
          <div style={{ flex: 1 }}>
            <Title level={4} style={{ margin: 0 }}>{selectedTemplate.name}</Title>
            <Text type="secondary">{selectedTemplate.description}</Text>
            <div style={{ marginTop: 8 }}>
              <Space wrap size={[0, 8]}>
                {selectedTemplate.tags?.map((tag, index) => (
                  <Tag key={index} color="blue">{tag}</Tag>
                ))}
                {selectedTemplate.clinicId ? (
                  <Tag icon={<TeamOutlined />} color="cyan">Clinic</Tag>
                ) : selectedTemplate.isPrivate ? (
                  <Tag icon={<LockOutlined />} color="red">Private</Tag>
                ) : (
                  <Tag icon={<GlobalOutlined />} color="green">Public</Tag>
                )}
                {isOwnTemplate && (
                  <Tag icon={<UserOutlined />} color="purple">Created by me</Tag>
                )}
              </Space>
            </div>
          </div>
        </div>


        {/* Action Buttons */}
        <div style={{ 
          display: 'flex',
          justifyContent: 'flex-end',
          gap: 8,
          marginTop: 16 
        }}>
          <Button onClick={() => {
            setTemplateModalView('list');
            setSelectedTemplate(null);
            setDetectedVariables([]);
          }}>
            Back to List
          </Button>
          {isOwnTemplate && (
            <>
              <Button 
                icon={<EditOutlined />}
                onClick={handleEditClick}
              >
                Edit
              </Button>
              <Button 
                danger
                icon={<DeleteOutlined />}
                onClick={handleDeleteClick}
              >
                Delete
              </Button>
            </>
          )}
          <Button 
            type="primary"
            onClick={() => {
              setActiveTemplate({
                id: selectedTemplate.id,
                name: selectedTemplate.name,
                content: selectedTemplate.content,
                variables: detectedVariables || [], // Ensure variables is at least an empty array
                usage_count: selectedTemplate.usage_count,
                createdBy: selectedTemplate.createdBy || ''
              });
              setShowTemplateModal(false);
              setTemplateModalView('list');
              setSelectedTemplate(null);
            }}
            disabled={!hasExistingTranscript}
          >
            Use Template
          </Button>
        </div>
        
        {!hasExistingTranscript && (
          <Alert
            type="warning"
            message="Please record a session before using a template"
            showIcon
          />
        )}

        {/* Delete Confirmation Modal */}
        <Modal
          title="Delete Template"
          open={showDeleteConfirm}
          onCancel={() => setShowDeleteConfirm(false)}
          footer={[
            <Button key="cancel" onClick={() => setShowDeleteConfirm(false)}>
              Cancel
            </Button>,
            <Button
              key="delete"
              type="primary"
              danger
              loading={isDeleting}
              onClick={handleDeleteConfirm}
            >
              Delete
            </Button>
          ]}
        >
          <Space direction="vertical" size={16}>
            <Text>Are you sure you want to delete this template?</Text>
            <Text type="secondary">This action cannot be undone.</Text>
          </Space>
        </Modal>

        {/* Edit Template Modal */}
        <Modal
          title="Edit Template"
          open={isEditingTemplate}
          onCancel={() => {
            setIsEditingTemplate(false);
            form.resetFields();
          }}
          footer={null}
          width={800}
        >
          <Form
            form={form}
            layout="vertical"
            onFinish={handleEditSubmit}
            initialValues={{
              isPrivate: userRole === EUserType.SOLO_PRACTITIONER ? 'private' : 'clinic',
              tags: ['progress note'],
            }}
          >
            <Form.Item
              name="name"
              label="Template Name"
              rules={[{ required: true, message: 'Please enter template name' }]}
            >
              <Input placeholder="Enter template name" />
            </Form.Item>

            <Form.Item
              name="description"
              label="Description"
              rules={[{ required: true, message: 'Please enter template description' }]}
            >
              <Input.TextArea 
                placeholder="Enter template description"
                autoSize={{ minRows: 2, maxRows: 4 }}
              />
            </Form.Item>

            <Form.Item
              name="content"
              label="Template Content"
              rules={[{ required: true, message: 'Please enter template content' }]}
            >
              <Input.TextArea 
                placeholder="Enter template content with placeholders like {{clientName}}"
                autoSize={{ minRows: 6, maxRows: 12 }}
              />
            </Form.Item>

            <Form.Item
              name="isPrivate"
              label="Template Type"
              rules={[{ required: true, message: 'Please select template type' }]}
            >
              <Radio.Group>
                {userRole === EUserType.SOLO_PRACTITIONER ? (
                  <>
                    <Radio.Button value="private">Private</Radio.Button>
                    <Radio.Button value="public">Public</Radio.Button>
                  </>
                ) : (
                  <>
                    <Radio.Button value="clinic">Clinic</Radio.Button>
                    <Radio.Button value="private">Private</Radio.Button>
                    <Radio.Button value="public">Public</Radio.Button>
                  </>
                )}
              </Radio.Group>
            </Form.Item>

            <Form.Item
              name="tags"
              label="Tags"
              rules={[{ required: true, message: 'Please add at least one tag' }]}
            >
              <Select
                mode="tags"
                style={{ width: '100%' }}
                placeholder="Add tags"
                tokenSeparators={[',']}
              />
            </Form.Item>

            <Form.Item>
              <Space style={{ width: '100%', justifyContent: 'flex-end' }}>
                <Button onClick={() => {
                  setIsEditingTemplate(false);
                  form.resetFields();
                }}>
                  Cancel
                </Button>
                <Button type="primary" htmlType="submit" loading={isCreatingTemplate}>
                  Update Template
                </Button>
              </Space>
            </Form.Item>
          </Form>
        </Modal>
      </Space>
    );
  };

  // Add function to fetch client data
  const fetchClientData = useCallback(async () => {
    if (selectedClientId) {
      try {
        const response = await dispatch(getClients({ 
          page: 1,
          size: 10,
          status: undefined,
          keyword: selectedClientId 
        }));
        const clientData = unwrapResult(response);
        setClients(clientData.data);
      } catch (error) {
        console.error('Error fetching client data:', error);
      }
    }
  }, [selectedClientId, dispatch]);

  // Add effect to fetch client data when selectedClientId changes
  useEffect(() => {
    fetchClientData();
  }, [selectedClientId, fetchClientData]);

  // Add template edit handler
  const handleEditTemplate = async (values: TemplateFormValues) => {
    if (!selectedTemplate) return;
    
    try {
      setIsCreatingTemplate(true);
      await dispatch(updateTemplateAction({
        id: selectedTemplate.id,
        template: {
          ...values,
          isPrivate: values.isPrivate !== 'public',
          clinicId: values.isPrivate === 'clinic' ? profile?.id || undefined : undefined,
        }
      })).unwrap();
      
      message.success('Template updated successfully');
      setIsEditingTemplate(false);
      
      // Refresh templates list
      setIsLoadingTemplates(true);
      try {
        const refreshedTemplates = await dispatch(getTemplatesAction({})).unwrap();
        const uiTemplates: UITemplate[] = refreshedTemplates.map((template: BaseTemplate) => ({
          id: template.id,
          name: template.name,
          description: template.description,
          content: template.content,
          icon: <FileTextOutlined />,
          color: template.color || '#1890ff',
          tags: template.tags || [],
          isPrivate: template.isPrivate,
          clinicId: template.clinicId,
          createdBy: template.createdBy,
          usage_count: template.usage_count
        }));
        setTemplates(uiTemplates);
        
        // Update selected template
        const updatedTemplate = uiTemplates.find(t => t.id === selectedTemplate.id);
        if (updatedTemplate) {
          setSelectedTemplate(updatedTemplate);
        }
      } catch (error) {
        message.error('Failed to refresh templates list');
      } finally {
        setIsLoadingTemplates(false);
      }
    } catch (error) {
      message.error('Failed to update template');
    } finally {
      setIsCreatingTemplate(false);
    }
  };

  // Add template delete handler
  const handleDeleteTemplate = async (templateId?: string) => {
    if (!selectedTemplate && !templateId) return;
    
    try {
      setIsDeleting(true);
      await dispatch(deleteTemplateAction(templateId || selectedTemplate!.id)).unwrap();
      message.success('Template deleted successfully');
      setShowDeleteConfirm(false);
      setTemplateModalView('list');
      setSelectedTemplate(null);
      
      // Refresh templates list
      setIsLoadingTemplates(true);
      try {
        const refreshedTemplates = await dispatch(getTemplatesAction({})).unwrap();
        const uiTemplates: UITemplate[] = refreshedTemplates.map((template: BaseTemplate) => ({
          id: template.id,
          name: template.name,
          description: template.description,
          content: template.content,
          icon: <FileTextOutlined />,
          color: template.color || '#1890ff',
          tags: template.tags || [],
          isPrivate: template.isPrivate,
          clinicId: template.clinicId,
          createdBy: template.createdBy,
          usage_count: template.usage_count
        }));
        setTemplates(uiTemplates);
      } catch (error) {
        message.error('Failed to refresh templates list');
      } finally {
        setIsLoadingTemplates(false);
      }
    } catch (error) {
      message.error('Failed to delete template');
    } finally {
      setIsDeleting(false);
    }
  };

  // Add back the renderTemplateModal function
  const renderTemplateModal = () => (
    <Modal 
      title={
        templateModalView === 'list' ? "Choose a Template" : 
        templateModalView === 'create' ? "Create New Template" :
        "Edit Template"
      }
      open={showTemplateModal || showCreateTemplate}
      onCancel={() => {
        if (templateModalView === 'create') {
          setTemplateModalView('list');
          setShowCreateTemplate(false);
          form.resetFields();
        } else if (templateModalView === 'edit') {
          setTemplateModalView('list');
          setSelectedTemplate(null);
          form.resetFields();
        } else {
          setShowTemplateModal(false);
        }
      }}
      footer={null}
      width={800}
      style={{ top: 20 }}
      bodyStyle={{ 
        maxHeight: 'calc(100vh - 200px)',
        overflowY: 'auto',
        padding: '24px'
      }}
    >
      {/* Template Selection */}
      {templateModalView === 'list' ? (
        <Space direction="vertical" size={24} style={{ width: '100%' }}>
          <div style={{ 
            display: 'flex', 
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: 16,
            flexWrap: 'wrap'
          }}>
            <Space.Compact style={{ flex: 1, minWidth: 200 }}>
              <Input.Search
                placeholder="Search templates..."
                value={templateSearchQuery}
                onChange={(e) => setTemplateSearchQuery(e.target.value)}
                style={{ flex: 1 }}
              />
              <Select
                value={templateTypeFilter}
                onChange={setTemplateTypeFilter}
                style={{ width: 120 }}
              >
                <Option value="all">All</Option>
                <Option value="private">Private</Option>
                {userRole !== EUserType.SOLO_PRACTITIONER && <Option value="clinic">Clinic</Option>}
                <Option value="public">Public</Option>
              </Select>
            </Space.Compact>
            <Tooltip title="Template creation">
              <Button 
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => {
                  setTemplateModalView('create');
                  setShowCreateTemplate(true);
                }}
              >
                Create Template
              </Button>
            </Tooltip>
          </div>

          {isLoadingTemplates ? (
            <div style={{ textAlign: 'center', padding: '40px' }}>
              <Spin size="large" />
            </div>
          ) : filteredTemplatesList.length === 0 ? (
            <div style={{ 
              textAlign: 'center', 
              padding: '32px', 
              background: '#f5f5f5',
              borderRadius: '8px'
            }}>
              <FileTextOutlined style={{ fontSize: '24px', color: '#bfbfbf', marginBottom: '8px' }} />
              <Text type="secondary" style={{ display: 'block' }}>No templates found</Text>
              <Button 
                type="link" 
                onClick={() => {
                  setTemplateModalView('create');
                  setShowCreateTemplate(true);
                }}
              >
                Create your first template
              </Button>
            </div>
          ) : (
            <div 
              style={{ 
              display: 'grid',
              gridTemplateColumns: `repeat(auto-fill, minmax(${width <= 640 ? '100%' : '300px'}, 1fr))`,
              gap: '16px',
              marginTop: '16px'
            }}>
              {filteredTemplatesList
                .filter((template: UITemplate) => {
                  // For solo practitioners, only show their private templates and public templates
                  if (userRole === EUserType.SOLO_PRACTITIONER) {
                    if (templateTypeFilter === 'all') {
                      return !template.clinicId; // Show private and public templates
                    }
                    if (templateTypeFilter === 'private') {
                      return template.isPrivate && !template.clinicId;
                    }
                    if (templateTypeFilter === 'public') {
                      return !template.isPrivate && !template.clinicId;
                    }
                    return true;
                  }

                  // For non-solo practitioners, show all template types
                  if (templateTypeFilter === 'all') return true;
                  if (templateTypeFilter === 'private') return template.isPrivate && !template.clinicId;
                  if (templateTypeFilter === 'clinic') return !!template.clinicId;
                  if (templateTypeFilter === 'public') return !template.isPrivate && !template.clinicId;
                  return true;
                })
                .map((template: UITemplate, index: number) => (
                  <Card
                    key={template.id}
                    hoverable
                    style={{ height: '100%' }}
                    ref={index === 0 ? templatesRef : undefined}
                    onClick={() => {
                      handleDocumentTemplateSelect(template);
                      
                      // If we're in the templates tour step, automatically advance to the next step
                      if (isTourOpen && currentTourStep === TEMPLATE_SELECTION_STEP) {
                        setTimeout(() => setCurrentTourStep(prev => prev + 1), 500);
                      }
                    }}
                    actions={[
                      ...(template.createdBy === localStorage.getItem(EUserProfile.PROFILE_ID) ? [
                        <Button 
                          key="edit"
                          type="text" 
                          icon={<EditOutlined />}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDocumentTemplateSelect(template);
                          }}
                        >
                          Edit
                        </Button>,
                        <Button
                          key="delete"
                          type="text"
                          danger
                          icon={<DeleteOutlined />}
                          onClick={(e) => {
                            e.stopPropagation();
                            Modal.confirm({
                              title: 'Delete Template',
                              content: 'Are you sure you want to delete this template? This action cannot be undone.',
                              okText: 'Delete',
                              okType: 'danger',
                              cancelText: 'Cancel',
                              onOk: () => handleDeleteTemplate(template.id)
                            });
                          }}
                        >
                          Delete
                        </Button>
                      ] : [])
                    ]}
                  >
                    <Space direction="vertical" style={{ width: '100%', height: '100%' }}>
                      <Space align="start">
                        <div style={{
                          width: 40,
                          height: 40,
                          borderRadius: '8px',
                          background: template.color || '#1890ff',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          color: '#fff'
                        }}>
                          {template.icon}
                        </div>
                        <div style={{ flex: 1 }}>
                          <Text strong style={{ fontSize: '16px', display: 'block' }}>
                            {template.name}
                          </Text>
                          <Text type="secondary" style={{ fontSize: '14px' }}>
                            {template.description}
                          </Text>
                        </div>
                      </Space>
                      <Space wrap size={[0, 8]} style={{ marginTop: 'auto' }}>
                        {template.tags && template.tags.length > 0 && template.tags.map((tag: string, index: number) => (
                          <Tag key={index} color="blue">{tag}</Tag>
                        ))}
                        {template.clinicId ? (
                          <Tag icon={<TeamOutlined />} color="cyan">Clinic</Tag>
                        ) : template.isPrivate ? (
                          <Tag icon={<LockOutlined />} color="red">Private</Tag>
                        ) : (
                          <Tag icon={<GlobalOutlined />} color="green">Public</Tag>
                        )}
                        {template.createdBy === localStorage.getItem(EUserProfile.PROFILE_ID) && (
                          <Tag icon={<UserOutlined />} color="purple">Created by me</Tag>
                        )}
                      </Space>
                    </Space>
                  </Card>
                ))}
            </div>
          )}
        </Space>
      ) : (
        <Form
          form={form}
          layout="vertical"
          onFinish={templateModalView === 'edit' ? handleEditTemplate : handleCreateTemplate}
          initialValues={{
            isPrivate: userRole === EUserType.SOLO_PRACTITIONER ? 'private' : 'clinic',
            tags: ['progress note'],
          }}
        >
          <Form.Item
            name="name"
            label="Template Name"
            rules={[{ required: true, message: 'Please enter template name' }]}
          >
            <Input placeholder="Enter template name" />
          </Form.Item>

          <Form.Item
            name="description"
            label="Description"
            rules={[{ required: true, message: 'Please enter template description' }]}
          >
            <Input.TextArea 
              placeholder="Enter template description"
              autoSize={{ minRows: 2, maxRows: 4 }}
            />
          </Form.Item>

          <Form.Item
            name="content"
            label="Template Content"
            rules={[{ required: true, message: 'Please enter template content' }]}
          >
            <Input.TextArea 
              placeholder="Enter template content with placeholders like {{clientName}}"
              autoSize={{ minRows: 6, maxRows: 12 }}
            />
          </Form.Item>

          <Form.Item
            name="isPrivate"
            label="Template Type"
            rules={[{ required: true, message: 'Please select template type' }]}
          >
            <Radio.Group>
              {userRole === EUserType.SOLO_PRACTITIONER ? (
                <>
                  <Radio.Button value="private">Private</Radio.Button>
                  <Radio.Button value="public">Public</Radio.Button>
                </>
              ) : (
                <>
                  <Radio.Button value="clinic">Clinic</Radio.Button>
                  <Radio.Button value="private">Private</Radio.Button>
                  <Radio.Button value="public">Public</Radio.Button>
                </>
              )}
            </Radio.Group>
          </Form.Item>

          <Form.Item
            name="tags"
            label="Tags"
            rules={[{ required: true, message: 'Please add at least one tag' }]}
          >
            <Select
              mode="tags"
              style={{ width: '100%' }}
              placeholder="Add tags"
              tokenSeparators={[',']}
            />
          </Form.Item>

          <Form.Item>
            <Space style={{ width: '100%', justifyContent: 'flex-end' }}>
              <Button onClick={() => {
                setTemplateModalView('list');
                if (templateModalView === 'create') {
                  setShowCreateTemplate(false);
                } else {
                  setSelectedTemplate(null);
                }
                form.resetFields();
              }}>
                Cancel
              </Button>
              {templateModalView === 'edit' && (
                <Button
                  danger
                  onClick={() => {
                    Modal.confirm({
                      title: 'Delete Template',
                      content: 'Are you sure you want to delete this template? This action cannot be undone.',
                      okText: 'Delete',
                      okType: 'danger',
                      cancelText: 'Cancel',
                      onOk: handleDeleteTemplate
                    });
                  }}
                >
                  Delete
                </Button>
              )}
              <Button 
                type="primary" 
                htmlType="submit" 
                loading={isCreatingTemplate}
              >
                {templateModalView === 'edit' ? 'Update' : 'Create'} Template
              </Button>
            </Space>
          </Form.Item>
        </Form>
      )}
    </Modal>
  );

  const handleCloseGeneratedDoc = (docId: string) => {
    setGeneratedDocs(prev => prev.filter(doc => doc.id !== docId));
    // Clear the active template if we're closing its document
    if (activeTemplate && activeTemplate.id === docId) {
      setActiveTemplate(null);
    }
    // Only change to transcript tab if we're closing the currently active doc
    if (activeTab === `doc-${docId}`) {
      setActiveTab('transcript');
    }
  };

  // Replace with a single memoized filtered templates value:
  const filteredTemplatesList = useMemo(() => {
    if (!templates.length) return [];
    if (!templateSearchQuery) return templates;
    
    return templates.filter(template => 
      template.name.toLowerCase().includes(templateSearchQuery.toLowerCase()) ||
      template.description.toLowerCase().includes(templateSearchQuery.toLowerCase()) ||
      template.tags?.some(tag => tag.toLowerCase().includes(templateSearchQuery.toLowerCase()))
    );
  }, [templates, templateSearchQuery]);

  // Add this effect to reset hasUploaded when transcript is cleared
  useEffect(() => {
    if (!hasExistingTranscript) {
      setHasUploaded(false);
    }
  }, [hasExistingTranscript]);

  // Add this function to fetch sessions
  const fetchSessions = async () => {
    if (!selectedClientId) return;
    
    setLoadingSessions(true);
    try {
      const response = await dispatch(getTranscriptsAction({
        clientId: selectedClientId,
        page: sessionFilters.page,
        limit: sessionFilters.limit,
        startDate: sessionFilters.startDate,
        endDate: sessionFilters.endDate
      })).unwrap();

      console.log('response', response);

      // Map the TTranscript data to match our Session interface
      const mappedSessions: Session[] = response.data.map(transcript => ({
        id: transcript.id,
        sessionId: transcript.sessionId,
        recordingDate: new Date(transcript.recordingDate).toISOString(),
        duration: transcript.duration,
        totalSegments: transcript.totalSegments,
        averageConfidence: transcript.averageConfidence,
        createdAt: new Date(transcript.createdAt).toISOString(),
        clientId: selectedClientId // Use the selected client ID
      }));

      setSessions(mappedSessions);
      setTotalSessions(response.total);
    } catch (error) {
      message.error('Failed to load sessions');
    }
    setLoadingSessions(false);
  };

  // Add useEffect to fetch sessions when filters change
  useEffect(() => {
    if (showSessionsDrawer && selectedClientId) {
      fetchSessions();
    }
  }, [showSessionsDrawer, sessionFilters, selectedClientId]);

  // Add the sessions drawer component
  const renderSessionsDrawer = (): React.ReactElement => (
    <Drawer
      title={`Session History${clientName ? ` - ${clientName}` : ''}`}
      placement="right"
      width={width <= MOBILE_BREAKPOINT ? '100%' : Math.min(720, width * 0.8)}
      onClose={() => setShowSessionsDrawer(false)}
      open={showSessionsDrawer && !!selectedClientId}
      bodyStyle={{ padding: width <= MOBILE_BREAKPOINT ? '12px' : '24px' }}
    >
      <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        {/* Filters */}
        <div style={{ 
          display: 'flex', 
          gap: '16px', 
          flexWrap: 'wrap',
          flexDirection: width <= MOBILE_BREAKPOINT ? 'column' : 'row',
          alignItems: width <= MOBILE_BREAKPOINT ? 'stretch' : 'center'
        }}>
          <DatePicker.RangePicker
            onChange={(dates) => {
              if (dates) {
                setSessionFilters({
                  ...sessionFilters,
                  startDate: dates[0]?.toISOString(),
                  endDate: dates[1]?.toISOString()
                });
              }
            }}
            style={{ width: width <= MOBILE_BREAKPOINT ? '100%' : 'auto' }}
          />
        </div>

        {/* Sessions Table */}
        <Table
          dataSource={sessions}
          loading={loadingSessions}
          rowKey="id"
          pagination={{
            total: totalSessions,
            pageSize: sessionFilters.limit,
            current: sessionFilters.page,
            onChange: (page) => {
              setSessionFilters(prev => ({
                ...prev,
                page
              }));
            },
            responsive: true,
            size: width <= MOBILE_BREAKPOINT ? 'small' : 'default'
          }}
          columns={[
            {
              title: 'Date',
              dataIndex: 'recordingDate',
              key: 'recordingDate',
              render: (date) => format(new Date(date), width <= MOBILE_BREAKPOINT ? 'MM/dd/yy' : 'MMM dd, yyyy HH:mm')
            },
            {
              title: 'Duration',
              dataIndex: 'duration',
              key: 'duration',
              render: (duration) => formatDuration(duration),
              responsive: ['md']
            },
            {
              title: 'Segments',
              dataIndex: 'totalSegments',
              key: 'totalSegments',
              responsive: ['lg']
            },
            {
              title: 'Actions',
              key: 'actions',
              render: (_, record) => {
                const isLoaded = generatedDocs.some(doc => 
                  doc.id === record.id || 
                  doc.id === `session-${record.id}` ||
                  (doc.sessionId && doc.sessionId === record.id)
                );
                return (
                  <Space wrap={width <= MOBILE_BREAKPOINT} size={[8, 8]}>
                    <Button
                      type={isLoaded ? 'default' : 'primary'}
                      onClick={() => {
                        handleSessionLoad(record);
                      }}
                      icon={isLoaded ? <CheckCircleOutlined /> : undefined}
                      disabled={isLoaded}
                      size={width <= MOBILE_BREAKPOINT ? 'small' : 'middle'}
                    >
                      {isLoaded ? (width <= MOBILE_BREAKPOINT ? 'Loaded' : 'Already Loaded') : 'Load Session'}
                    </Button>
                    <Button
                      danger
                      icon={<DeleteOutlined />}
                      onClick={() => {
                        Modal.confirm({
                          title: 'Delete Session',
                          content: 'Are you sure you want to delete this session? This action cannot be undone.',
                          okText: 'Delete',
                          okType: 'danger',
                          cancelText: 'Cancel',
                          onOk: () => handleSessionDelete(record)
                        });
                      }}
                      size={width <= MOBILE_BREAKPOINT ? 'small' : 'middle'}
                    >
                      {width <= MOBILE_BREAKPOINT ? '' : 'Delete'}
                    </Button>
                  </Space>
                );
              }
            }
          ]}
          scroll={{ x: 'max-content' }}
          size={width <= MOBILE_BREAKPOINT ? 'small' : 'middle'}
        />
      </div>
    </Drawer>
  );

  // Add this function inside the component
  const handleSessionLoad = async (session: Session) => {
    // Check if session is already loaded by checking both session ID and session-prefixed ID
    const isSessionLoaded = generatedDocs.some(doc => 
      doc.id === session.id || 
      doc.id === `session-${session.id}` ||
      (doc.sessionId && doc.sessionId === session.id)
    );

    if (isSessionLoaded) {
      const loadedDoc = generatedDocs.find(doc => 
        doc.id === session.id || 
        doc.id === `session-${session.id}` ||
        (doc.sessionId && doc.sessionId === session.id)
      );
      
      if (loadedDoc) {
        message.info({
          content: (
            <span>
              This session is already loaded in tab{' '}
              <strong>{loadedDoc.name}</strong>
            </span>
          ),
          duration: 3
        });
        setActiveTab(loadedDoc.id);
        setShowSessionsDrawer(false);
        return;
      }
    }

    try {
      const transcriptResult = await dispatch(getTranscriptDetailsAction({
        transcriptId: session.id,
        clientId: session.clientId
      })).unwrap();

      const formattedDate = format(new Date(session.recordingDate), 'MMM dd, yyyy HH:mm');
      const formattedDuration = session.duration < 60 
        ? `${Math.floor(session.duration)} secs`
        : `${Math.floor(session.duration / 60)}:${String(Math.floor(session.duration % 60)).padStart(2, '0')} mins`;
      const sessionName = `${session.clientName || 'Session'} - ${formattedDate} (${formattedDuration})`;

      // Format each segment in the same way as live transcripts
      const formattedContent = transcriptResult.segments.map((segment, index, array) => {
        // Calculate proper start and end times
        const startTime = segment.startTime;
        let endTime: number;
        
        if (segment.endTime) {
          // Use segment's end time if available
          endTime = segment.endTime;
        } else if (index < array.length - 1) {
          // Use next segment's start time as end time
          endTime = array[index + 1].startTime;
        } else {
          // For last segment, add typical segment duration (e.g., 5 seconds)
          endTime = startTime + 5;
        }

        // Format timestamps as MM:SS
        const formatTimeMMSS = (timeInSeconds: number) => {
          const minutes = Math.floor(timeInSeconds / 60);
          const seconds = Math.floor(timeInSeconds % 60);
          return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
        };

        const startTimeStr = formatTimeMMSS(startTime);
        const endTimeStr = formatTimeMMSS(endTime);
        
        // Format confidence and sentiment
        const defaultConfidence = 0.75;
        const defaultSentiment = { label: 'neutral' as const, score: 0 };
        
        const confidence = segment.confidence ?? defaultConfidence;
        const sentiment = segment.sentiment ?? defaultSentiment;
        
        const confidenceFormatted = Math.round(confidence * 100);
        const sentimentFormatted = Math.round(Math.abs(sentiment.score) * 100);
        
        // Include segment ID in the formatted string
        return `[ID:${segment.id}] [${segment.speaker}] [CONF ${confidenceFormatted}%] [${sentiment.label.toUpperCase()} ${sentimentFormatted}%] [${startTimeStr} - ${endTimeStr}]: ${segment.text}`;
      }).join('\n\n');

      const sessionDoc: GeneratedDoc = {
        id: `session-${session.id}`,
        sessionId: session.id,
        name: sessionName,
        content: formattedContent,
        isGenerated: false
      };

      setGeneratedDocs(prev => [...prev, sessionDoc]);
      setActiveTab(`doc-${sessionDoc.id}`);  // Changed from `session-${session.id}`
      setShowSessionsDrawer(false);
      message.success('Session loaded successfully');
    } catch (error) {
      console.error('Failed to load session:', error);
      message.error('Failed to load session');
    }
  };

  // ... existing code ...
  const handleSessionDelete = async (session: Session) => {
    try {
      await dispatch(deleteTranscriptsAction({
        transcriptIds: [session.id],
        clientId: session.clientId
      })).unwrap();
      
      message.success('Session deleted successfully');
      // Refresh sessions list
      fetchSessions();
    } catch (error) {
      console.error('Error deleting session:', error);
      message.error('Failed to delete session');
    }
  };
  // ... existing code ...

  // Refs for tour targets
  const clientInputRef = useRef<HTMLDivElement>(null);
  const recordButtonRef = useRef<HTMLDivElement>(null);
  const transcriptContainerRef = useRef<HTMLDivElement>(null);
  const sentimentToggleRef = useRef<HTMLDivElement>(null);
  const templateSectionRef = useRef<HTMLDivElement>(null);
  const quickNotesRef = useRef<HTMLDivElement>(null);
  const settingsRef = useRef<HTMLDivElement>(null);
  const historyRef = useRef<HTMLDivElement>(null);

  // Add new refs for segment features
  const segmentSpeakerLabelRef = useRef<HTMLDivElement>(null);
  const segmentConfidenceRef = useRef<HTMLDivElement>(null);
  const segmentSentimentIconRef = useRef<HTMLDivElement>(null);
  const segmentTimestampRef = useRef<HTMLDivElement>(null);
  const segmentEditButtonRef = useRef<HTMLDivElement>(null);
  const segmentDeleteButtonRef = useRef<HTMLDivElement>(null);
  const transcriptionSegmentRef = useRef<HTMLDivElement>(null);
  const overallSentimentRef = useRef<HTMLDivElement>(null);
  const createButtonRef = useRef<HTMLDivElement>(null);
  const templatesRef = useRef<HTMLDivElement>(null);
  const selectedTemplateRef = useRef<HTMLDivElement>(null);
  const generateButtonRef = useRef<HTMLDivElement>(null);
  const generatedDocumentRef = useRef<HTMLDivElement>(null);
  const piiSettingsRef = useRef<HTMLDivElement>(null);

  // Add handler for segment changes
  const handleSegmentsChange = useCallback((count: number) => {
    setSegmentCount(count);
  }, []);

  // Add a cleanup function near the top of the component
  const cleanupForTour = useCallback(() => {
    // Clear transcript if exists
    setHasExistingTranscript(false);
    // Clear any selected template
    setActiveTemplate(null);
    // Clear generated documents
    setGeneratedDocs([]);
    // Reset to transcript tab
    setActiveTab('transcript');
    // Clear client name and selection
    setClientName('');
    setSelectedClientId(null);
    setIsClientLinked(false);
    // Reset any other state
    setShowTemplateModal(false);
    setShowSessionsDrawer(false);
    setIsSettingsVisible(false);
  }, []);


  // Update the tour navigation logic
  const handleTourStepChange = (current: number) => {
    // Don't allow proceeding past first step if no microphone permission
    if (current > 0 && !hasMicrophonePermission) {
      message.warning('Please enable microphone access to continue the tour');
      return;
    }
    
    // Don't allow proceeding past transcription step without segments
    if (current > 3 && currentTourStep === 3 && segmentCount === 0) {
      message.warning('Please speak to generate at least one transcription segment');
      return;
    }
    
    setCurrentTourStep(current);
  };

  // Update the tour close handler
  const handleTourClose = () => {
    // Don't save tour as completed if microphone permission wasn't granted
    if (currentTourStep === 0 && !hasMicrophonePermission) {
      setIsTourOpen(false);
      return;
    }
    setIsTourOpen(false);
    localStorage.setItem('transcribe-tour-completed', 'true');
    cleanupForTour();
  };

  const renderTutorialButton = () => (
    <Tooltip title="Start Tutorial">
      <Button
        type="text"
        icon={<QuestionCircleOutlined />}
        onClick={() => setIsTourOpen(true)}
        style={{
          position: 'fixed',
          bottom: 24,
          right: 24,
          width: 40,
          height: 40,
          borderRadius: '50%',
          boxShadow: '0 2px 8px rgba(0,0,0,0.15)',
          backgroundColor: '#fff',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          fontSize: '20px',
          zIndex: 1000
        }}
      />
    </Tooltip>
  );

  const renderTemplateVariables = useCallback(() => {
    if (!customVariables) return null;
    
    return (
      <div className="template-variables">
        {customVariables.map((variable, index) => (
          <div key={index} className="variable-item">
            <Tag color="green">
              {variable.name}
            </Tag>
            <span>{variable.description}</span>
          </div>
        ))}
      </div>
    );
  }, [customVariables]);

  // Add permission alert component
  const renderPermissionAlert = () => {
    if (isCheckingPermissions) {
      return (
        <Alert
          message="Checking Microphone Access"
          description={
            <Space direction="vertical">
              <Spin size="small" />
              <Text>Please wait while we check microphone permissions...</Text>
            </Space>
          }
          type="info"
          showIcon
          style={{ 
            borderRadius: 12,
            border: 'none',
            boxShadow: '0 2px 8px rgba(24, 144, 255, 0.1)',
            marginBottom: 16
          }}
        />
      );
    }

    if (hasMicrophonePermission === false) {
      return (
        <Alert
          message="Microphone Access Required"
          description={
            <Space direction="vertical">
              <Text>To use Live Transcribe, you need to allow microphone access:</Text>
              <ol style={{ marginBottom: 0, paddingLeft: 24 }}>
                <li>Click the microphone icon in your browser&apos;s address bar</li>
                <li>Select &quot;Allow&quot; for microphone access</li>
                <li>Refresh the page after granting permission</li>
              </ol>
              <Button 
                type="primary"
                icon={<ReloadOutlined />}
                onClick={checkMicrophonePermissions}
                style={{ marginTop: 8 }}
              >
                Check Again
              </Button>
            </Space>
          }
          type="warning"
          showIcon
          style={{ 
            borderRadius: 12,
            border: 'none',
            boxShadow: '0 2px 8px rgba(250, 173, 20, 0.1)',
            marginBottom: 16
          }}
        />
      );
    }

    return null;
  };

  // Helper function to extract variables from text
  const extractVariablesFromText = (text: string): TemplateVariable[] => {
    const variableRegex = /\{\{([^}]+)\}\}/g;
    const matches = text.match(variableRegex);
    return matches 
      ? matches.map(m => ({
          name: m.replace(/[{}]/g, ''),
          value: '',
          type: 'custom' as const,
          description: 'Variable detected from transcript'
        }))
      : [];
  };

  // Add a helper function for duration formatting
  const formatDuration = (duration: number): string => {
    const minutes = Math.floor(duration / 60);
    const seconds = Math.floor(duration % 60);
    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
  };

  return (
    <div 
      className="live-transcribe-page"
      style={{
        width: isMobile ? '100%' : 'calc(100% - 96px)',
        height: 'calc(100vh - 64px)',
        display: 'flex',
        flexDirection: 'column',
        background: '#f5f5f5',
        position: 'absolute',
        top: '64px',
        left: isMobile ? '0' : '96px',
        right: 0,
        bottom: 0,
        overflow: 'auto'
      }}>
      {/* Header */}
      <div style={{
        padding: isMobile ? '8px 12px' : '12px 16px',
        display: 'flex',
        flexDirection: 'column',
        gap: '12px',
        zIndex: 100,
        flexShrink: 0,
        position: 'sticky',
        top: 0,
        background: '#f5f5f5'
      }}>
        {/* Main header row */}
        <div style={{ 
          display: 'flex',
          alignItems: 'center',
          gap: '12px',
          width: '100%',
          flexWrap: width <= 640 ? 'wrap' : 'nowrap',
          justifyContent: 'space-between'
        }}>
          {/* Client input section */}
          <div style={{
            flex: '1 1 auto',
            maxWidth: '500px',
            minWidth: width <= 640 ? '100%' : '300px'
          }}>
            {renderClientInput()}
          </div>

          {/* Right section - Timer and action buttons */}
          <div style={{
            display: 'flex',
            alignItems: 'center',
            gap: '12px',
            flexWrap: 'wrap',
            justifyContent: 'flex-end'
          }}>
            {clientName && (hasExistingTranscript || generatedDocs.length > 0) && activeTemplate && (
              <Button
                ref={generateButtonRef}
                type="primary"
                icon={<FontAwesomeIcon icon={faWandSparkles} />}
                loading={isGeneratingDocument}
                disabled={!clientName || (!hasExistingTranscript && !generatedDocs.length) || !activeTemplate}
                onClick={async () => {
                  const docId = Date.now().toString();
                  try {
                    setIsGeneratingDocument(true);
                    setGeneratedDocument('');
                    // Create a temporary document immediately
                    setGeneratedDocs(prev => [...prev, {
                      id: docId,
                      content: '',
                      name: `Generated Document ${prev.length + 1}`,
                      isGenerated: true
                    }]);
                    // Switch to the new document tab
                    setActiveTab(`doc-${docId}`);

                    // Combine current transcript segments with loaded historical sessions
                    const allSegments = [
                      ...apiTranscriptSegments,
                      ...generatedDocs
                        .filter(doc => !doc.isGenerated) // Only include non-generated (historical) docs
                        .flatMap(doc => {
                          // Convert the historical doc content back into segments
                          return doc.content.split('\n\n').map(segment => {
                            const [speaker, text] = segment.split(']: ');
                            return {
                              speaker: speaker.replace('[', ''),
                              text: text || '',
                              startTime: 0 // Historical segments don't need exact timing
                            };
                          });
                        })
                    ];

                    const result = await dispatch(generateDocumentFromTemplateAction({
                      transcript: {
                        segments: allSegments
                      },
                      template: {
                        id: activeTemplate.id,
                        name: activeTemplate.name,
                        content: activeTemplate.content
                      },
                      ...(isClientLinked && selectedClientId ? { clientId: selectedClientId } : { clientName: clientName })
                    })).unwrap();
                    
                    // Update the document with the actual content
                    setGeneratedDocument(result.content);
                    setGeneratedDocs(prev => prev.map(doc => 
                      doc.id === docId 
                        ? { ...doc, content: result.content }
                        : doc
                    ));
                    message.success('Document generated successfully');

                    // If we're in the Generate Document step of the tour, advance to the next step
                    if (isTourOpen && currentTourStep === 16) {
                      setTimeout(() => setCurrentTourStep(prev => prev + 1), 500);
                    }
                  } catch (error) {
                    message.error('Failed to generate document');
                    // Remove the temporary document on error
                    setGeneratedDocs(prev => prev.filter(doc => doc.id !== docId));
                  } finally {
                    setIsGeneratingDocument(false);
                  }
                }}
                style={{ 
                  height: '40px',
                  borderRadius: '6px',
                  fontSize: '14px',
                  display: 'flex',
                  alignItems: 'center',
                  gap: 8,
                  minWidth: isVerySmallScreen ? '40px' : 'auto',
                  justifyContent: 'center',
                  border: 'none',
                  background: 'linear-gradient(45deg, #722ed1, #1890ff)',
                  boxShadow: '0 2px 8px rgba(114, 46, 209, 0.35)',
                  transition: 'all 0.3s ease'
                }}
              >
                {!isVerySmallScreen && (
                  <span style={{
                    background: 'linear-gradient(45deg, #f0f0f0, #ffffff)',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent'
                  }}>
                    Generate
                  </span>
                )}
              </Button>
            )}
            <Tooltip title="Settings">
              <Button
                type={isSettingsVisible ? 'primary' : 'default'}
                icon={<SettingOutlined />}
                onClick={() => setIsSettingsVisible(true)}
                style={{ 
                  height: '40px',
                  width: '40px',
                  borderRadius: '6px',
                  padding: '8px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              />
            </Tooltip>
            <Button
              icon={<HistoryOutlined />}
              onClick={() => setShowSessionsDrawer(true)}
              disabled={!isClientLinked}
              style={{ height: '40px' }}
            >
              {isClientLinked ? 'View Client Sessions' : 'Select a client to view sessions'}
            </Button>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div style={{
        flex: 1,
        background: '#f5f5f5',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        padding: '16px',
        gap: '16px',
        overflow: 'auto'
      }}>
        {/* Add Permission Alert */}
        {renderPermissionAlert()}

        {/* Add custom styles for tabs */}
        <style>
          {`
            .live-transcribe-tabs .ant-tabs-nav {
              margin-bottom: 16px;
            }
            .live-transcribe-tabs .ant-tabs-tab {
              background: white !important;
              border: 1px solid #d9d9d9 !important;
              border-radius: 4px !important;
              margin: 0 8px 0 0 !important;
              padding: 6px 16px !important;
              transition: all 0.3s;
            }
            .live-transcribe-tabs .ant-tabs-tab:hover {
              color: #1890ff !important;
              border-color: #1890ff !important;
            }
            .live-transcribe-tabs .ant-tabs-tab.ant-tabs-tab-active {
              background: white !important;
              border-color: #1890ff !important;
            }
            .live-transcribe-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
              color: #1890ff !important;
            }
            .live-transcribe-tabs .ant-tabs-ink-bar {
              display: none !important;
            }
          `}
        </style>

        {/* Live Transcribe Tab */}
        <LiveTranscribeTab
          ref={transcriptContainerRef}
          clientId={selectedClientId || ''}
          clientName={clientName}
          isRecording={isRecording}
          isPaused={isPaused}
          onRecordingStateChange={(recording: boolean, initializing: boolean) => {
            setIsRecording(recording);
            setIsInitializing(initializing);
          }}
          onPauseStateChange={handlePauseToggle}
          onProcessRecording={handleProcessRecording}
          onTranscriptStateChange={setHasExistingTranscript}
          onTranscriptUpdate={setApiTranscriptSegments}
          onSegmentsChange={handleSegmentsChange}
          quickNotes={quickNotes}
          selectedTemplate={activeTemplate}
          onTemplateClose={handleTemplateClose}
          renderTemplateVariables={renderTemplateVariables}
          isAddingVariable={isAddingVariable}
          setIsAddingVariable={setIsAddingVariable}
          newVariableName={newVariableName}
          setNewVariableName={setNewVariableName}
          newVariableValue={newVariableValue}
          setNewVariableValue={setNewVariableValue}
          handleAddCustomVariable={handleAddCustomVariable}
          isGeneratingDocument={isGeneratingDocument}
          generatedDocument={generatedDocument}
          generatedDocs={generatedDocs}
          activeTab={activeTab}
          setActiveTab={setActiveTabCallback}
          onCloseDocument={handleCloseGeneratedDoc}
          customVariables={customVariables}
          isTemplateVariablesCollapsed={isTemplateVariablesCollapsed}
          segmentRefs={{
            speakerLabelRef: segmentSpeakerLabelRef,
            confidenceRef: segmentConfidenceRef,
            sentimentIconRef: segmentSentimentIconRef,
            timestampRef: segmentTimestampRef,
            editButtonRef: segmentEditButtonRef,
            deleteButtonRef: segmentDeleteButtonRef,
            transcriptionSegmentRef: transcriptionSegmentRef,
            overallSentimentRef: overallSentimentRef,
            selectedTemplateRef: selectedTemplateRef,
            generatedDocumentRef: generatedDocumentRef,
            piiSettingsRef: piiSettingsRef // Add PII settings ref
          }}
          piiSettings={piiSettings} // Add PII settings prop
          showTemplateModal={showTemplateModal}
          setShowTemplateModal={setShowTemplateModal}
          hasExistingTranscript={hasExistingTranscript}
          renderTemplateModal={renderTemplateModal}
          onGeneratedDocsChange={setGeneratedDocs}
          isClientLinked={isClientLinked}
          selectedClientId={selectedClientId}
        />

      </div>

      {/* Settings Drawer */}
      <Drawer
        title="Settings"
        placement="right"
        open={isSettingsVisible}
        onClose={() => setIsSettingsVisible(false)}
        width={isMobile ? '100%' : Math.min(400, width * 0.9)}
        bodyStyle={{ 
          padding: isMobile ? '12px' : '24px',
          overflowX: 'hidden'
        }}
      >
        {renderSettingsContent()}
      </Drawer>

      {/* Template Modal */}
      {renderTemplateModal()}

      {/* Add styles for magical button */}
      <style>
        {`
          .magical-generate-btn {
            background: linear-gradient(45deg, #722ed1, #1890ff) !important;
            box-shadow: 0 2px 8px rgba(114, 46, 209, 0.35) !important;
            transition: all 0.3s ease !important;
          }
          
          .magical-generate-btn:hover {
            background: linear-gradient(45deg, #531dab, #096dd9) !important;
            box-shadow: 0 4px 12px rgba(114, 46, 209, 0.45) !important;
            transform: translateY(-1px);
          }

          .magical-text {
            background: linear-gradient(45deg, #f0f0f0, #ffffff);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        `}
      </style>

      {/* Error Alert */}
      {error && (
        <Alert
          message="Error"
          description={error}
          type="error"
          closable
          onClose={() => setError(null)}
          style={{ 
            borderRadius: 12,
            border: 'none',
            boxShadow: '0 2px 8px rgba(255, 0, 0, 0.1)',
            flexShrink: 0
          }}
        />
      )}

      {renderSessionsDrawer()}

    </div>
  );
}

export default LiveTranscribePage; 