import { Socket, io } from 'socket.io-client';
import { TRoomMessage } from 'src/interfaces/chat-interface';

import { EAuthToken, EUserProfile } from 'src/variables/storage';
import { BufferStatus } from '../interfaces/socket-interfaces';

const url = process.env.REACT_APP_API_URL ?? '';

class SocketConfig {
  private static socket: Socket | null = null;

  static getSocket() {
    if (SocketConfig.socket) {
      return SocketConfig.socket;
    }

    const accessToken = localStorage.getItem(EAuthToken.ACCESS_TOKEN);
    SocketConfig.socket = io(url, { 
      query: { accessToken },
      reconnection: true,
      reconnectionAttempts: 5,
      reconnectionDelay: 1000,
      reconnectionDelayMax: 5000,
      timeout: 20000,
    });
    return SocketConfig.socket;
  }

  static destroySocket() {
    if (SocketConfig.socket) {
      SocketConfig.socket.disconnect();
      SocketConfig.socket.close();
      SocketConfig.socket = null;
    }
  }
}

export const getSocket = () => {
  const currentProfileId = localStorage.getItem(EUserProfile.PROFILE_ID);

  const connect = () => {
    const socket = SocketConfig.getSocket();
    socket.connect();
  };

  const disconnect = () => {
    SocketConfig.destroySocket();
  };

  const reconnect = () => {
    SocketConfig.destroySocket();
    const socket = SocketConfig.getSocket();
    socket.connect();
  };

  const leaveRoomChat = async (roomId: string) => {
    if (currentProfileId) {
      const socket = SocketConfig.getSocket();
      socket.emit('leaveRoom', `ROOM-${roomId}`);
      socket.off('messageOnRoom');
    }
  };
  

  const joinRoomChat = (roomId: string, cb: (message: TRoomMessage) => void) => {
    if (currentProfileId) {
      const socket = SocketConfig.getSocket();
      socket.emit('joinRoom', `ROOM-${roomId}`);
      socket.on('messageOnRoom', (message: TRoomMessage) => {
        cb(message);
      });
    }
  };

  const leaveRoomNotification = () => {
    if (currentProfileId) {
      const socket = SocketConfig.getSocket();
      socket.emit('leaveRoom', `PROFILE-${currentProfileId}`);
      socket.off('notification');
    }
  };

  const joinRoomNotification = (handleReceiveNotification?: (message: TRoomMessage) => void) => {
    if (currentProfileId) {
      const socket = SocketConfig.getSocket();
      socket.emit('joinRoom', `PROFILE-${currentProfileId}`);
      socket.on('notification', (message) => {
        handleReceiveNotification?.(message);
      });
    }
  };

  const listenMessageOnProfile = (cb: () => void) => {
    const socket = SocketConfig.getSocket();
    socket.off('messageOnProfile');
    socket.on('messageOnProfile', () => {
      cb();
    });
  };

  const offMessageOnProfile = () => {
    const socket = SocketConfig.getSocket();
    socket.off('messageOnProfile');
  };

  const listenCreateRoom = (cb: () => void) => {
    if (currentProfileId) {
      const socket = SocketConfig.getSocket();
      socket.off('room');
      socket.on('room', () => {
        cb();
      });
    }
  };

  const offCreateRoom = () => {
    const socket = SocketConfig.getSocket();
    socket.off('room');
  };

  const listenGptResChunk = (cb: (message: { content: string }) => void) => {
    const socket = SocketConfig.getSocket();
    socket.off('gptResChunk'); // Remove any previous listeners to avoid duplicates
    socket.on('gptResChunk', (message: { content: string }) => {
      cb(message);
    });
  };

  const offGptResChunk = () => {
    const socket = SocketConfig.getSocket();
    socket.off('gptResChunk');
  };

  const connectGPT = (profileId: string) => {
    const socket = SocketConfig.getSocket();
    socket.emit('joinRoom', `PROFILE-${profileId}`);
  };

  const toggleMetrics = (sessionId: string, enabled: boolean) => {
    const socket = SocketConfig.getSocket();
    socket.emit('toggle_metrics', { sessionId, enabled });
  };

  interface StreamingStartResponse {
    data: {
      sessionId: string;
    };
  }

  interface TranscriptionResult {
    sessionId: string;
    transcript: string;
    isPartial: boolean;
    speakers: Array<{
      speaker: string;
      text: string;
      confidence: number;
      startTime: number;
      endTime: number;
    }>;
  }

  interface TranscriptionError {
    message: string;
    code?: string;
    details?: string;
  }

  // Live transcription methods
  const startTranscription = async (): Promise<string> => {
    const socket = SocketConfig.getSocket();
    return new Promise((resolve, reject) => {
      socket.emit('start_streaming', null, (response: { event: string; data: { sessionId: string } }) => {
        if (response?.data?.sessionId) {
          resolve(response.data.sessionId);
        } else {
          reject(new Error('Failed to start transcription'));
        }
      });

      // Add a timeout in case the server doesn't respond
      setTimeout(() => {
        reject(new Error('Transcription start timeout'));
      }, 5000);

      // Also listen for the streaming_started event as a fallback
      socket.once('streaming_started', (response: { sessionId: string }) => {
        if (response?.sessionId) {
          resolve(response.sessionId);
        }
      });
    });
  };

  const stopTranscription = (sessionId: string): void => {
    const socket = SocketConfig.getSocket();
    socket.emit('stop_streaming', { sessionId });
  };

  const streamAudio = (sessionId: string, audioData: Float32Array | Int16Array | Uint8Array): void => {
    const socket = SocketConfig.getSocket();
    socket.emit('stream_audio', {
      sessionId,
      audioData: Array.from(audioData),
      timestamp: Date.now()
    });
  };

  const listenTranscriptionResults = (callback: (result: TranscriptionResult) => void): void => {
    const socket = SocketConfig.getSocket();
    socket.on('transcription_result', callback);
  };

  const listenTranscriptionErrors = (callback: (error: TranscriptionError) => void): void => {
    const socket = SocketConfig.getSocket();
    socket.on('transcription_error', callback);
  };

  const cleanupTranscriptionListeners = (): void => {
    const socket = SocketConfig.getSocket();
    socket.off('transcription_result');
    socket.off('transcription_error');
    socket.off('stream_error');
    socket.off('streaming_stopped');
  };

  const listenNetworkStatus = (callback: (status: { 
    status: 'stable' | 'unstable' | 'recovering';
    recommendedAction?: string;
  }) => void) => {
    const socket = SocketConfig.getSocket();
    socket.on('network_status', callback);
  };

  const listenBufferWarning = (callback: (warning: {
    level: 'healthy' | 'strained' | 'critical';
    bufferedSeconds: number;
  }) => void) => {
    const socket = SocketConfig.getSocket();
    socket.on('buffer_warning', callback);
  };

  const listenStreamHeartbeat = (callback: (data: { sessionId: string }, ack: (response: boolean) => void) => void) => {
    const socket = SocketConfig.getSocket();
    socket.on('stream_heartbeat', callback);
  };

  const cleanupStreamListeners = () => {
    const socket = SocketConfig.getSocket();
    socket.off('network_status');
    socket.off('buffer_warning');
    socket.off('stream_heartbeat');
  };

  const listenBufferStatus = (callback: (status: BufferStatus) => void) => {
    const socket = SocketConfig.getSocket();
    socket.on('buffer_status', callback);
  };

  const toggleMetricsSubscription = (enabled: boolean) => {
    const socket = SocketConfig.getSocket();
    socket.emit('toggle_metrics_subscription', { enabled });
  };

  const listenSocketConnect = (callback: () => void) => {
    const socket = SocketConfig.getSocket();
    socket.on('connect', callback);
  };

  const listenSocketDisconnect = (callback: () => void) => {
    const socket = SocketConfig.getSocket();
    socket.on('disconnect', callback);
  };

  const listenSocketError = (callback: (error: Error) => void) => {
    const socket = SocketConfig.getSocket();
    socket.on('error', callback);
  };

  const cleanupSocketListeners = () => {
    const socket = SocketConfig.getSocket();
    socket.off('connect');
    socket.off('disconnect');
    socket.off('error');
  };

  return {
    connect,
    disconnect,
    reconnect,
    connectGPT,
    leaveRoomChat,
    joinRoomChat,
    listenMessageOnProfile,
    offMessageOnProfile,
    listenCreateRoom,
    offCreateRoom,
    leaveRoomNotification,
    joinRoomNotification,
    listenGptResChunk,
    offGptResChunk,
    startTranscription,
    stopTranscription,
    streamAudio,
    listenTranscriptionResults,
    listenTranscriptionErrors,
    cleanupTranscriptionListeners,
    listenNetworkStatus,
    listenBufferWarning,
    listenStreamHeartbeat,
    cleanupStreamListeners,
    listenBufferStatus,
    toggleMetrics,
    toggleMetricsSubscription,
    listenSocketConnect,
    listenSocketDisconnect,
    listenSocketError,
    cleanupSocketListeners,
  };
};
