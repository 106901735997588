import React from 'react';
import { ArrowUpOutlined, ArrowDownOutlined, MinusOutlined } from '@ant-design/icons';
import { BaseText } from 'src/components/typography';
import { DassMetrics } from './utils/statistics';
import { getSeverityForScore } from './utils/severity';
import './DassStatistics.scss';

interface DassStatisticsProps {
  title: string;
  metrics: DassMetrics;
  color: string;
  version: 'DASS-21' | 'DASS-42';
}

const DassStatistics: React.FC<DassStatisticsProps> = ({ title, metrics, color, version }) => {
  const getTrendIcon = () => {
    switch (metrics.trend) {
      case 'improving':
        return <ArrowDownOutlined style={{ color: '#52c41a' }} />;
      case 'worsening':
        return <ArrowUpOutlined style={{ color: '#ff4d4f' }} />;
      default:
        return <MinusOutlined style={{ color: '#8c8c8c' }} />;
    }
  };

  const severity = getSeverityForScore(metrics.current, title as 'Depression' | 'Anxiety' | 'Stress', version);

  return (
    <div className="dass-stat-card" style={{ borderColor: color }}>
      <BaseText type="subHeading" className="stat-title" style={{ color }}>
        {title}
      </BaseText>
      
      <div className="stat-grid">
        <div className="stat-item">
          <BaseText type="caption">Current</BaseText>
          <BaseText type="heading">{metrics.current}</BaseText>
          <div className="severity-badge" style={{ backgroundColor: severity.color }}>
            {severity.category}
          </div>
        </div>
        
        <div className="stat-item">
          <BaseText type="caption">Average</BaseText>
          <BaseText type="heading">{metrics.average}</BaseText>
          <div className="severity-badge" style={{ 
            backgroundColor: getSeverityForScore(
              metrics.average, 
              title as 'Depression' | 'Anxiety' | 'Stress',
              version
            ).color 
          }}>
            {getSeverityForScore(metrics.average, title as 'Depression' | 'Anxiety' | 'Stress', version).category}
          </div>
        </div>
        
        <div className="stat-item">
          <BaseText type="caption">Min</BaseText>
          <BaseText type="heading">{metrics.min}</BaseText>
        </div>
        
        <div className="stat-item">
          <BaseText type="caption">Max</BaseText>
          <BaseText type="heading">{metrics.max}</BaseText>
        </div>
      </div>

      <div className="trend-section">
        <div className="trend-indicator">
          {getTrendIcon()}
          <BaseText type="body1">
            {metrics.trend.charAt(0).toUpperCase() + metrics.trend.slice(1)}
          </BaseText>
        </div>
        <BaseText type="caption" className="change-percent">
          {Math.abs(metrics.changePercent)}% {metrics.changePercent >= 0 ? 'increase' : 'decrease'}
        </BaseText>
      </div>
    </div>
  );
};

export default DassStatistics; 