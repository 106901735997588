import { AxiosResponse } from 'axios';
import { ApiClient } from './api-client';

// Match the enum from the backend
export enum DashboardVideoType {
  VIDEO = 'video',
  ARTICLE = 'article',
}

export interface DashboardVideo {
  id: string;
  title: string;
  description?: string;
  type: DashboardVideoType;
  duration?: string;
  thumbnail: string;
  url: string;
  order: number;
}

export const getDashboardVideos = async (): Promise<DashboardVideo[]> => {
  const response: AxiosResponse<DashboardVideo[]> = await ApiClient.get('/api/v1/dashboard-videos');
  return response.data;
}; 