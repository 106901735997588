import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Typography,
  styled,
  DialogProps,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    borderRadius: theme.shape.borderRadius * 2,
    boxShadow: '0px 4px 24px rgba(0, 0, 0, 0.08)',
  },
  '& .MDialog-header': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(2, 3),
    borderBottom: `1px solid ${theme.palette.divider}`,
    '& .MuiTypography-root': {
      fontSize: '1.25rem',
      fontWeight: 600,
      color: theme.palette.text.primary,
    },
  },
  '& .MDialog-content': {
    padding: theme.spacing(3),
    '&.with-title': {
      paddingTop: theme.spacing(2),
    },
  },
  '& .MDialog-actions': {
    padding: theme.spacing(2, 3),
    borderTop: `1px solid ${theme.palette.divider}`,
    '& > :not(:first-of-type)': {
      marginLeft: theme.spacing(2),
    },
  },
}));

export interface MDialogProps extends Omit<DialogProps, 'css' | 'title'> {
  title?: React.ReactNode;
  actions?: React.ReactNode;
  onClose?: () => void;
  hideCloseButton?: boolean;
  contentClassName?: string;
}

export const MDialog: React.FC<MDialogProps> = ({
  children,
  title,
  actions,
  onClose,
  hideCloseButton = false,
  contentClassName,
  ...props
}) => {
  return (
    <StyledDialog
      onClose={onClose}
      {...props}
    >
      {title && (
        <div className="MDialog-header">
          <Typography variant="h6">{title}</Typography>
          {!hideCloseButton && onClose && (
            <IconButton
              aria-label="close"
              onClick={onClose}
              size="small"
              sx={{
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          )}
        </div>
      )}
      <DialogContent className={`MDialog-content ${title ? 'with-title' : ''} ${contentClassName || ''}`}>
        {children}
      </DialogContent>
      {actions && (
        <DialogActions className="MDialog-actions">
          {actions}
        </DialogActions>
      )}
    </StyledDialog>
  );
}; 