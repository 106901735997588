import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Card, Typography, Space, Tag, Button, Spin, message, Tabs, Descriptions, Badge } from 'antd';
import { ArrowLeftOutlined, FileTextOutlined, UserOutlined, TeamOutlined, GlobalOutlined, EyeOutlined } from '@ant-design/icons';
import { useAppDispatch } from 'src/stores';
import { getTemplateByIdAction, generateTemplatePreviewAction } from 'src/stores/clients/clients-actions';
import type { Template } from 'src/stores/clients/clients-actions';
import { formatDistanceToNow } from 'date-fns';
import CommonContent from 'src/components/containers/CommonContent';
import Container from 'src/components/containers/Container';
import { RoutePaths } from 'src/routes/routes-constants';

const { Title, Text, Paragraph } = Typography;
const { TabPane } = Tabs;

const TemplateViewPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [template, setTemplate] = useState<Template | null>(null);
  const [loading, setLoading] = useState(true);
  const [previewLoading, setPreviewLoading] = useState(false);
  const [previewContent, setPreviewContent] = useState<string>('');
  const [activeTab, setActiveTab] = useState<string>('structure');

  useEffect(() => {
    const fetchTemplate = async () => {
      try {
        if (id) {
          const result = await dispatch(getTemplateByIdAction(id)).unwrap();
          setTemplate(result);
        }
      } catch (error) {
        message.error('Failed to load template');
      } finally {
        setLoading(false);
      }
    };

    fetchTemplate();
  }, [dispatch, id]);

  const handleGeneratePreview = async () => {
    if (!template) return;
    
    setPreviewLoading(true);
    try {
      const result = await dispatch(generateTemplatePreviewAction(template)).unwrap();
      setPreviewContent(result.content);
    } catch (error) {
      message.error('Failed to generate preview');
    } finally {
      setPreviewLoading(false);
    }
  };

  const getTemplateTypeTag = () => {
    if (template?.clinicId) {
      return <Tag icon={<TeamOutlined />} color="cyan">Clinic Template</Tag>;
    } else if (template?.isPrivate) {
      return <Tag icon={<UserOutlined />} color="red">Private Template</Tag>;
    }
    return <Tag icon={<GlobalOutlined />} color="green">Public Template</Tag>;
  };

  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Spin size="large" />
      </div>
    );
  }

  if (!template) {
    return (
      <div style={{ padding: '24px' }}>
        <Text>Template not found</Text>
      </div>
    );
  }

  return (
    <Container className="ClientsPage">
    <CommonContent
      title={template.name}
      haveBackButton
      onClickToBackButton={() =>  navigate('/templates')}
    >
    <div style={{ 
      display: 'flex',
      flexDirection: 'column',
      gap: '24px',
      height: '100vh',
      overflow: 'auto'
    }}>
      {/* Header Section */}
      <div style={{
        padding: '16px',
        background: '#ffffff',
        borderRadius: '8px',
        boxShadow: '0 1px 2px rgba(0, 0, 0, 0.05)',
        position: 'sticky',
        top: 0,
        zIndex: 100
      }}>
        <Space direction="vertical" size="middle" style={{ width: '100%' }}>
          <div>
            <div style={{ marginTop: '8px' }}>
              {getTemplateTypeTag()}
              <Tag style={{ marginLeft: '8px' }}>Used {template.usageCount} {template.usageCount === 1 ? 'time' : 'times'}</Tag>
            </div>
          </div>

          <Descriptions column={1} size="small">
            <Descriptions.Item label="Description">{template.description}</Descriptions.Item>
            <Descriptions.Item label="Created By">
              {template.createdBy === '00000000-0000-0000-0000-000000000000' ? 'ANTSA' : template.createdBy}
            </Descriptions.Item>
            <Descriptions.Item label="Last Updated">
              {formatDistanceToNow(new Date(template.updatedAt), { addSuffix: true })}
            </Descriptions.Item>
            <Descriptions.Item label="Tags">
              <Space wrap>
                {template.tags?.map((tag, index) => (
                  <Tag key={index} color="blue">{tag}</Tag>
                ))}
              </Space>
            </Descriptions.Item>
          </Descriptions>
        </Space>
      </div>

      {/* Content Section */}
      <div style={{ flex: 1, background: '#f5f5f5', overflow: 'hidden' }}>
        <style>
          {`
            .template-tabs {
              background: #f5f5f5;
            }
            .template-tabs .ant-tabs-nav {
              margin-bottom: 16px;
            }
            .template-tabs .ant-tabs-tab {
              background: white !important;
              border: 1px solid #d9d9d9 !important;
              border-radius: 4px !important;
              margin: 0 8px 0 0 !important;
              padding: 6px 16px !important;
              transition: all 0.3s;
            }
            .template-tabs .ant-tabs-tab:hover {
              color: #1890ff !important;
              border-color: #1890ff !important;
            }
            .template-tabs .ant-tabs-tab.ant-tabs-tab-active {
              background: white !important;
              border-color: #1890ff !important;
            }
            .template-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
              color: #1890ff !important;
            }
            .template-tabs .ant-tabs-ink-bar {
              display: none !important;
            }
            .template-tabs .ant-tabs-content-holder {
              background: white;
            }
            .template-tabs .ant-tabs-nav::before {
              border-bottom: none !important;
            }
          `}
        </style>

        <Tabs 
          activeKey={activeTab}
          onChange={setActiveTab}
          className="template-tabs"
        >
          <TabPane 
            tab={
              <span>
                <FileTextOutlined /> Template Structure
              </span>
            } 
            key="structure"
          >
            <Card
              type="inner"
              title="Template Structure"
              bodyStyle={{ 
                padding: '24px',
                maxHeight: 'calc(100vh - 400px)',
                overflowY: 'auto'
              }}
              style={{ border: 'none', borderRadius: '8px' }}
            >
              <pre style={{ 
                whiteSpace: 'pre-wrap',
                wordWrap: 'break-word',
                fontFamily: 'inherit',
                margin: 0
              }}>
                {template.content}
              </pre>
            </Card>
          </TabPane>
          <TabPane 
            tab={
              <span>
                <EyeOutlined /> Preview
              </span>
            } 
            key="preview"
          >
            <Card
              type="inner"
              title="Template Preview"
              extra={
                <Button 
                  type="primary" 
                  onClick={handleGeneratePreview}
                  loading={previewLoading}
                >
                  Generate Preview
                </Button>
              }
              bodyStyle={{ 
                padding: '24px',
                maxHeight: 'calc(100vh - 400px)',
                overflowY: 'auto'
              }}
              style={{ border: 'none', borderRadius: '8px' }}
            >
              {previewContent ? (
                <div style={{ 
                  whiteSpace: 'pre-wrap',
                  wordWrap: 'break-word'
                }}>
                  {previewContent}
                </div>
              ) : (
                <Text type="secondary">
                  Click &quot;Generate Preview&quot; to see how this template looks with sample data
                </Text>
              )}
            </Card>
          </TabPane>
        </Tabs>
      </div>
    </div>
    </CommonContent>
    </Container>
  );
};

export default TemplateViewPage; 